import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { from } from 'rxjs';

import * as _ from 'lodash';

@Component({
  selector: 'app-input-cep',
  templateUrl: './input-cep.component.html',
  styleUrls: ['./input-cep.component.less']
})
export class InputCepComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;

  _=_;

  loading = false;
  mapResponseToSetup: any;
  settingMask: any;
  minLengthMask: string;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    const control = this.form.get(this.attribute.key) as FormControl;
    this.mapResponseToSetup = _.result(this.attribute, 'formItem.pars.mapResponseToSetup');
    this.settingMask = _.get(this.attribute, 'formItem.pars.settingMask');
    this.minLengthMask = `${this.settingMask.mask.replace(/[^a-zA-Z0-9]/g, '').length}`;

    control.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(value => this.validCep(value) ?
        this.http.get(`https://viacep.com.br/ws/${value}/json`)
        .pipe(catchError(error => {           
            this.loading = false;
            control.setErrors({ invalidCEP: true });
          return from([]);
        }))
        :from([])
      )
    ).subscribe(
      resp => {
        if(resp.erro){
          control.setErrors({ invalidCEP: true });
        }

        if(this.mapResponseToSetup != null){
          if(resp){
            _.map(this.mapResponseToSetup, (item) => {
              if(!item.value)
                this.form.get(item.toSetupAttribute).setValue(_.result(resp, item.fromResponse));
              else 
                this.form.get(item.toSetupAttribute).setValue(item.value);
              
              if(item.disableSetupAttribute)
                this.form.get(item.toSetupAttribute).disable();
            });
          }
        }

        this.loading = false;
      });
  }

  validCep(cep: any): boolean {
    if (cep != null && cep.length === 8) {
      var objER = /[0-9]{8}/;
      cep = cep.trim();

      if (objER.test(cep)) {
        this.loading = true;
        return true;
      }
      return false;
    } else {
      return false
    }
  }
}
