<footer>
  <div nz-row>
    <div nz-col nzSpan="12">
      <a href="https://www.gov.br/pt-br" target="_blank"><img class="imggov" src="assets/img/Gov.br_logo_white.svg"
          alt=""></a>
    </div>
    <div nz-col nzSpan="12">
      <img class="imgness" src="assets/img/neeslogo_white.svg" alt="">
    </div>
  </div>
  <div class="first-line"></div>
  <div nz-col nzSpan="24">
    <span class="text-footer"><a href="assets/pdf/Termos_de_Uso_PNLD_Avaliacao.pdf" target="_blank">Termos de Uso</a> e
      <a href="assets/pdf/Politica_de_Privacidade_PNLD_Avaliacao.pdf" target="_blank"> Política de Privacidade</a></span>
  </div>
  <div nz-col nzSpan="24">
    <span class="text-footer-version">Versão f-{{version}} / b-{{versionBackend}}</span>
  </div>
</footer>