<nz-form-item>
  <nz-form-label 
    *ngIf="attribute?.description" 
    [nzRequired]="attribute?.isRequired" 
    [nzFor]="attribute?.key"
  >
    {{ attribute?.description }}
  </nz-form-label>

  <nz-form-control>
    <div class="row">
      <div class="select-col">
        <nz-select
          [(ngModel)]="selectedValue"
          [nzPlaceHolder]="attribute?.formItem?.placeholder ? attribute?.formItem?.placeholder : ''"
          nzAllowClear
          nzShowSearch
          nzServerSearch
          nzNotFoundContent="Nenhum registro encontrado."
          [nzSuffixIcon]="'search'"
          [nzClearIcon]="clearIconTpt"
          (nzOnSearch)="onSearch($event)"
          style="width: 100%;"
        >
          <ng-container *ngFor="let item of items">
            <nz-option 
              *ngIf="!isLoading" 
              [nzValue]="item[attributeValue]" 
              [nzLabel]="item[attributeKey]"
            ></nz-option>
          </ng-container>
      
          <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
            <i nz-icon nzType="loading" class="loading-icon"></i> Buscando...
          </nz-option>
        </nz-select>

        <ng-template #clearIconTpt>
          <i nz-icon nzType="close" nzTheme="outline"></i>
        </ng-template>
      </div>

      <div>
        <button 
          nz-button 
          nzShape="circle"
          (click)="onOpenModalFilter()"
        >
          <i nz-icon nzType="filter" nzTheme="fill"></i>
        </button>
      </div>
    
      <div>
        <button 
          nz-button 
          nzType="primary" 
          nzShape="round" 
          [disabled]="!selectedValue"
          (click)="onUseModel()"
        >
          Usar modelo
        </button>
      </div>
    </div>
  </nz-form-control>
</nz-form-item>

<nz-modal
  [(nzVisible)]="isVisibleModalFilter"
  nzCentered
  [nzFooter]="null"
  (nzOnCancel)="onCloseModalFilter()"
>
  <div class="modal-filter">
    <div class='header'>
      <strong>Filtrar por:</strong>
      <button nz-button nzType="link">Limpar filtros</button>
    </div>

    <div class="content">
      <app-form-layout
        [attributes]="attributes"
        [form]="formFilter"
      ></app-form-layout>
    </div>

    <div class="footer">
      <button 
        nz-button 
        nzShape="round" 
        (click)="onCloseModalFilter()"
      >
        Cancelar
      </button>

      <button 
        nz-button 
        nzShape="round" 
        nzType="primary"
        (click)="onFilter()"
      >
        Filtrar
      </button>
    </div>
  </div>
</nz-modal>
