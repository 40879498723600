import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiService } from '../../shared/services/api.service';
import { PermissionsService } from '../../shared/services/permissions.service';
import {
  convertCammelCaseToUnderscoreCase,
  treatDataset,
  DEFAULT_PRIMARY_KEY_PREFIX,
  URL_API_SEARCH
} from '../utils/const';
import { PnldFiltroBase } from '../models/pnled-filtros-base.model';

interface Data {
  data: any[];
  filters: Filter[];
  offset: number;
  size: number;
  total: number;
}

interface Filter {
  model: string;
  filter: any[];
  path?: string;
}

interface FetchData {
  filters?: Filter[];
  offset?: number;
  orderBy?: {
    attribute: string;
    direction: string;
  };
}

@Injectable()
export class ModuleService {

  setup: any = {};
  moduleFilters: any = {};
  activeAction: any = undefined;
  isOffsetX = false;
  defaultAction: any = undefined;
  actionsWithoutMoreAction: any = [];
  actionsCard: any[] = [];
  actionAdd: any;
  actionEdit: any;
  bulkAction: any;
  actionSearch: any;
  headerCardIcon: string = '';
  headerCardTitle: string = '';
  headerCardDescription: string = '';
  primaryKey: any = undefined;

  private data: any[] = [];
  dataSet: Data;
  numberItemDisplaying = 10;
  quantityPages = 1;
  currentPage = 0;
  menuPage: number[] = [];

  attributeDataSet: any[] = [];
  attributeBodyCard: any = undefined;
  loadingDataSet: boolean = false;
  loadingDataSetMore: boolean = false;
  errorDataSet: any = null;

  values: any = undefined;
  attributesToSort: any = undefined;
  loadingModule: boolean = false;
  statusActionLoading: any = { "status": "", "type": "", "title": "", "description": "" };
  showFiltersOnModuleTitle: boolean = false;

  /* Demand */
  demand: any[] = [];

  currentModulePermissions: any;

  alert: {
    type: string;
    title: string;
  };
  modulePars: {};
  pnldFiltroBase: PnldFiltroBase;
  moduleTitle: string;
  datasetFilters: {
    filters: Filter[],
    pnldFilterBase: {},
    limit: number,
    offset: number,
    orderBy: {},
  };

  defaultOrderBy: {
    attribute: string;
    direction: string;
  }

  constructor(
    private apiService: ApiService,
    private permissionsService: PermissionsService,
    private router: Router
  ) { }

  /**
   * Quando se muda a página de um data-grid essa informação continua mesmo trocando de elemento.
   * Por exemplo, abrir um campo fixo qualquer, mudar a sua página na listagem e depois trocar para outro campo fixo, a página permance.
   * Essa função será chamada para assegurar que haverá um reinício dessa página.
   */
  reiniciar(){
    this.currentPage = 0;
  }

  fetchSetup(moduleKey: string): Observable<any> {
    this.errorDataSet = null;
    this.loadingModule = true;
    this.dataSet = null;

    this.pnldFiltroBase = JSON.parse(localStorage.getItem('@pnld_filtro_base') || '{}');

    return this.apiService.get(`setup/${moduleKey}`).pipe(
      map(resp => {
        /* Module setup */
        this.setup = resp;

        /* Permissões do modulo atual */
        this.currentModulePermissions = _.find(this.permissionsService.permissions, ['modulo.ds_chave', moduleKey]);

        /* Obtendo as Actions e verificando se possue permissão */
        const actions = _.filter(_.result(this.setup, 'actions'), action => this.checkPermissionOnAction(action) != null);

        /* Obtendo a key da action default */
        const defaultActionKey = _.result(this.setup, 'defaultActionKey');

        
        /* Obtendo action add */
        this.actionAdd = _.find(actions, { 'key': 'add' });

        /* Obtendo action edit */
        this.actionEdit = _.find(actions, { 'key': 'edit' });
        
        /* Obtendo action bulk-action */
        this.bulkAction = _.find(actions, { 'key': 'bulk-action' });
        
        /* Obtendo action search */
        this.actionSearch = _.find(actions, { 'key': 'search' });
        
        /* Obtendo a action default */
        this.defaultAction = _.find(actions, { 'key': defaultActionKey });

        /* Obtendo a orderBy default */
        this.defaultOrderBy = _.result(this.setup, 'orderBy');

        /* Filtrando as actions => 'isMoreAction': false */
        this.actionsWithoutMoreAction = _.orderBy(_.filter(actions, { 'isMoreAction': false }), ['order'], ['asc']);

        /* Obtendo as actions exibidas no rodapé do card */
        this.actionsCard = _.orderBy(_.filter(actions, item => item.isDisableWithoutSelection), ['order'],['asc']);

        /* Header do card */
        this.headerCardIcon = _.result(this.currentModulePermissions, 'modulo.modulo_grupo.ds_icone');
        this.headerCardTitle = _.result(this.setup, 'toString');
        this.headerCardDescription = "created_at";

        /* Obtendo primare key */
        if(this.setup.primaryKey) {
          this.primaryKey = this.setup.primaryKey;
        } else {
          this.primaryKey = this.setup.model ? DEFAULT_PRIMARY_KEY_PREFIX + convertCammelCaseToUnderscoreCase(this.setup.model) : null
        }

        /* Obtendos os attributes */
        const attributes = _.result(this.setup, 'attributes');

        /* Obtendo attributes do dataGrid */
        this.attributeDataSet = _.filter(attributes, { 'isVisibleDataTable': true });
        this.attributeBodyCard = _.filter(attributes, item => {
          return item.isVisibleDataTable && item.key !== this.headerCardTitle && item.key !== this.headerCardDescription
        });

        /* Obtendo attributes para ordenar dataGrid */
        this.attributesToSort = this.sortableAttributes(_.filter(attributes, { 'isSortable': true }));

        /* Obtendo os pars para o dataGrid e etc */
        this.modulePars = _.result(this.setup, 'pars');
        this.showFiltersOnModuleTitle = _.result(this.modulePars, 'pnldFiltroBase') || false;

        /* Obtendo filtro para o dataGrid */
        this.moduleFilters = _.result(this.setup, 'filters');

        /**
         * Vericar se nos filtros do modulo está passando uma variavel buscar na localStorage
         * Exemplo: "filter": ["co_edital", "=", "edital|co_edital"]
         */

        this.moduleFilters = this.moduleFilters.map((f: Filter) => {
          const [key, operador, value] = f.filter;
          const [model, attKey] = value.split('|');

          if (attKey) {
            this.showFiltersOnModuleTitle = true;
            return {
              ...f,
              filter: [key, operador, this.pnldFiltroBase[model].value]
            }
          } else {
            return f;
          }
        });

        this.moduleTitle = this.setup.title;

        /* Loading Module */
        this.loadingModule = false;

        /* Obtendo os registros referente ao modulo */
        this.fetchData({}, _.result(this.modulePars, 'setFetchEndpoint'));

        return resp;
      }),
      catchError(err => {
        this.loadingModule = false;
        this.setAlert({ type: 'error', title: 'Erro! Contate o administrador ou tente novamente mais tarde.' });

        return err;
      })
    );
  }

  /* Verificando permissão na action */
  checkPermissionOnAction(action): any {
    if (!_.isEmpty(this.currentModulePermissions)) {
      return _.find(this.currentModulePermissions.modulo_acaos, ['ds_chave', action.key]);
    }

    return null;
  }

  /* Classificando os attributes para a ordenação do dataGrid */
  sortableAttributes(attributes): any {
    let result = [];

    attributes.forEach(element => {
      let obj = {};

      if (_.isEmpty(element.model)) {
        obj = {
          key: element.key,
          description: element.description
        }
      } else {
        const model = _.camelCase(element.model);
        obj = {
          key: `${model}.${element.formItem.pars.attributeValue}`,
          description: element.description
        }
      }
      result.push(obj);
    });
    return result;
  }

  setNumberItemDisplaying(amount: number): void {
    this.numberItemDisplaying = amount;
    this.fetchData({});
  }

  setCurrentPage(page: number): void {
    const offset = this.numberItemDisplaying * page;
    this.currentPage = page;

    this.fetchData({ offset });
  }

  incluirFiltro(filtros){
    this.limparFiltros();
    this.moduleFilters = [...this.moduleFilters, ...filtros];
  }

  // Limpa apenas um filtro em específico
  limparFiltro(filtro){
    this.moduleFilters = this.moduleFilters.filter(filtros=>{
      if(filtros.filter[0] == filtro[0] && 
        filtros.filter[1] == filtro[1] &&
        filtros.filter[2] == filtro[2]){
        return false;
      }else{
        return true;
      }
    });
  }

  /**
   * Exclui os filtros de descrição e código do dataset, exceto o filtro de campo legado que é filtrado pela função filtrarCamposLegados.
   */
  limparFiltros(){
    this.moduleFilters = this.moduleFilters.filter(filtros=>{
      if(filtros.filter[2] == "%%"){
        return true;
      }
    });
  }

  isCampoAuxiliarFixo() {

    switch(this.setup.model) {
      case 'componente':
      case 'area-conhecimento':
      case 'exemplar':
      case 'formato':
      case 'serie':
      case 'recurso':
      case 'extensao':
      case 'genero-obra':
      case 'idioma':
      case 'relacionamento':
      case 'tema':
      case 'tipo-autoria':
        return true;
      default:
        return false;
    }

  }

  // Identifica o tipo de campo fixo/auxiliar a depender do dado que ele possui.
  // Seria ideal a existÊncia de uma coluna única para representar esse identificador único, ao invés de nomes diferentes para cada campo.
  getColunaIdentificadorModulo(data) {
    if(data.ds_componente != null) {
      return 'co_componente_pnld_digital';
    } else if(data.ds_area_conhecimento != null) {
      return 'co_area_conhec_pnld_digital';
    } else if(data.ds_exemplar != null) {
      return 'co_exemplar_pnld_digital';
    } else if(data.ds_formato != null){
      return 'co_formato_pnld_digital';
    } else if(data.ds_serie != null){
      return 'co_serie_pnld_digital';
    } else if (data.ds_recurso != null) {
      return 'co_recurso_pnld_digital';
    } else if (data.ds_extensao != null) {
      return 'co_ext_arq_pnld_digital';
    } else if (data.ds_genero_obra != null) {
      return 'co_genero_obra_pnld_digital';
    } else if (data.ds_idioma != null) {
      return 'co_idioma_pnld_digital';
    } else if (data.ds_relacionamento != null) {
      return 'co_relac_pnld_digital';
    } else if (data.ds_tema != null) {
      return 'co_tema_pnld_digital';
    } else if (data.ds_tipo_autoria != null) {
      return 'co_tp_autoria_pnld_digital';
    } else {
      return null;
    }
  }

  incluirFiltroLegado(){
    if (this.dataSet != null && Array.isArray(this.dataSet.data) && this.dataSet.data.length > 0) {
      const tipo = this.getColunaIdentificadorModulo(this.dataSet.data[0]);
      if( tipo != null ) {
        this.moduleFilters.push({'filter': [ tipo, 'like', '%%' ], 'model': '', 'path': null});
      }
    }
  }

  filtrarCamposLegados(filtrarLegados) {
    const data = treatDataset(this.data, this.attributeDataSet) || [];

    if(filtrarLegados){
      this.incluirFiltroLegado();
      this.setCurrentPage(0);
    } else {
        let posicaoRemocao = -1;
        for(let i = 0; i < this.moduleFilters.length; i++) {
          if(this.moduleFilters[i].filter[2] === '%%') {
            posicaoRemocao = i;
            break;
          }
        }

        if(posicaoRemocao !== -1) {
          this.moduleFilters.splice(posicaoRemocao, 1);
        }

        this.setCurrentPage(0);
    }
  }

  /* Obtendo os registro do modulo */
  fetchData({ filters = [], offset = 0, orderBy }: FetchData, urlApiSearch: string = URL_API_SEARCH): void {
    this.errorDataSet = null;
    this.loadingDataSet = true;

    let pnldFiltroBase = {};
    if(this.setup?.pars?.pnldFiltroBase) {
      pnldFiltroBase = {
        co_edital: this.pnldFiltroBase.edital.value,
        co_etapa_ensino: this.pnldFiltroBase.etapaEnsino.value,
        co_objeto: this.pnldFiltroBase.objeto.value,
      }
    }

    this.datasetFilters = {
      filters: [...this.moduleFilters, ...filters],
      pnldFilterBase: pnldFiltroBase,
      limit: this.numberItemDisplaying,
      offset,
      orderBy: orderBy || this.defaultOrderBy,
    };

    this.apiService.post<Data>(`${this.setup.model}${urlApiSearch}`, this.datasetFilters).subscribe(
      resp => {
        this.data = resp['data'];
        resp['data'] = treatDataset(resp['data'], this.attributeDataSet);
        this.dataSet = resp;

        this.quantityPages = Math.ceil(this.dataSet?.total / this.numberItemDisplaying);

        this.loadingDataSet = false;
      }, err => {
        this.errorDataSet = err;
        this.loadingDataSet = false;
        this.setAlert({ type: 'error', title: 'Erro! Contate o administrador ou tente novamente mais tarde.' });
      });
  }

  

  /**
   * Metodo usada para o campo de busca rapida, que deve pesquisar o valor digitado
   * em cada uma das colunas do datagrid.
   * @param value 
   */
  filterDataSet(value: string) {
    const data = treatDataset(this.data, this.attributeDataSet) || [];

    this.dataSet.data = data.filter(
      data => {
        let itemFound = false;

        this.attributeDataSet.forEach(att => {
          if (String(_.result(data, att.key)).toUpperCase().indexOf(String(value).toUpperCase()) > -1) {
            itemFound = true;
            return;
          }
        });

        return itemFound;
      }
    );
  }

  /* Obtendo mais registros do modulo */
  moreItemDataSet() {
    if (!this.loadingDataSet && !this.loadingDataSetMore && this.dataSet) {
      if (this.dataSet.size + this.dataSet.offset < this.dataSet.total) {
        this.loadingDataSetMore = true;
        this.apiService.post<Data>(`${this.setup.model}${URL_API_SEARCH}`,
          {
            "filters": this.dataSet.filters,
            "limit": 30,
            "offset": this.dataSet.size + this.dataSet.offset
          }).subscribe(resp => {
            if (resp['data'] != null) {
              this.data = [].concat(this.data, resp['data']);
              resp['data'] = treatDataset(resp['data'], this.attributeDataSet);
              resp['data'] = [].concat(this.dataSet.data, resp['data']);
            }
            this.dataSet = resp;
            this.loadingDataSetMore = false;
          });
      }
    }
  }

  /* Selecionado cads no dataGrid */
  selectedItemDataSet(event) {
    const { item, status } = event;
    if (status) {
      this.demand.push(item);
    } else {
      this.demand = _.filter(this.demand, itemDemand => itemDemand !== item);
    }
  }

  handleMoreActionsClick(event): void {
    this.activeAction = event.action;
    this.values = _.find(this.data, item => _.result(item, this.primaryKey) == _.result(event.item, this.primaryKey));
  }

  initAction(): void {
    this.activeAction = undefined;
    this.values = undefined;
  }

  onOffsetX(offsetX: boolean): void {
    this.isOffsetX = offsetX;
  }

  setAlert(alert: { type: string, title: string }): void {
    this.alert = alert;
    window.scrollTo(0, 0);

    setTimeout(() => {
      this.initAlert()
    }, 5000);
  }

  initAlert(): void {
    this.alert = null;
  }
}
