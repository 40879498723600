import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from '../../shared/services/api.service';

@Injectable()
export class UsuarioService {

  constructor(private apiService: ApiService, private notification: NzNotificationService) {}

  getPermissoesObjeto(coObjeto): Promise<any>{
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`usuario/permissoes-objeto/${coObjeto}`).subscribe(
        resp => resolve(resp),
        error => reject(error)
      )
    })
  }

  getUsers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`usuario`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
      });
  }



  getNotificacoes(co_usuario): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`notificacao`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
      });
  }

  putNotificacaoComoLida(co_notificacao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`notificacao/${co_notificacao}/lida`, {})
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
      });
  }

  putNotificacaoSalvar(co_notificacao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`notificacao/${co_notificacao}/salvar`, {})
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
      });
  }

  deleteNotificacao(co_notificacao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.delete(`notificacao/${co_notificacao}/excluir`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
      });
  }

  getUser(co_usuario): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`usuario/${co_usuario}`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
      });
  }

  updateUserBlock(co_usuario, params): Promise<any> {
    const paramsAdjus = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`usuario/${co_usuario}`, paramsAdjus)
        .subscribe(
          resp => {
            this.notification.success('', `Usuário atualizado com sucesso.`);
            resolve(resp);
          },
          error => {
            this.notification.error('', `Ocorreu algum erro, por favor tente novamente.`);
            reject(error);
          },
        );
      });
  }

  getProfiles(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get('perfil')
        .subscribe(
          resp => {
            resolve(resp);
          },
          error => {
            reject(error);
          },
        );
    });
  }

  cleanParam(param) {
    const propNames = Object.getOwnPropertyNames(param);
    for (let i = 0; i < propNames.length; i++) {
      let propName = propNames[i];
      if (param[propName] === null || param[propName] === undefined) {
        delete param[propName];
      }
    }

    return param;
  }

  excluir(usuarioiD): Promise<any>{
    return new Promise<any>((resolve, reject) => {
      this.apiService.delete(`usuario/${usuarioiD}`).subscribe(
        resp => resolve(resp),
        error => reject(error)
      )
    });
  }
  
  getHistoricoBloqueio(co_usuario): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`usuario/${co_usuario}/block-history`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  bloquear(co_usuario, ds_justificativa): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`usuario/${co_usuario}/block`, {ds_justificativa})
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  desbloquear(co_usuario, ds_justificativa): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`usuario/${co_usuario}/unblock`, {ds_justificativa})
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }
}
