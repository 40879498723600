<ng-container *ngIf="form != null" [formGroup]="form">
  <div class="items">
    <div class="item" *ngFor="let item of form.get(arrayName).controls; let i = index;">
      <div class="fields">
        <div [formGroup]="item" [ngClass]="!attributeOne?.formItem?.gridstyle ? 'col-12' : attributeOne?.formItem?.gridstyle">

          <nz-form-item>
            <nz-form-label *ngIf="attributeOne?.description" [nzRequired]="attributeOne?.isRequired" [nzFor]="attributeOne?.key">{{ attributeOne?.description }}</nz-form-label>
            <nz-form-control>

              <nz-select 
                [id]="attributeOne?.key" 
                [formControlName]="attributeOne?.key" 
                nzAllowClear
                nzShowSearch
                nzPlaceHolder="Selecione..."
                [nzMode]="modeOne"
                (nzOpenChange)="openChange($event, 'one', i)"
                (ngModelChange)="modelChange($event, 'one', i, item)"
              >
                <ng-container *ngFor="let item of selects[i]['one']['options']">
                  <nz-option 
                    *ngIf="!isLoading" 
                    [nzValue]="_.result(item, keyValueOne)"
                    [nzLabel]="_.result(item, descriptionValueOne)"
                  ></nz-option>
                </ng-container>
                
                <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
                  <i nz-icon nzType="loading" class="loading-icon"></i> Carregando opções...
                </nz-option>
              </nz-select>
              
            </nz-form-control>
          </nz-form-item>

        </div>
        <div [formGroup]="item" [ngClass]="!attributeTwo?.formItem?.gridstyle ? 'col-12' : attributeTwo?.formItem?.gridstyle">

          <nz-form-item>
            <nz-form-label *ngIf="attributeTwo?.description" [nzRequired]="attributeTwo?.isRequired" [nzFor]="attributeTwo?.key">{{ attributeTwo?.description }}</nz-form-label>
            <nz-form-control>

              <nz-select 
                [id]="attributeTwo?.key" 
                [formControlName]="attributeTwo?.key" 
                nzAllowClear
                nzShowSearch
                nzPlaceHolder="Selecione..."
                [nzDisabled]="selects[i]['two']['disabled']"
                [nzMode]="modeTwo"
                (nzOpenChange)="openChange($event, 'two', i)"
                (ngModelChange)="modelChange($event, 'two', i, item)"
              >
                <ng-container *ngFor="let item of selects[i]['two']['options']">
                  <nz-option 
                    *ngIf="!isLoading" 
                    [nzValue]="_.result(item, keyValueTwo)"
                    [nzLabel]="_.result(item, descriptionValueTwo)"
                  ></nz-option>
                </ng-container>
                
                <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
                  <i nz-icon nzType="loading" class="loading-icon"></i> Carregando opções...
                </nz-option>
              </nz-select>
              
            </nz-form-control>
          </nz-form-item>

        </div>
      </div>
      
      <button *ngIf="!attribute?.formItem?.pars?.isNoButtonAdd" nz-button [nzType]="i == 0 ? 'primary' : 'danger'" nzShape="circle" (click)="i == 0 ? addItem() : removeItem(i)">
        <i nz-icon [nzType]="i == 0 ? 'plus' : 'minus'" nzTheme="outline"></i>
      </button>
    </div>
  </div>
</ng-container>