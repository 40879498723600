<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control [nzHasFeedback]="true" [nzErrorTip]="errorTpl" [nzExtra]="attribute?.formItem?.pars?.extra">

      <!-- <input
        *ngIf="!uniqueConfig"
        [id]="attribute?.key"
        type="text"
        nz-input
        [formControlName]="attribute?.key"
        [placeholder]="attribute?.formItem?.placeholder ? attribute?.formItem?.placeholder : ''"
      >

      <nz-input-group *ngIf="uniqueConfig" [nzSuffix]="suffixTemplate"> -->
        <input
          [id]="attribute?.key"
          type="text"
          nz-input
          [formControlName]="attribute?.key"
          [placeholder]="attribute?.formItem?.placeholder ? attribute?.formItem?.placeholder : ''"
          (keyup)="uniqueConfig ? onKey($event) : null"
          (blur)="uniqueConfig ? validUnique($event) : null"
        >
      <!-- </nz-input-group>

      <ng-template #suffixTemplate>
        <i *ngIf="!uniqueConfig" nz-icon type="search" nzTheme="outline"></i>
        <i *ngIf="control.hasError('loading')" nz-icon type="loading" nzTheme="outline" class="icon-loading"></i>
      </ng-template> -->

      <ng-template #errorTpl let-control>
        <app-form-explain 
          [control]="control"
          [minLength]="attribute?.formItem?.maxLength"
          [maxLength]="attribute?.formItem?.maxLength"
          [attribute]="attribute"
        ></app-form-explain>
      </ng-template>

    </nz-form-control>
  </nz-form-item>
</ng-container>
