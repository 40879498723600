import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';

export class URLGovBRResponse {
  url: string
  logout_url: string
}

export class URLGovRequest {
  code: string
  state: string

  constructor(code: string, state: string) {
    this.code = code;
    this.state = state;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGovbrService {

  constructor(
    private _apiService: ApiService,
    private _http: HttpClient,
  ) { }

  getLogin(): Observable<URLGovBRResponse> {
    let getURLGovBREndpoint: string = 'auth/govbr-url';
    return this._apiService.get(getURLGovBREndpoint);
  }

  login(requestData: URLGovRequest): Observable<any> {
    let postURLGovBREndpoint: string = 'auth/govbr';
    return this._apiService.post(postURLGovBREndpoint, requestData);
  }

  logout(url): Observable<any> {
    return this._http.post(url, null);
  }

  setLogoutGovBRURL(url): void {
    localStorage.setItem('logout_url', JSON.stringify(url));
  }

  getGovBRURLLogoutFromLocalStorage(): string {
    return JSON.parse(localStorage.getItem('logout_url'));
  }

}
