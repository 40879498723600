import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';

import { ApiService } from '../../../../shared/services/api.service';

@Component({
  selector: 'app-tree-select-single',
  templateUrl: './tree-select-single.component.html',
  styleUrls: ['./tree-select-single.component.less']
})
export class TreeSelectSingleComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;

  treeData = [];
  isDisabled = false;
  loading = false;
  value: string;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
    this.loading = true;

    setTimeout(() => {
      this.onFetch();
    }, 1000);
  }

  onFetch(): void {
    const { model, formItem } = this.attribute;

    this.api.get(this.attribute.model).pipe(
      take(1)
    ).subscribe(resp => {
      const dataPai = _.filter(resp, [formItem.pars.attributeParent, null]);
      const dataFilhos = resp;

      this.treeData = this.formatarTreeData(dataPai, dataFilhos);
      if(this.initial && this.initial.value) {
        this.value = this.initial.value;
      }
      this.loading = false;
    }, err => {
      this.treeData = [];
      this.loading = false;
    });
  }

  formatarTreeData = (pais, filhos) => {
    const { formItem } = this.attribute;
    let treeData = []
    //Obter todos os objetos que possuem attributeParent == null (_.filter)
    //e método map para transformar no padrão esperado pelo TreeSelect do Antd
    if (!_.isEmpty(pais) && pais !== false) {
      treeData = pais.map((objeto) => (
        {
          title: _.result(objeto, formItem.pars.attributeValue),
          key: _.result(objeto, formItem.pars.attributeKey).toString(),
          value: _.result(objeto, formItem.pars.attributeKey).toString(),
          children: []
        }
      ))
      //Navegar entre os dados do fetch e colocar os filhos como
      //children de cada dataPai anterior
      filhos.forEach(element => {
        for (let i = 0; i < treeData.length; i++) {
          //Não pode fazer a comparação tipada '===' porque o antd
          //Força o value a ser convertido para string.
          //E vindo do backend, o value é int.
          if (element[formItem.pars.attributeParent] == treeData[i].value) {
            treeData[i].children.push({
              title: _.result(element, formItem.pars.attributeValue),
              key: _.result(element, formItem.pars.attributeKey).toString(),
              value: _.result(element, formItem.pars.attributeKey).toString(),
              isLeaf: true
            })
          }
        }
      })
    }

    return treeData
  }
}