<div
  *ngIf="(visible && form?.get(attribute.key) || attribute.key == '')"
  [formGroup]="form"
  [ngClass]="{
    'form-item-clean': isLabelOptional,
    'form-item-optional-label': !attribute?.isRequired && isLabelOptional
  }"
>

  <ng-container [ngSwitch]="true">

    <!-- Input - Text -->
    <app-input-text
      *ngSwitchCase="formItemKey == 'text'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-input-text>

    <!-- Input - Url -->
    <app-input-url
      *ngSwitchCase="formItemKey == 'input-url'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-input-url>

    <!-- Input - E-mail -->
    <app-input-email
      *ngSwitchCase="formItemKey == 'input-email'"
      [form]="form" [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-input-email>

    <!-- Input - Textarea -->
    <app-input-text-area
      *ngSwitchCase="formItemKey == 'textArea'"
      [form]="form"
      [attribute]="attribute"
    ></app-input-text-area>

    <!-- Input - Password -->
    <app-input-password
      *ngSwitchCase="formItemKey == 'password'"
      [form]="form"
      [attribute]="attribute"
    ></app-input-password>

    <!-- Input - Number -->
    <app-input-number
      *ngSwitchCase="formItemKey == 'number'"
      [form]="form"
      [attribute]="attribute"
    ></app-input-number>

    <!-- Input - Mask -->
    <app-input-mask
      *ngSwitchCase="formItemKey == 'input-mask'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-input-mask>

    <!-- Input - Cep -->
    <app-input-cep
      *ngSwitchCase="formItemKey == 'input-cep'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-input-cep>

    <!-- Summernote Rich Text Area -->
    <app-summernote-rich-text-area
      *ngSwitchCase="formItemKey == 'summernote-input-rich-text-area' || formItemKey == 'input-rich-text-area' || formItemKey == 'draft-input-rich-text-area'"
      [form]="form"
      [attribute]="attribute"
    ></app-summernote-rich-text-area>

    <!-- Data Picker -->
    <app-data-picker
      *ngSwitchCase="formItemKey == 'datePicker'"
      [form]="form"
      [attribute]="attribute"
    ></app-data-picker>

    <!-- Radio -->
    <app-radio
      *ngSwitchCase="formItemKey == 'radio-button' || formItemKey == 'radio'"
      [form]="form"
      [attribute]="attribute"
    ></app-radio>

    <!-- Checkbox -->
    <app-checkbox
      *ngSwitchCase="formItemKey == 'checkbox'"
      [form]="form"
      [attribute]="attribute"
    ></app-checkbox>

    <!-- Selects -->
    <app-select-search
      *ngSwitchCase="formItemKey == 'select-single-static-values'
                    || formItemKey == 'select-single-search'
                    || formItemKey == 'select-multiple-search'
                    || formItemKey == 'select-tag'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
      [static]="formItemKey == 'select-single-static-values'"
      [mode]="formItemKey == 'select-multiple-search' ? 'multiple' : formItemKey == 'select-tag' ? 'tags' : 'default'"
      (callback)="callback.emit($event)"
    ></app-select-search>

    <!-- Select - Double -->
    <app-select-double
      *ngSwitchCase="formItemKey == 'select-double'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
      (callback)="callback.emit($event)"
    ></app-select-double>

    <!-- Upload File -->
    <app-upload-file
      *ngSwitchCase="formItemKey == 'upload-file'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
      (callback)="callback.emit($event)"
    ></app-upload-file>

    <app-upload-file-2
      *ngSwitchCase="formItemKey == 'upload-file-2'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-upload-file-2>

    <!-- Dynamic Form Control -->
    <app-dynamic-form-control
      *ngSwitchCase="formItemKey == 'dynamic'"
      [form]="form"
      [isLabelOptional]="isLabelOptional"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
      (callback)="callback.emit($event)"
    ></app-dynamic-form-control>

    <!-- Re-captcha Control -->
    <app-re-captcha-control
      *ngSwitchCase="formItemKey == 're-captcha'"
      [form]="form"
      [attribute]="attribute"
    ></app-re-captcha-control>

    <!-- Select Card -->
    <app-select-card
      *ngSwitchCase="formItemKey == 'select-card'"
      [form]="form"
      [attribute]="attribute"
      (callback)="callback.emit($event)"
    ></app-select-card>

    <!-- FormItem Custom -->
    <app-form-item-custom
      *ngSwitchCase="formItemKey == 'form-item-custom'"
      [form]="form"
      [attribute]="attribute"
    ></app-form-item-custom>

    <!-- FormItem Tree Select Single -->
    <app-tree-select-single
      *ngSwitchCase="formItemKey == 'tree-select-single'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-tree-select-single>

    <!-- Dynamic Form Table -->
    <app-dynamic-form-table
      *ngSwitchCase="formItemKey == 'dynamic-table'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
      (callback)="callback.emit($event)"
    ></app-dynamic-form-table>

    <app-label
      *ngSwitchCase="formItemKey === 'label'"
      [attribute]="attribute"
    ></app-label>

    <app-classificacao-pedagogica-edital
      *ngSwitchCase="formItemKey === 'classificacao-pedagogica-edital'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-classificacao-pedagogica-edital>
    
    <app-classificacao-pedagogica-especialista
      *ngSwitchCase="formItemKey === 'classificacao-pedagogica-especialista'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-classificacao-pedagogica-especialista>
    
    <app-group
      *ngSwitchCase="formItemKey === 'group'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
      (callback)="callback.emit($event)"
    ></app-group>

    <app-label-local
      *ngSwitchCase="formItemKey === 'label-local'"
      [form]="form"
      [attribute]="attribute"
    ></app-label-local>

    <app-classificacao-pedagogica
      *ngSwitchCase="formItemKey === 'classificacao-pedagogica'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-classificacao-pedagogica>

    <app-membro-especialista
      *ngSwitchCase="formItemKey === 'membro-especialista'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-membro-especialista>

    <app-membro-comissao
      *ngSwitchCase="formItemKey === 'membro-comissao'"
      [form]="form"
      [attribute]="attribute"
      [initial]="_.result(initial, attribute?.key)"
    ></app-membro-comissao>

    <app-container-form
      *ngSwitchCase="formItemKey === 'container-form'"
      [form]="form"
      [attribute]="attribute"
      [initial]="initial"
    ></app-container-form>

    <app-filter-model
      *ngSwitchCase="formItemKey === 'filter-model'"
      [form]="form"
      [attribute]="attribute"
      [initial]="initial"
      (callback)="callback.emit($event)"
    ></app-filter-model>

    <app-clear-button 
      *ngSwitchCase="formItemKey === 'clear-button'"
      [form]="form"
      [attribute]="attribute"
    ></app-clear-button>

    <app-classificacao-pedagogica-selects
      *ngSwitchCase="formItemKey === 'classificacao-pedagogica-selects'"
      [form]="form"
      [attribute]="attribute"
      [initial]="initial"
    ></app-classificacao-pedagogica-selects>
  </ng-container>
</div>
