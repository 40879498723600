import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { PnldFiltroBase } from '../../../models/pnled-filtros-base.model';

@Component({
  selector: 'app-label-local',
  templateUrl: './label-local.component.html',
  styleUrls: ['./label-local.component.less']
})
export class LabelLocalComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() attribute: any;

  control: AbstractControl;
  local: {
    model: string;
    valueKey: string;
    descriptionKey: string;
  };
  isHidden = false;
  description: string;

  constructor() { }

  ngOnInit(): void {
    this.control = this.form.get(this.attribute.key);
    this.local = _.result(this.attribute, 'formItem.pars.local');
    this.isHidden = _.result(this.attribute, 'formItem.pars.isHidden');

    const pnldFiltroBase: PnldFiltroBase = JSON.parse(localStorage.getItem('@pnld_filtro_base'));
    const data = pnldFiltroBase[this.local.model];
    
    if (pnldFiltroBase) {
      this.control.setValue(data[this.local.valueKey]);
      this.description = data[this.local.descriptionKey];
    }
  }

}
