<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control [nzErrorTip]="formExplain?.reCaptchaRequired">
      <re-captcha
        [formControlName]="attribute?.key"
        required
        siteKey="6Ld-7kQUAAAAAKmpHKjTHnPTVY73qEyD6XL_1hwP"
      ></re-captcha>
    </nz-form-control>
  </nz-form-item>
</ng-container>
