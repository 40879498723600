<nz-form-item *ngIf="!isView && !initial" [formGroup]="form">
  <nz-form-label 
    *ngIf="attribute?.description" 
    [nzRequired]="attribute?.isRequired" 
    [nzFor]="attribute?.key"
  >
    {{ attribute?.description }}
  </nz-form-label>
  
  <nz-form-control  [nzErrorTip]="errorTpl" [nzExtra]="attribute?.tooltip">
    <nz-select
      nzPlaceHolder="Buscar por nome ou CPF"
      nzAllowClear
      nzShowSearch
      nzServerSearch
      [nzNotFoundContent]="'Digite um nome ou CPF'"
      [nzSuffixIcon]="'search'"
      [nzClearIcon]="clearIconTpt"
      [formControlName]="attribute?.key"
      (nzOnSearch)="onSearch($event)"
      style="width: 100%; max-width: 450px;"
    >
      <ng-container *ngFor="let professor of professores">
        <nz-option 
          *ngIf="!isLoading" 
          [nzValue]="professor.co_professor" 
          [nzLabel]="professor.no_professor"
        ></nz-option>
      </ng-container>

      <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
        <i nz-icon nzType="loading" class="loading-icon"></i> Buscando...
      </nz-option>
    </nz-select>
  </nz-form-control>

  <ng-template #errorTpl let-control>
    <app-form-explain [control]="control" [attribute]="attribute"></app-form-explain>
  </ng-template>

  <ng-template #clearIconTpt>
    <i nz-icon nzType="close" nzTheme="outline"></i>
  </ng-template>
</nz-form-item>

<div *ngIf="professor" class="info">
  <div>
    <div>
      <strong>CPF</strong>
      <span>{{ professor.co_cpf | mask: '000.000.000-00' }}</span>
    </div>
    <div>
      <strong>Nome</strong>
      <span>{{professor.lgpd_nome}}</span>
    </div>
    <div>
      <strong>Telefone</strong>
      <span>{{ professor.nu_telefone | mask: '(00) 0000-0000' }}</span>
    </div>
    <div>
      <strong>Celular</strong>
      <span>{{ professor.nu_celular | mask: '(00) 0 0000-0000' }}</span>
    </div>
    <div>
      <strong>E-mail</strong>
      <span class="email">{{ professor.ds_email ? professor.ds_email : '-' }}</span>
    </div>
  </div>

  <div>
    <div *ngIf="isPerfilCompleto">
      <strong>Região</strong>
      <span>{{ professor.endereco_municipio?.uf?.ds_regiao }}</span>
    </div>
    
    <div *ngIf="isPerfilCompleto">
      <strong>Unidade ferativa</strong>
      <span>{{ professor.endereco_municipio?.uf?.ds_uf }}</span>
    </div>

    <div *ngIf="!isPerfilCompleto" class="button">
      <button nz-button nzShape="round" (click)="isPerfilCompleto = true">
        Ver perfil completo
      </button>
    </div>
  </div>
</div>

<div *ngIf="professor" class="info especialista">
  <div>
    <div>
      <strong>Link para o Lattes</strong>
      <a 
        *ngIf="professor.ds_link_lattes; else noLinkTpt" 
        [href]="professor.ds_link_lattes" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Clique aqui
      </a>
  
      <ng-template #noLinkTpt>
        <span>-</span>
      </ng-template>
    </div>
  
    <div>
      <ng-container *ngIf="isView || !st_especialista">
        <strong>Situação</strong>
        <ng-container [ngSwitch]="st_especialista">
          <span *ngSwitchCase="'ATI'">Ativo</span>
          <span *ngSwitchCase="'INA'">Inativo</span>
          <span *ngSwitchDefault>Ativo</span>
        </ng-container>
      </ng-container>
  
      <app-form-items
        *ngIf="!isView && st_especialista"
        [form]="form"
        [attribute]="attributeSituacao"
      ></app-form-items>
    </div>
  </div>
</div>
