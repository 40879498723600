import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import * as _ from 'lodash';

import { FormLayoutControlService } from '../../form-layout-control.service';

@Component({
  selector: 'app-dynamic-form-control',
  templateUrl: './dynamic-form-control.component.html',
  styleUrls: ['./dynamic-form-control.component.less']
})
export class DynamicFormControlComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;
  @Input() isLabelOptional: boolean = false;
  @Output() callback = new EventEmitter();

  items: FormArray;

  formArray: FormArray;
  arrayName: string;
  attributes: any;

  constructor(
    private formLayoutControlService: FormLayoutControlService
  ) { }

  ngOnInit(): void {
    this.arrayName = this.attribute.key;
    this.formArray = this.form.get(this.arrayName) as FormArray;
    this.attributes = _.get(this.attribute, 'formItem.pars.attributes');

    if (!this.initial?.value) {
      this.addItem();
    }
  }

  createItem(): FormGroup {
    return this.formLayoutControlService.toFormGroup(this.attributes);
  }

  addItem(): void {
    this.items = this.form.get(this.arrayName) as FormArray;
    this.items.push(this.createItem());
  }

  removeItem(index: number): void {
    this.items = this.form.get(this.arrayName) as FormArray;
    this.items.removeAt(index);
  }
}
