export const ATTRIBUTE_ENTIDADE_INDICANTE = {
  model: "",
  key: "no_entidade_indicante",
  description: "Entidade Indicante",
  isRequired: true,
  formItem: {
    key: "text",
    placeholder: "Entidade indicante",
    pars: {},
    filters: []
  }
}

export const ATTRIBUTE_PORTARIA = {
  model: "portaria",
  key: "co_portaria",
  description: "Portaria",
  isRequired: true,
  formItem: {
    key: "select-single-search",
    placeholder: "Portaria",
    pars: {
      attributeKey: "co_portaria",
      attributeValue: "ds_titulo"
    },
    filters: [
      {
        model: "",
        filter: ["co_edital", "=", "edital|co_edital"]
      },
      {
        model: "",
        filter: ["co_etapa_ensino", "=", "etapaEnsino|co_etapa_ensino"]
      },
      {
        model: "",
        filter: ["co_objeto", "=", "objeto|co_objeto"]
      }
    ]
  }
}

export const ATTRIBUTE_ATRIBUICAO = {
  model: "",
  key: "st_atribuicao",
  description: "Atribuição",
  isRequired: true,
  formItem: {
    key: "select-single-static-values",
    placeholder: "Atribuição",
    pars: {
      options: [
        {
          value: "TIT",
          description: "Titular"
        },
        {
          value: "SUP",
          description: "Suplente"
        }
      ]
    },
    filters: []
  }
}

export const ATTRIBUTE_SITUACAO = {
  model: "",
  key: "st_especialista",
  description: "Situação",
  isRequired: true,
  formItem: {
    key: "select-single-static-values",
    placeholder: "Situação",
    pars: {
      options: [
        {
          value: "ATI",
          description: "Ativo"
        },
        {
          value: "INA",
          description: "Inativo"
        }
      ]
    },
    filters: []
  }
}