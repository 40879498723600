<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control [nzErrorTip]="errorTpl">
      <textarea
        [id]="attribute?.key"
        type="text"
        nz-input
        [nzAutosize]="attribute?.formItem?.autosize ?
          attribute?.formItem?.autosize : {
            minRows: attribute?.formItem?.rows || 4,
            maxRows: attribute?.formItem?.rows || 4
          }
        "
        [formControlName]="attribute?.key"
        [placeholder]="attribute?.formItem?.placeholder || ''"
      ></textarea>

      <ng-template #errorTpl let-control>
        <app-form-explain 
          [control]="control"
          [minLength]="attribute?.formItem?.maxLength"
          [maxLength]="attribute?.formItem?.maxLength"
          [attribute]="attribute"
        ></app-form-explain>
      </ng-template>

    </nz-form-control>
  </nz-form-item>
</ng-container>
