<ng-container [formGroup]="form" *ngIf="!attribute?.formItem?.pars?.isHidden">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control [nzHasFeedback]="true" [nzErrorTip]="errorTpl">
      <input
        [id]="attribute?.key"
        type="url"
        [pattern]="attribute?.pattern"
        nz-input
        [formControlName]="attribute?.key"
        [placeholder]="attribute?.formItem?.placeholder ? attribute?.formItem?.placeholder : ''"
      >

      <ng-template #errorTpl let-control>
        {{ attribute?.tooltip }}
        <app-form-explain 
          [control]="control"
          [minLength]="attribute?.formItem?.maxLength"
          [maxLength]="attribute?.formItem?.maxLength"
          [attribute]="attribute"
        ></app-form-explain>
      </ng-template>

    </nz-form-control>
  </nz-form-item>
</ng-container>
