import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user.model';
import { ApiService } from 'src/app/shared/services/api.service';
import { MonitoringService } from 'src/app/shared/services/monitoring.service';
import { DS_CHAVE_PROFESSOR } from 'src/app/shared/utils/const';

interface LoginRequestData {
  username: string
  password: string
  captcha: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  urlRedirecionamento;

  constructor(
    private _apiService: ApiService,
    private _monitoringService: MonitoringService,
    private _notification: NzNotificationService,
    private _router: Router,
    private route: ActivatedRoute,
  ) { }

  isLogado() {
    const user = JSON.parse(localStorage.getItem('user')) as User;

    if (user) {
      return true;
    } else {
      return false;
    }
  }


  loginV2(loginData: LoginRequestData): Observable<any> {
    let endpointLoginV2 = 'auth/loginv2/'

    return this._apiService.post<any>(endpointLoginV2, loginData).pipe(
      map(response => {
        let userResponse: any = response.data
        if (this._loginSucess(response)) {
          this.realizeLogin(userResponse)
          return response['data'];
        } else {
          throw new Error(response.error);
        }
      })
    );

  }

  realizeLogin(userResponse: any): void {
    this._monitorUser(userResponse);
    this._initUserSession(userResponse);
    this.pageRedirectFromUser(userResponse);
  }

  resetPasswordV2(loginData: LoginRequestData): Observable<any> {
    let endpointResetPasswordV2 = '/auth/reset-passwordv2'

    return this._apiService.post<any>(endpointResetPasswordV2, loginData).pipe(
      map(response => {
        let userResponse: any = response.data
        if (this._loginSucess(response)) {
          return response['data'];
        } else {
          throw new Error(response.error);
        }
      })
    );

  }

  pageRedirectFromUser(userResponse: any): void {
    if (userResponse.first_login) {
      this._navigateToCreatePassword(userResponse);
    } else if (userResponse.perfil.ds_chave === DS_CHAVE_PROFESSOR && (userResponse.st_declaracao === 'N' || !userResponse.st_declaracao)) {
      this._router.navigate(['/admin/meu-perfil']);
    } else {
      if (this.urlRedirecionamento != null && this.urlRedirecionamento.includes("gerenciar-convites")) {
        this._router.navigate([this.urlRedirecionamento]);
        this.urlRedirecionamento = null;
      } else {
        this._router.navigate(['/admin']);
      }

    }
  }

  private _loginSucess(respLogin: any): boolean {
    return respLogin.status == 'OK';
  }

  private _monitorUser(user: User): void {
    this._monitoringService.monitorUser(user);
  }

  private _initUserSession(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  private _navigateToCreatePassword(user: any): void {
    this._router.navigate(['/criar-senha'], {
      relativeTo: this.route,
      state: {
        username: user.login,
        token: user.first_login_token,
      }
    });
  }

}
