import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-input-url',
  templateUrl: './input-url.component.html',
  styleUrls: ['./input-url.component.less']
})
export class InputUrlComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;

  constructor() { }

  ngOnInit(): void { }

}
