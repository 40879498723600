import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.less']
})
export class CheckboxComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void { }

  safeHtml(html: string){
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
