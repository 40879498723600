import { Injectable } from '@angular/core';

import * as Sentry from "@sentry/angular";
import { Severity } from '@sentry/angular';

import { User } from '../models/user.model';
import { PnldFiltroBase } from '../models/pnled-filtros-base.model';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  constructor() { }

  monitorUser(user: User): void {
    Sentry.setUser({
      'id': user?.co_usuario.toString(),
      'Perfil': {
        "Código": user?.perfil?.co_perfil,
        "Descrição": user?.perfil?.ds_perfil
      }
    });
  }

  forgetUser(): void {
    Sentry.setUser(null);
  }

  setUserInfo(info: any) {
    Sentry.setUser(info);
  }

  setFilterTags(filter: PnldFiltroBase): void {
    Sentry.setTag('edital.codigo', filter.edital.value);
    Sentry.setTag('edital.descricao', filter.edital.valueDescription);
    Sentry.setTag('etapa.codigo', filter.etapaEnsino.value);
    Sentry.setTag('etapa.descricao', filter.etapaEnsino.valueDescription);
    Sentry.setTag('objeto.codigo', filter.objeto.value);
    Sentry.setTag('objeto.descricao', filter.objeto.valueDescription);
  }
}
