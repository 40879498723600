import { Injectable }   from '@angular/core';
import { FormControl, FormGroup, AbstractControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import * as _ from 'lodash';

import { adaptDataEdit } from '../../shared/utils/const';

@Injectable()
export class FormLayoutControlService {

  constructor(private fb: FormBuilder) { }

  toFormGroup(attributes: any[], initials = null) {
    let group: any = {};

    attributes && attributes.forEach(attribute => {
      if(attribute.key != null && attribute.key != ''){
        group[attribute.key] = this.toFormField(attribute, _.result(initials, attribute.key));
      } else {
        const atts = _.result(attribute, 'formItem.pars.attributes') as any[];

        if (atts) {
          atts.forEach(att => {
            if(att.key != null && att.key != ''){
              group[att.key] = this.toFormField(att, _.result(initials, att.key));
            }
          });
        }
      }
    });

    return this.fb.group(group);
  }

  toFormField(attribute: any, initial = null): FormControl | FormGroup | FormArray {
    let validators: any[] = [];
    let maxLength: any;
    let minLength: any;
    let valid: any;
    let formArray: any = [];

    if(attribute.key != null && attribute.key != ''){
      validators = [];
      maxLength = _.result(attribute, 'formItem.maxLength');
      minLength = _.result(attribute, 'formItem.minLength');
      valid = _.result(attribute, 'formItem.pars.valid');

      if (maxLength) {
        validators.push(Validators.maxLength(maxLength));
      }

      if (minLength) {
        validators.push(Validators.minLength(minLength));
      }

      if (attribute.isRequired) {
        validators.push(Validators.required);
      }

      if (_.get(attribute, 'formItem.key') == 'input-email') {
        validators.push(Validators.email);
      }

      switch(_.get(attribute, 'formItem.key')) {
        case 'group':
          const attributesFormGroup = _.get(attribute, 'formItem.pars.attributes');

          return this.toFormGroup(attributesFormGroup, adaptDataEdit(attributesFormGroup, initial));

        case 'dynamic':
        case 'select-double':
          formArray = [];
    
          if(!_.isEmpty(initial) && initial.value?.length > 0){
            const attributesFormArray = _.get(attribute, 'formItem.pars.attributes');
    
            _.map(initial.value, item => {
              formArray.push(this.addItem(attributesFormArray, item));
            });
          }
          
          return this.fb.array(formArray, validators);

        default: 
          return new FormControl({value: initial && initial.value || _.get(attribute, 'formItem.pars.initialValue'), disabled: _.get(attribute, 'isDisabled') || _.get(initial, 'disabled')}, validators);
      }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        for (const key in control.controls) {
          this.validateAllFormFields(control.controls[key] as FormGroup);
        }
      }
    });
  }

  validateFormField(control) {
    if (control instanceof FormControl) {
      control.markAsDirty();
      control.updateValueAndValidity();
    } else if (control instanceof FormGroup) {
      this.validateAllFormFields(control);
    } else if (control instanceof FormArray) {
      for (const key in control.controls) {
        this.validateAllFormFields(control.controls[key] as FormGroup);
      }
    }
  }

  getIndexFormGroup(c: AbstractControl): string | null {
    if(!c.parent) return null;

    const formArray = c.parent.controls;
    return Object.keys(formArray).find(key => c === formArray[key]) || null;
  }

  /*getControlName(c: AbstractControl): string | null {
    if(!c.parent) return null;

    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  getControlPath(c: AbstractControl, path: string): string | null {
    path = this.getControlName(c) + path;

    if(c.parent && this.getControlName(c.parent)) {
      path = "." + path;
      return this.getControlPath(c.parent, path);
    } else {
      return path;
    }

  }*/

  private addItem(attributes: any, values?: any): FormGroup {
    return this.toFormGroup(attributes, values);
  }
}
