import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import * as _ from 'lodash';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.less']
})
export class InputPasswordComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;

  checkConfirm: any;
  checkPassword: any;
  
  constructor() { }

  ngOnInit(): void {
    this.checkConfirm = _.result(this.attribute, 'formItem.pars.checkPassword');
    this.checkPassword = _.result(this.attribute, 'formItem.pars.checkPassword');

    if(this.checkPassword){
      const validators = [
        Validators.maxLength(this.attribute.formItem.maxLength), 
        Validators.minLength(this.attribute.formItem.minLength),
        this.confirmationValidator
      ]

      if(this.attribute.isRequired)
        validators.push(Validators.required);

      this.form.controls[this.attribute.key].setValidators(validators);
    }
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.form.controls[this.checkConfirm.attributeKey].updateValueAndValidity());
  }
  
  confirmationValidator = (control: FormControl): { [ s: string ]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.form.controls[this.checkPassword.attributeKey].value) {
      return { confirm: true, error: true };
    }
    return {};
  }
}
