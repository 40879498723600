import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';

import * as _ from 'lodash';

import { formExplain, URL_API_SEARCH } from '../../../utils/const';

@Component({
  selector: 'app-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.less']
})
export class InputEmailComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;

  /* Lodash */
  _ = _;

  formExplain = formExplain;
  valid: string = '';
  control: FormControl;
  status: string = '';
  uniqueConfig: any;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.control = this.form.get(this.attribute.key);

    if(_.result(this.attribute, 'formItem.pars.validateEmail')){
      this.uniqueConfig = {
        endpoint: `${_.result(this.attribute, 'formItem.pars.validateEmail.endpoint')}${URL_API_SEARCH}`,
        key: _.result(this.attribute, 'formItem.pars.validateEmail.body.keyAttribute'),
        operador: _.result(this.attribute, 'formItem.pars.validateEmail.body.operador'),
      }

      this.control.setValidators([Validators.email, this.validate]);
    }
  }

  validate = (control: FormControl): { [ s: string ]: boolean } => {
    if (!control.value) {
      if(this.attribute.isRequired)
        return { required: true };
      else
        return null;
    } else {

      if(_.result(control.errors, 'invalidUnique')){
        return { invalidUnique: true };
      }

      if(_.result(control.errors, 'validating')){
        return { validating: true };
      }

      if(_.result(control.errors, 'errorServe')){
        return { errorServe: true };
      }
    }

    return {};
  }

  validUnique($event: any) {
    if(this.control.value && !this.control.errors){

      this.control.disable();
      this.control.markAsPending();

      this.api.post(this.uniqueConfig.endpoint, {
        filters: [{
          model: "",
          filter: [this.uniqueConfig.key, this.uniqueConfig.operador, this.control.value]
        }]
      }).subscribe(
        resp => {
          const data = _.result(resp, 'data');
          this.control.enable();

          if(data && data.length > 0)
            this.control.setErrors({ invalidUnique: true });
          else
            this.control.setErrors(null);

          this.control.markAsDirty();
          this.control.updateValueAndValidity();
        }, err => {
          this.control.enable();
          this.control.setErrors({ errorServe: true });
          this.control.markAsDirty();
          this.control.updateValueAndValidity();
        }
      );
    }
  }

  onKey(event: any): void {
    if(this.uniqueConfig && _.result(this.control.errors, 'invalidUnique')){
      this.control.setErrors(null);
      this.control.markAsDirty();
      this.control.updateValueAndValidity();
    }
  }
}
