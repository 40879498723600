<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control [nzHasFeedback]="true" [nzErrorTip]="errorTpl">
      <!-- <input
        *ngIf="!searchCpf && !uniqueConfig"
        [id]="attribute?.key"
        nz-input
        type="text"
        [mask]="mask"
        [prefix]="settingMask?.prefix"
        [suffix]="settingMask?.suffix"
        [hiddenInput]="settingMask?.hiddenInput"
        [showMaskTyped]="settingMask?.showMaskTyped"
        [dropSpecialCharacters]="settingMask?.dropSpecialCharacters"
        [formControlName]="attribute?.key"
        [placeholder]="placeholder ? placeholder : ''"
        (keyup)="_.isObject(settingMask?.mask) && !_.isArray(settingMask?.mask) ? onKey($event) : ''"
      > -->

      <!-- <nz-input-group *ngIf="searchCpf || uniqueConfig" [nzSuffix]="suffixTemplate"> -->

        <input
          [id]="attribute?.key"
          nz-input
          type="text"
          [mask]="mask"
          [prefix]="settingMask?.prefix"
          [suffix]="settingMask?.suffix"
          [hiddenInput]="settingMask?.hiddenInput"
          [showMaskTyped]="settingMask?.showMaskTyped"
          [dropSpecialCharacters]="settingMask?.dropSpecialCharacters"
          [formControlName]="attribute?.key"
          [placeholder]="placeholder ? placeholder : ''"
          (keyup)="(_.isObject(settingMask?.mask) && !_.isArray(settingMask?.mask)) || uniqueConfig ? onKey($event) : ''"
          (blur)="uniqueConfig ? validUnique($event) : ''"
        >
      <!-- </nz-input-group>

      <ng-template #suffixTemplate>
        <i *ngIf="!loadingSearch && !uniqueConfig" nz-icon type="search" nzTheme="outline"></i>
        <i *ngIf="loadingSearch || control.hasError('loading')" nz-icon type="loading" nzTheme="outline" class="icon-loading"></i>
      </ng-template> -->

      <ng-template #errorTpl let-control>        
        <app-form-explain 
          [control]="control"
          [minLength]="minLengthMask || attribute?.formItem?.maxLength"
          [maxLength]="attribute?.formItem?.maxLength"
          [attribute]="attribute"
        ></app-form-explain>
      </ng-template>

    </nz-form-control>
  </nz-form-item>
</ng-container>
