import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

import { PnldFiltroBase } from '../../../../shared/models/pnled-filtros-base.model';
import { ClassificacaoPedagogica } from '../../../../shared/models/classificacao-pedagogica';
import { Edital } from '../../../../shared/models/edital.model';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-classificacao-pedagogica-selects',
  templateUrl: './classificacao-pedagogica-selects.component.html',
  styleUrls: ['./classificacao-pedagogica-selects.component.less']
})
export class ClassificacaoPedagogicaSelectsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() attribute: any;
  @Input() initial: any;
  @Input() isView = false;

  pnldFiltroBase: PnldFiltroBase;
  classificacaoPedagogicas: ClassificacaoPedagogica[];

  options: any[] = [];
  keyValue: string;
  descriptionValue: string;
  isLoading: boolean;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.pnldFiltroBase = JSON.parse(localStorage.getItem('@pnld_filtro_base'));
    this.keyValue = this.attribute.formItem.pars.attributeKey;
    this.descriptionValue = this.attribute.formItem.pars.attributeValue;

    if (this.initial) {
      this.onOpenChange(true);
    }
  }

  onOpenChange(isOpen: boolean): void {
    this._clearAreaConhecimentoErrors();

    if ((this._isAreaConhecimentoSelect() && this._hasCategoriaInForm()) && this._categoriaFormIsNull()) {
      this.options = [];
      this.form.controls["co_area_conhecimento"].setErrors({ categoriaRequired: true })
      return;
    }

    if (isOpen) {
      this.isLoading = true;

      this.api.get<Edital>(`edital/${this.pnldFiltroBase.edital.value}`).subscribe(
        edital => {
          this.classificacaoPedagogicas = edital.classificacao_pedagogicas.filter(
            ({ co_objeto, co_etapa_ensino }) =>
              co_objeto === this.pnldFiltroBase.objeto.value
              && co_etapa_ensino === this.pnldFiltroBase.etapaEnsino.value
          );

          this.classificacaoPedagogicas.forEach(classificacao => {
            if (_.result(classificacao, this.attribute.model)) {
              this.options.push(_.result(classificacao, this.attribute.model));
            }
          });

          // validando se é o combo de area de conhecimento
          // para preencher somente as areas de conhecimento pertinentes a categoria selecionada
          if (this._isAreaConhecimentoSelect() && this._hasCategoriaInForm()) {
            this.options = this._getAreasConhecimentoFromCategoria();
          } else {
            this.options = _.uniqBy(this.options, this.keyValue);
          }
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
          console.log('Error: ', err);
        }
      );
    }


  }

  private _getCategoriaSelecionada(): number {
    return this.form.controls["co_categoria"].value;
  }

  private _hasCategoriaInForm(): boolean {
    return this.form.contains("co_categoria");
  }

  private _isAreaConhecimentoSelect(): boolean {
    return this.attribute.model == "area_conhecimento";
  }

  private _categoriaFormIsNull(): boolean {
    return this._getCategoriaSelecionada() == null;
  }

  private _clearAreaConhecimentoErrors(): void {
    this.form.controls["co_area_conhecimento"].setErrors(null);
  }

  private _getAreasConhecimentoFromCategoria(): any[] {
    let coCategoriaSelecionada = this._getCategoriaSelecionada();
    return _.uniqBy(this.classificacaoPedagogicas.filter(
      classificacao => classificacao.categoria.co_categoria == coCategoriaSelecionada
    ).map(resp => resp.area_conhecimento), 'co_area_conhecimento');
  }

}
