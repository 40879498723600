import { ErrorHandler, Injectable, Injector } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { environment } from './../environments/environment';

@Injectable()
class ApplicationErrorHandler extends ErrorHandler {
  constructor() { super(); }

  handleError(error: any){
    console.error(error);
  }
}

@Injectable()
class SentryErrorHandler extends ErrorHandler {
  constructor() {
    super();

    const api = environment.api.split('//')[1];

    Sentry.init({
      dsn: environment.sentry.DSN,
      release: environment.sentry.release,
      environment: environment.sentry.envName,

      integrations: [
        new BrowserTracing({
          tracingOrigins: [api],
        })
      ],

      // To set a uniform sample rate
      tracesSampleRate: environment.sentry.tracesSampleRate
    });
  }

  handleError(error: any) {
    Sentry.captureException(error.originalError || error);
    if(environment.name !== 'prod') {
      console.error(error);
    }
  }
}

export function getErrorHandler(): ErrorHandler {
  if (environment.name === 'dev-novo-container') {
    return new SentryErrorHandler();
  }
  return new ApplicationErrorHandler();
}
