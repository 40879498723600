import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

import { ApiService } from '../../../../services/api.service';
import { FormLayoutControlService } from '../../../form-layout-control.service';

@Component({
  selector: 'app-modal-create-form',
  templateUrl: './modal-create-form.component.html',
  styleUrls: ['./modal-create-form.component.less']
})
export class ModalCreateFormComponent implements OnChanges {

  @Input() visible = false;
  @Input() title: string;
  @Input() moduleKey: string;
  @Output() onClose = new EventEmitter();

  form: FormGroup;
  attributes: any[];
  model: string;
  loading = false;
  loadingSave = false;
  error: string;

  constructor(
    private api: ApiService,
    private formLayoutControlService: FormLayoutControlService
  ) { }

  ngOnChanges(): void {
    if (this.visible) {
      this.loading = true;
  
      this.api.get(`setup/${this.moduleKey}`).subscribe(
        resp => {
          const attributes = _.result(resp, 'attributes');
          this.attributes = _.filter(attributes, 'isEditable');
          this.model =  _.result(resp, 'model');
          this.form = this.formLayoutControlService.toFormGroup(this.attributes);
          this.loading = false;
        },
        err => {}
      );
    }
  }

  onSubmitForm(): void {
    this.error = '';

    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
    }

    if (this.form.valid) {
      this.loadingSave = true;

      this.api.post(this.model, this.form.value).subscribe(
        resp => {
          this.loadingSave = false;
          this.onClose.emit();
        },
        err => {
          this.loadingSave = false;
          const errorMessage = _.result(err, 'error.error.message');
          this.error = errorMessage || 'Não foi possível cadastrar.';
        }
      );
    }
  }

  close(): void {
    this.error = '';
    this.onClose.emit();
  }
}
