<ng-container [formGroup]="form" *ngIf="!attribute?.formItem?.pars?.isHidden">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control  [nzErrorTip]="errorTpl" [nzExtra]="attribute?.tooltip">
      <nz-select
        [id]="attribute?.key"
        [formControlName]="attribute?.key"
        nzAllowClear
        nzShowSearch
        [nzSuffixIcon]="isLoading ? 'loading' : null"
        [nzMode]="mode"
        [nzServerSearch]="isLoading"
        [nzNotFoundContent]="'Nenhum registro encontrado.'"
        [nzPlaceHolder]="onPlaceholder()"
        [nzMaxMultipleCount]="_.get(attribute, 'formItem.pars.maxMultipleCount') || 'Infinity'"
        [nzSize]="_.get(attribute, 'formItem.pars.size')"
        [nzOpen]="isOpen"
        (nzOnSearch)="!static ? onSearch($event) : ''"
        (nzOpenChange)="!static ? openChange($event) : ''"
        [nzDropdownRender]="other ? otherTemplate : null"
      >
        <ng-container *ngFor="let item of options">
          <nz-option
            *ngIf="!isLoading && !hierarchy"
            [nzValue]="static ? item?.value : _.result(item, keyValue)"
            [nzLabel]="static ? item?.description : _.result(item, descriptionValue)"
          ></nz-option>
          <nz-option
            nzCustomContent
            *ngIf="!isLoading && hierarchy"
            [nzValue]="_.result(item, keyValue)"
            [nzLabel]="_.result(item, descriptionValue)"
          >
            <ng-container [ngSwitch]="_.result(item, hierarchy)">
              <ng-container *ngSwitchCase="0"><strong>{{ _.result(item, descriptionValue) }}</strong></ng-container>
              <ng-container *ngSwitchCase="1">{{ _.result(item, descriptionValue) }}</ng-container>
              <ng-container *ngSwitchCase="2">&nbsp;&nbsp;&nbsp;&nbsp;{{ _.result(item, descriptionValue) }}</ng-container>
              <ng-container *ngSwitchDefault>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull; {{ _.result(item, descriptionValue) }}</ng-container>
            </ng-container>
          </nz-option>
        </ng-container>
        <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
          <i nz-icon nzType="loading" class="loading-icon"></i> Carregando opções...
        </nz-option>
      </nz-select>
      <ng-template #otherTemplate>
        <nz-divider></nz-divider>
        <div class="container">
          <a class="other" (click)="selectOther()">
            {{ other.label }}
          </a>
        </div>
      </ng-template>
      <ng-template #errorTpl let-control>
        <app-form-explain [control]="control" [attribute]="attribute"></app-form-explain>
      </ng-template>

      <!-- <ng-template #extra>
        {{ form.get(attribute?.key)?.value != null ? _.get(attribute, 'formItem.placeholder') || attribute?.tooltip || static ? '' : isSearch ? 'Digite 3 caracteres para pesquisar' : '' : '' }}
      </ng-template> -->

      <div *ngIf="attribute?.formItem?.pars?.moduleKey" class="create-item">
        Se não encontrar um item na lista, <a (click)="openModalCreateItem()">clique aqui para cria-lo</a>
      </div>
    </nz-form-control>
  </nz-form-item>

</ng-container>

<app-modal-create-form
  *ngIf="attribute?.formItem?.pars?.moduleKey"
  [visible]="visibleModalCreateItem"
  [title]="'Novo ' + attribute.description"
  [moduleKey]="attribute.formItem.pars.moduleKey"
  (onClose)="closeModalCreateItem()"
></app-modal-create-form>
