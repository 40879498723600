import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as _ from 'lodash';

import { ApiService } from '../../../services/api.service';
import { URL_API_SEARCH } from '../../../utils/const';

import { 
  Categoria, 
  EtapaEnsino, 
  Objeto, 
  AreaConheciemnto, 
  Componente, 
  ClassificacaoPedagogica,
  ClassificacaoPedagogicaResponse
} from './classificacao-pedagogica-edital.model';

@Component({
  selector: 'app-classificacao-pedagogica-edital',
  templateUrl: './classificacao-pedagogica-edital.component.html',
  styleUrls: ['./classificacao-pedagogica-edital.component.less']
})
export class ClassificacaoPedagogicaEditalComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() attribute: any;
  @Input() initial: any;
  @Input() isView = false;

  validateForm: FormGroup;
  control: AbstractControl;

  controlEtapas: FormControl;
  controlObjeto: FormControl;
  controlCategoria: FormControl;
  controlAreaConhecimento: FormControl;
  controlComponente: FormControl;

  classificacaoPedagogicas: ClassificacaoPedagogica[];
  etapas: EtapaEnsino[];
  objetos: Objeto[];
  categorias: Categoria[];
  area_conhecimentos: AreaConheciemnto[];
  componentes: Componente[];

  classificacoesSelecionadas: ClassificacaoPedagogica[] = [];

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private modal: NzModalService
  ) { }

  ngOnInit(): void {
    if (!this.isView) {
      this.control = this.form.get(this.attribute.key);
      this.controlEtapas = new FormControl({ value: null }, [ Validators.required ]);
      this.controlObjeto = new FormControl({ value: null, disabled: true }, [ Validators.required ]);
      this.controlCategoria = new FormControl({ value: null, disabled: true }, [ Validators.required ]);
      this.controlAreaConhecimento = new FormControl({ value: null, disabled: true });
      this.controlComponente = new FormControl({ value: null, disabled: true });
  
      this.validateForm = this.fb.group({
        co_etapa_ensino: this.controlEtapas,
        co_objeto: this.controlObjeto,
        co_categoria: this.controlCategoria,
        co_area_conhecimento: this.controlAreaConhecimento,
        co_componente: this.controlComponente,
      });

      this.controlEtapas.valueChanges.subscribe((co_etapa_ensino: number) => {
        if (co_etapa_ensino) {
          this.controlObjeto.enable();
        } else {
          this.validateForm.patchValue({
            co_objeto: null,
            co_categoria: null,
            co_area_conhecimento: null,
            co_componente: null,
          });
  
          this.controlObjeto.disable();
          this.controlCategoria.disable();
          this.controlAreaConhecimento.disable();
          this.controlComponente.disable();
        }
  
        this.objetos = this.filter('objeto', {
          co_etapa_ensino,
        });
      });
  
      this.controlObjeto.valueChanges.subscribe((co_objeto: number) => {
        if (co_objeto) {
          this.controlCategoria.enable();
        } else {
          this.validateForm.patchValue({
            co_categoria: null,
            co_area_conhecimento: null,
            co_componente: null,
          });
  
          this.controlCategoria.disable();
          this.controlAreaConhecimento.disable();
          this.controlComponente.disable();
        }
  
        this.categorias = this.filter('categoria', {
          co_etapa_ensino: this.controlEtapas.value,
          co_objeto,
        });
      });
  
      this.controlCategoria.valueChanges.subscribe((co_categoria: number) => {
        if (co_categoria) {
          this.controlAreaConhecimento.enable();
          this.controlComponente.enable();
        } else {
          this.validateForm.patchValue({
            co_area_conhecimento: null,
            co_componente: null,
          });
  
          this.controlAreaConhecimento.disable();
          this.controlComponente.disable();
        }
  
        this.area_conhecimentos = this.filter('area_conhecimento', {
          co_etapa_ensino: this.controlEtapas.value,
          co_objeto: this.controlObjeto.value,
          co_categoria,
        });

        this.componentes = this.filter('componente', {
          co_etapa_ensino: this.controlEtapas.value,
          co_objeto: this.controlObjeto.value,
          co_categoria: this.controlCategoria.value,
          co_area_conhecimento: null,
        });
      });
  
      this.controlAreaConhecimento.valueChanges.subscribe((co_area_conhecimento: number) => {
        
        this.componentes = this.filter('componente', {
          co_etapa_ensino: this.controlEtapas.value,
          co_objeto: this.controlObjeto.value,
          co_categoria: this.controlCategoria.value,
          co_area_conhecimento,
        });
        this.controlComponente.setValue(null);
      });
    }

    this.api.post<ClassificacaoPedagogicaResponse>(`classificacao-pedagogica${URL_API_SEARCH}`, {}).subscribe(
      ({ data }) => {
        this.classificacaoPedagogicas = data;

        const initial = this.initial?.value as number[];

        if (initial?.length > 0) {
          this.classificacoesSelecionadas = this.classificacaoPedagogicas.filter(classificacao => {
            return initial.indexOf(classificacao.co_classif_pedag) > -1;
          });
        }

        this.etapas = _.uniqWith(_.map(
          this.classificacaoPedagogicas, c => c.etapa_ensino
        ), _.isEqual);
      }, err => {
        console.log('Error: ', err);
      }
    );
  }

  filter(key: string, filter: any) {
    const classificacoes = _.filter(this.classificacaoPedagogicas, filter);
    const items = [];
    
    _.forEach(classificacoes, classificacao => {
      if (!classificacao[key]) {
        return;
      }

      items.push(classificacao[key]);
    });

    return _.uniqWith(items, _.isEqual);
  }
  
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
    }

    if (this.validateForm.valid) {
      const { 
        co_etapa_ensino, 
        co_objeto, 
        co_categoria, 
        co_area_conhecimento, 
        co_componente,
      } = this.validateForm.getRawValue();

      const classificacaoPedagogica = _.find(this.classificacaoPedagogicas, { 
        co_etapa_ensino, 
        co_objeto, 
        co_categoria, 
        co_area_conhecimento, 
        co_componente
      });

      if (classificacaoPedagogica) {
        const value = this.control.value || [];
        value.push(classificacaoPedagogica.co_classif_pedag);
        
        this.control.setValue(value);

        this.classificacoesSelecionadas = [
          ...this.classificacoesSelecionadas, 
          classificacaoPedagogica
        ];
  
        this.validateForm.reset();

        this.controlObjeto.disable();
        this.controlCategoria.disable();
        this.controlAreaConhecimento.disable();
        this.controlComponente.disable();
        
        this.objetos = [];
        this.categorias = [];
        this.area_conhecimentos = [];
        this.componentes = [];
      } else {
        this.modal.info({
          nzTitle: 'Classificação pedagógica não encontrada',
          nzContent: 'A combinação dos campos selecionados não correspondem a uma classificação pedagógica.',
        });
      }
    }
  }

  removeItem(id: number): void {
    const value = this.control.value.filter((v: number) => v !== id);
    this.control.setValue(value);

    this.classificacoesSelecionadas = this.classificacoesSelecionadas.filter(
      c => c.co_classif_pedag !== id
    );
  }
}
