import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-home',
  templateUrl: './landing-home.component.html',
  styleUrls: ['./landing-home.component.less']
})
export class LandingHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
