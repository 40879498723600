import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { ApiService } from '../../../../shared/services/api.service';
import { URL_API_SEARCH } from '../../../../shared/utils/const';
import { FormLayoutControlService } from '../../form-layout-control.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

interface ResponseItems {
  data: Array<{
    [key: string]: any;
  }>,
  status: 'ERROR' | 'OK',
  message?: string;
}

@Component({
  selector: 'app-filter-model',
  templateUrl: './filter-model.component.html',
  styleUrls: ['./filter-model.component.less']
})
export class FilterModelComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() attribute: any;
  @Input() initial: any;

  formFilter: FormGroup;
  isLoading = false;
  searchChange$ = new BehaviorSubject('');
  attributes: Array<{
    key: string;
    [key: string]: any;
  }> = [];

  items: Array<{
    [key: string]: any;
  }>;
  isVisibleModalFilter = false;
  endpoint: string;
  attributeKey: string;
  attributeValue: string;
  selectedValue: string;
  mapResponseToSetup: Array<{
    fromResponse: string;
    toSetupAttribute: string;
    disableSetupAttribute: boolean;
  }>;

  constructor(
    private api: ApiService,
    private formLayoutControlService: FormLayoutControlService,
    private notification: NzNotificationService
  ) { }

  ngOnInit(): void {
    this.attributes = _.result(this.attribute, 'formItem.pars.attributes');
    this.endpoint = _.result(this.attribute, 'formItem.pars.endpoint');
    this.attributeKey = _.result(this.attribute, 'formItem.pars.attributeKeySearch');
    this.attributeValue = _.result(this.attribute, 'formItem.pars.attributeValueSearch');
    this.mapResponseToSetup = _.result(this.attribute, 'formItem.pars.mapResponseToSetup');

    this.formFilter = this.formLayoutControlService.toFormGroup(this.attributes);

    this.searchChange$.asObservable().pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap<string, Observable<ResponseItems>>(value => {
        if (value) {
          this.isLoading = true;

          let filters: Array<{ model: string; filter: string[] }> = [];

          filters.push({
            model: '',
            filter: [this.attributeKey, 'like', `%${value}%`]
          });

          this.attributes.forEach(att => {
            const value = this.formFilter.get(att.key).value;

            if (value) {
              filters.push({
                model: '',
                filter: [att.key, '=', value]
              })
            }
          });
    
          return this.api.post<ResponseItems>(`${this.endpoint}${URL_API_SEARCH}`, { 
            filters 
          });
        } else {
          return from([])
        }
      })
    ).subscribe(
      response => {
        if (response.status === 'ERROR') {
          this.notification.error(
            "Erro!", 
            response.message
          );
        } else {
          this.items = response.data;
          this.isLoading = false;
        }
      }, err => {
        console.log('Error: ', err);

        this.notification.error(
          'Erro',
          'Contate o administrador ou tente novamente mais tarde.'
        );
      }
    );
  }

  onSearch(value: string): void {
    this.searchChange$.next(value);
  }

  onOpenModalFilter(): void {
    this.isVisibleModalFilter = true;
  }

  onCloseModalFilter(): void {
    this.isVisibleModalFilter = false;
  }

  onUseModel(): void {
    const existIndex = this.items.findIndex(
      item => item[this.attributeValue] === this.selectedValue
    );

    if(this.mapResponseToSetup != null && existIndex > -1){
      this.mapResponseToSetup.map(rs => {
        const itemValues = this.items[existIndex];

        this.form.get(rs.toSetupAttribute).setValue(
          _.result(itemValues, rs.fromResponse)
        );
        
        if(rs.disableSetupAttribute) {
          this.form.get(rs.toSetupAttribute).disable();
        }
      });
    }
  }

  onFilter(): void {
    this.items = [];
    this.isVisibleModalFilter = false;
    this.selectedValue = '';
  }
}
