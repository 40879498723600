import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { NZ_DATE_LOCALE, NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { ptBR } from 'date-fns/locale';
import pt from '@angular/common/locales/pt';

import * as Sentry from "@sentry/angular";

import { AppRoutingModule } from './app.routing.module';
import { AuthGuard } from './shared/guards/auth.guard';
import { ApiService } from './shared/services/api.service';
import { PermissionsService } from './shared/services/permissions.service';
import { AppComponent } from './app.component';
import { getErrorHandler } from './app.error-handler';
import { ModuleService } from './shared/services/module.service';
import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { SharedModule } from './shared/shared.module';
import { SessionExpirationInterceptor } from './session-expiration.interceptor';
import { EvaluationInstrumentService } from './shared/services/evaluation-instrument.service';
import { UsuarioService } from './shared/services/usuario.service';

registerLocaleData(pt);

// export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    // NgxMaskModule.forRoot(options)
    NgxMaskModule.forRoot(),
    EditorModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: pt_BR },
    { provide: NZ_DATE_LOCALE, useValue: ptBR },
    { provide: ErrorHandler, useFactory: getErrorHandler },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: SessionExpirationInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    ApiService,
    AuthGuard,
    PermissionsService,
    ModuleService,
    EvaluationInstrumentService,
    UsuarioService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
