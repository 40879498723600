import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-container-form',
  templateUrl: './container-form.component.html',
  styleUrls: ['./container-form.component.less']
})
export class ContainerFormComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() attribute: any;
  @Input() initial: any;
  @Output() callback = new EventEmitter();

  attributes: any[];

  constructor() { }

  ngOnInit(): void {
    this.attributes = _.result(this.attribute, 'formItem.pars.attributes');
  }
}
