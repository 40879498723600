<div class="container-classificacao-pedagogica-edital">
  <strong>{{attribute?.description}}</strong>

  <form *ngIf="!isView" nz-form nzLayout="vertical" [formGroup]="validateForm" class="guia-row">
    <nz-form-item class="col-4">
      <nz-form-label nzFor="co_etapa_ensino" nzRequired>Etapa</nz-form-label>
      <nz-form-control>
        <nz-select 
          nzShowSearch 
          nzAllowClear 
          nzPlaceHolder="Etapa"
          formControlName="co_etapa_ensino"
        >
          <nz-option 
            *ngFor="let etapa of etapas" 
            [nzLabel]="etapa?.ds_etapa_ensino" 
            [nzValue]="etapa?.co_etapa_ensino"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    
    <nz-form-item class="col-4">
      <nz-form-label nzFor="co_objeto" nzRequired>Objeto</nz-form-label>
      <nz-form-control>
        <nz-select 
          nzShowSearch 
          nzAllowClear 
          nzPlaceHolder="Objeto"
          formControlName="co_objeto"
        >
          <nz-option 
            *ngFor="let objeto of objetos" 
            [nzLabel]="objeto?.ds_objeto" 
            [nzValue]="objeto?.co_objeto"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    
    <nz-form-item class="col-4">
      <nz-form-label nzFor="co_categoria" nzRequired>Categoria</nz-form-label>
      <nz-form-control>
        <nz-select 
          nzShowSearch 
          nzAllowClear 
          nzPlaceHolder="Categoria"
          formControlName="co_categoria"
        >
          <nz-option 
            *ngFor="let categoria of categorias" 
            [nzLabel]="categoria?.ds_categoria" 
            [nzValue]="categoria?.co_categoria"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    
    <nz-form-item class="col-4">
      <nz-form-label nzFor="co_area_conhecimento">Área de Conhecimento</nz-form-label>
      <nz-form-control>
        <nz-select 
          nzShowSearch 
          nzAllowClear 
          nzPlaceHolder="Área de Conhecimento"
          formControlName="co_area_conhecimento"
        >
          <nz-option 
            *ngFor="let area_conhecimento of area_conhecimentos" 
            [nzLabel]="area_conhecimento?.ds_area_conhecimento" 
            [nzValue]="area_conhecimento?.co_area_conhecimento"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    
    <nz-form-item class="col-4">
      <nz-form-label nzFor="co_componente">Componente</nz-form-label>
      <nz-form-control>
        <nz-select 
          nzShowSearch 
          nzAllowClear 
          nzPlaceHolder="Componente"
          formControlName="co_componente"
        >
          <nz-option 
            *ngFor="let componente of componentes" 
            [nzLabel]="componente?.ds_componente" 
            [nzValue]="componente?.co_componente"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <div class="col-4 col-button">
      <button nz-button nzShape="circle" (click)="submitForm()">
        <i nz-icon nzType="plus" nzTheme=""></i>
      </button>
    </div>
  </form>

  <table>
    <thead>
      <tr>
        <th class="item">#</th>
        <th>Etapa</th>
        <th>Objeto</th>
        <th>Categoria</th>
        <th>Área de Conhecimento</th>
        <th>Componente</th>
        <th *ngIf="!isView" class="item"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="classificacoesSelecionadas.length > 0; else templateItem">
        <tr *ngFor="let classificacao of classificacoesSelecionadas; let index = index">
          <td class="item">{{index + 1}}</td>
          <td>{{classificacao.etapa_ensino?.ds_etapa_ensino}}</td>
          <td>{{classificacao.objeto?.ds_objeto}}</td>
          <td>{{classificacao.categoria?.ds_categoria}}</td>
          <td>{{classificacao.area_conhecimento?.ds_area_conhecimento}}</td>
          <td>{{classificacao.componente?.ds_componente}}</td>
          <td *ngIf="!isView" class="item">
            <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nzPlacement="bottomRight">
              <i nz-icon nzType="more" nzTheme="outline"></i>
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="removeItem(classificacao.co_classif_pedag)">
                  <span>
                    <i nz-icon nzType="delete" nzTheme="fill"></i>
                    Excluir
                  </span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </ng-container>

      <ng-template #templateItem>
        <tr>
          <td class="item">1</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td *ngIf="!isView" class="item">
            <i nz-icon nzType="more" nzTheme="outline"></i>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>

<app-fases 
  [form]="form" 
  [classificacoes]="classificacoesSelecionadas"
  [initial]="initial?.fases"
  [isView]="isView"
></app-fases>