<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control [nzErrorTip]="errorTpl">

    <nz-tree-select
      *ngIf="!loading"
      [formControlName]="attribute?.key"
      [nzPlaceHolder]="attribute?.formItem?.placeholder ? attribute?.formItem?.placeholder : ''"
      [nzDropdownStyle]="{ 'max-height': '300px' }"
      [nzNodes]="treeData"
      [(ngModel)]="value"
      [nzAsyncData]="true"
      nzShowSearch
      [nzDisabled]="isDisabled"
    ></nz-tree-select>

    <div *ngIf="loading" class="loading-tree-select">
      <i nz-icon nzType="loading" nzTheme="outline"></i> Carregando...
    </div>

    <ng-template #errorTpl let-control>
      <app-form-explain [control]="control" [attribute]="attribute"></app-form-explain>
    </ng-template>

  </nz-form-control>
</nz-form-item>
</ng-container>
