import { Component, OnInit, Input } from '@angular/core';

import { formExplain } from '../../../../shared/utils/const';

@Component({
  selector: 'app-re-captcha-control',
  templateUrl: './re-captcha-control.component.html',
  styleUrls: ['./re-captcha-control.component.less']
})
export class ReCaptchaControlComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;

  formExplain = formExplain;
  
  constructor() { }

  ngOnInit() {
  }

}
