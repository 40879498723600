<app-label
  [attribute]="lblClassificacaoPedagogicaEspecialista"
></app-label>
<div class="container-classificacao-pedagogica-especialista">
  <strong>{{attribute?.description}}</strong>

  <form *ngIf="!isView" nz-form nzLayout="vertical" [formGroup]="validateForm" class="guia-row">        
    <nz-form-item class="col-3">
      <nz-form-label nzFor="co_categoria">Categoria</nz-form-label>
      <nz-form-control>
        <nz-select 
          nzShowSearch 
          nzAllowClear 
          nzPlaceHolder="Categoria"
          formControlName="co_categoria"
        >
          <nz-option 
            *ngFor="let categoria of categorias" 
            [nzLabel]="categoria?.ds_categoria" 
            [nzValue]="categoria?.co_categoria"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    
    <nz-form-item class="col-3">
      <nz-form-label nzFor="co_area_conhecimento">Área de Conhecimento</nz-form-label>
      <nz-form-control>
        <nz-select 
          nzShowSearch 
          nzAllowClear 
          nzPlaceHolder="Área de Conhecimento"
          formControlName="co_area_conhecimento"
        >
          <nz-option 
            *ngFor="let area_conhecimento of area_conhecimentos" 
            [nzLabel]="area_conhecimento?.ds_area_conhecimento" 
            [nzValue]="area_conhecimento?.co_area_conhecimento"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    
    <nz-form-item class="col-3">
      <nz-form-label nzFor="co_componente">Componente</nz-form-label>
      <nz-form-control>
        <nz-select 
          nzShowSearch 
          nzAllowClear 
          nzPlaceHolder="Componente"
          formControlName="co_componente"
        >
          <nz-option 
            *ngFor="let componente of componentes" 
            [nzLabel]="componente?.ds_componente" 
            [nzValue]="componente?.co_componente"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <div class="col-3 col-button">
      <button nz-button nzShape="round" (click)="submitForm()">
        Adicionar Classificação
      </button>
    </div>
  </form>

  <table [ngClass]="{'error': control?.dirty && control?.errors}">
    <thead>
      <tr>
        <th class="item">#</th>
        <th>Categoria</th>
        <th>Área de Conhecimento</th>
        <th>Componente</th>
        <th *ngIf="!isView" class="item"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="classificacoesSelecionadas.length > 0; else templateItem">
        <tr *ngFor="let classificacao of classificacoesSelecionadas; let index = index">
          <td class="item">{{index + 1}}</td>
          <td>{{classificacao.categoria?.ds_categoria}}</td>
          <td>{{classificacao.area_conhecimento?.ds_area_conhecimento}}</td>
          <td>{{classificacao.componente?.ds_componente}}</td>
          <td *ngIf="!isView" class="item">
            <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nzPlacement="bottomRight">
              <i nz-icon nzType="more" nzTheme="outline"></i>
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="removeItem(classificacao.co_classif_pedag)">
                  <span>
                    <i nz-icon nzType="delete" nzTheme="fill"></i>
                    Excluir
                  </span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </ng-container>

      <ng-template #templateItem>
        <tr *ngIf="!(control?.dirty && control?.errors)" class="empty-data">
          <td [attr.colspan]="5">
            Não existem dados cadastrados
          </td>
        </tr>
        
        <tr *ngIf="control?.dirty && control?.errors" class="error">
          <td [attr.colspan]="5">
            <i nz-icon nzType="close-circle" nzTheme="fill"></i>
            Campo obrigatório
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>