<!-- <h2 class="label" *ngIf="attribute?.description != ''">{{ attribute?.description }}</h2>

{{form.value | json}}

<div class="content" [ngClass]="{ 'disabled': form.get(attribute.key).disabled, 'error': formArray?.dirty && formArray?.errors && formArray.hasError('required') }">
  <ng-container *ngIf="form.get(arrayName)?.value?.length == 0">

    <ng-container *ngIf="formArray?.dirty && formArray?.errors && formArray.hasError('required'); else info">
      <div class="intro error">
        <i nz-icon nzType="close-circle" nzTheme="outline"></i>
        <h2>
          <span>Campo obrigatório!</span>
          Cadastre um ou mais itens preenchendo o formulário abaixo e clicando em "Adicionar."
        </h2>
      </div>
    </ng-container>
    
    <ng-template #info>
      <div class="intro">
        <i nz-icon nzType="info-circle" nzTheme="outline"></i>
        <h2>Cadastre um ou mais itens preenchendo o formulário abaixo e clicando em "Adicionar."</h2>
      </div>
    </ng-template> 
    
  </ng-container>

  <div class="items" *ngFor="let item of formArray.value; let i = index; let first = first;" [ngClass]="{'first': first, 'active': i == indexActive}">
    <div class="item">
      <i nz-icon nzType="file" nzTheme="outline" class="icon-file"></i>
      <div class="values">
        <ng-container *ngFor="let data of tratarDataDisplay(item)">
          <div class="title">{{data.title}}</div>
          <ng-container [ngSwitch]="data?.type">
      
            <div *ngSwitchCase="'dynamic'" class="description comma">
              <div *ngFor="let items of data?.description" class="description-content">
                <div class="icon">
                  <i *ngIf="data.icon" nz-icon [type]="data.icon" nzTheme="outline"></i>
                </div>
                <div class="text">
                  <ng-container *ngFor="let item of items">
                    <span *ngIf="item.description">{{ item?.description }}</span>
                  </ng-container>
                </div>
              </div>
            </div>
            
            <div *ngSwitchCase="'upload-file'" class="description">
              <a [href]="data?.description?.link" target="_blank">{{ data?.description?.description }}</a>
            </div>

            <div *ngSwitchDefault class="description">
              {{ data?.description ? data?.description : '-' }}
            </div>
          
          </ng-container>
        </ng-container>
      </div>

      <div *ngIf="!form.get(attribute.key).disabled" class="actions">
        <button nz-button (click)="i != indexActive ? editItem(i, item) : ''" [disabled]="i == indexActive" class="edit">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
        </button>
        <button nz-button (click)="i != indexActive ? removeItem(i, item) : ''" [disabled]="i == indexActive" class="delete">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="items" *ngFor="let item of formArray.value; let i = index; let first = first;" [ngClass]="{'first': first, 'active': i == indexActive}">
    {{item | json}}
  </div>

  <div class="form" *ngIf="!form.get(attribute.key).disabled">
    <app-form-layout
      *ngIf="validateForm != null"
      [attributes]="attributes"
      [form]="validateForm"
    ></app-form-layout>

    <div class="actions">
      <button nz-button nzType="primary" (click)="indexActive >= 0 ? editValue() : submitForm()">{{ indexActive >= 0 ? 'Salvar' : 'Adicionar' }}</button>
      <button nz-button nzType="default" (click)="cancelar()" *ngIf="indexActive >= 0">Cancelar</button>
    </div>
  </div>  
</div> -->

<div class="container-dynamic">
  <strong *ngIf="attribute?.description">{{attribute?.description}}</strong>

  <form *ngIf="validateForm != null && !isView" nz-form nzLayout="vertical" [formGroup]="validateForm" class="guia-row">
    <div
      *ngFor="let attribute of attributes"
      [ngClass]="!attribute?.formItem?.pars?.gridClass ? 'col-12' : attribute?.formItem?.pars?.gridClass"
      [ngStyle]="attribute?.formItem?.pars?.gridWidth ? {'max-width': attribute?.formItem?.pars?.gridWidth} : {}"
    >
      <div
        [ngStyle]="attribute?.formItem?.pars?.controlWidth ? {'max-width': attribute?.formItem?.pars?.controlWidth} : {}"
      >
        <app-form-items
          [attribute]="attribute"
          [initial]="initial"
          [form]="validateForm"
          (callback)="callback($event)"
        ></app-form-items>
      </div>
    </div>
    
    <button nz-button nzShape="circle" (click)="submitForm()">
      <i nz-icon nzType="plus" nzTheme=""></i>
    </button>
  </form>

  <table [ngClass]="{'error': control?.dirty && control?.errors}">
    <thead>
      <tr>
        <th class="item">#</th>
        <th *ngFor="let att of attributes">{{att.description}}</th>
        <th *ngIf="!isView" class="item"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="valuesTable.length > 0; else templateEmptyData">
        <tr *ngFor="let item of valuesTable; let index = index">
          <td class="item">{{index + 1}}</td>
          <td *ngFor="let att of attributes">{{ item[att.key] }}</td>
          <td *ngIf="!isView" class="item">
            <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nzPlacement="bottomRight">
              <i nz-icon nzType="more" nzTheme="outline"></i>
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="removeItem(index)">
                  <span>
                    <i nz-icon nzType="delete" nzTheme="fill"></i>
                    Excluir
                  </span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <ng-template #templateEmptyData>
    <tr *ngIf="!(control?.dirty && control?.errors)" class="empty-data">
      <td [attr.colspan]="attributes.length + 2">
        Não existem dados cadastrados
      </td>
    </tr>
    
    <tr *ngIf="control?.dirty && control?.errors" class="error">
      <td [attr.colspan]="attributes.length + 2">
        <i nz-icon nzType="close-circle" nzTheme="fill"></i>
        Campo obrigatório
      </td>
    </tr>
  </ng-template>
</div>