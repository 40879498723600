import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { NzUploadFile, UploadFilter, NzUploadXHRArgs, NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { ApiService } from '../../../services/api.service';
import { formExplain, MIMETYPES, URL_API, URL_FILE } from '../../../utils/const';

@Component({
  selector: 'app-upload-file-2',
  templateUrl: './upload-file-2.component.html',
  styleUrls: ['./upload-file-2.component.less']
})
export class UploadFile2Component implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;

  formExplain = formExplain;

  uploadControl: FormControl;
  fileList: NzUploadFile[] = [];
  isValidUpload: boolean = false;
  action: string = `${URL_API}/storage/put`;
  header: {
    Accept: string;
    Authorization: string;
  }
  limit: number = 1;
  isUnlimited = false;
  values: any[] = [];
  fileTypes = null;
  keyAttribute = '';
  hashAttribute = '';

  isUploadReset = false;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user'));

    this.header = {
      'Accept': 'application/x.edutec.v1+json',
      'Authorization': user != null ? user.access_token : ''
    }

    this.uploadControl = this.form.get(this.attribute.key) as FormControl;
    this.keyAttribute = _.result(this.attribute, 'formItem.pars.keyAttribute');
    this.hashAttribute = _.result(this.attribute, 'formItem.pars.hashAttribute');
    this.limit = _.result(this.attribute, 'formItem.pars.limit') || 1;
    this.isUnlimited = _.result(this.attribute, 'formItem.pars.isUnlimited') || false;
    this.fileTypes = _.result(this.attribute, 'formItem.pars.fileTypes') || null;

    if (!_.isEmpty(this.initial)) {
      this.files(this.initial.value);
    }

    if (this.uploadControl.value) {
      this.files(this.uploadControl.value);
    }

    this.uploadControl.valueChanges.subscribe(value => {
      this.files(value);
    });
  }

  files(values): void {
    if (!values) {
      this.fileList = [];
      this.values = [];
    } else {
      this.fileList = [];
      this.values = values;

      this.values.map((item, index) => {
        this.fileList.push({
          uid: `${index}`,
          name: item[this.keyAttribute],
          status: 'done',
          url: `${URL_FILE}${item[this.keyAttribute]}`,
          response: item
        })
      });
    }
  }

  handleChange(info: NzUploadChangeParam): void {
    //Se não passar na validação antes do upload
    //não deve fazer upload do arquivo

    let fileList = info.fileList;

    if (!this.isUnlimited) {
      fileList = fileList.slice(-this.limit);
    }

    this.fileList = fileList.map(file => {
      if (file.response) {
        file.name = file.response.key;
        file.url = `${URL_FILE}${file.response.key}`;
      }
      return file;
    });

    if (info.file.status === 'done') {
      this.values.push({
        [this.keyAttribute]: info.file.response.key,
        [this.hashAttribute]: new Date().getTime(),
      });

      this.uploadControl.setValue(this.values);
    }
  }

  // customReq = (item: NzUploadXHRArgs) => {
  //   let formData = new FormData();
  //   formData.append('file', item.file as any);

  //   return this.api.upload(this.action, formData).subscribe((event: HttpEvent<{}>) => {
  //     if (event.type === HttpEventType.UploadProgress) {
  //       if (event.total > 0) {
  //         (event as any).percent = event.loaded / event.total * 100;
  //       }
  //       item.onProgress(event, item.file);
  //     } else if (event instanceof HttpResponse) {
  //       item.onSuccess(event.body, item.file, event);

  //       this.values.push({
  //         [this.keyAttribute]: event.body['key'],
  //         [this.hashAttribute]: new Date().getTime(),
  //       });

  //       this.uploadControl.setValue(this.values);
  //     }
  //   }, (err) => {
  //     item.onError(err, item.file);
  //     this.uploadControl.markAsDirty();
  //     this.uploadControl.setErrors({ errorserve: true });
  //   });
  // }

  // beforeUpload = (file: File) => {
  //   //const isPDF = file.type === 'application/pdf';
  //   /*if (!isPDF) {
  //     this.form.controls[ this.attribute.key ].markAsDirty();
  //     this.uploadControl.setErrors({notype: true});
  //     this.isValidUpload = false;
  //     return this.isValidUpload;
  //   }*/
  //   const isLt10M = file.size / 1024 / 1024 <= 10;
  //   if (!isLt10M) {
  //     this.uploadControl.markAsDirty();
  //     this.uploadControl.setErrors({ nosize: true });
  //     this.isValidUpload = false;
  //     return this.isValidUpload;
  //   }
  //   this.isValidUpload = true;

  //   if (!this.attribute.manualSend) return;

  //   //return isPDF && isLt10M;
  //   return false;
  // }

  handleRemove = (file: NzUploadFile) => {
    this.values = this.values.filter((v) => v[this.keyAttribute] !== file.name);
    this.fileList = this.fileList.filter((f) => f.name !== file.name);

    this.uploadControl.setValue(this.values);

    return true;
  };

  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: NzUploadFile[]) => {

        if (this.fileTypes) {

          const tiposPermitidos = _.map(this.fileTypes, tipo => {
            const tipoExpanded = _.find(MIMETYPES, { 'type': tipo });
            return tipoExpanded ? tipoExpanded.expanded : null;
          });

          const filterFiles = fileList.filter(w => ~tiposPermitidos.indexOf(w.type));
          if (filterFiles.length !== fileList.length) {

            this.formExplain.errorUpload = `Somente os tipos a seguir são permitidos: ${_.join(this.fileTypes, ', ')}`;
            this.uploadControl.markAsDirty();
            this.uploadControl.setErrors({ errorserve: true });
            return filterFiles;
          }
        }
        return fileList;
      }
    },
    {
      name: 'limit',
      fn: (fileList: NzUploadFile[]) => {
        if ((this.limit - this.values.length) < fileList.length && !this.isUnlimited) {
          this.formExplain.errorUpload = `Não é permitido carregar mais que ${this.limit} arquivo(s).`;
          this.uploadControl.markAsDirty();
          this.uploadControl.setErrors({ errorserve: true });
          return [];
        }

        return fileList;
      }
    }
  ];
}
