import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatData'
})
export class FormatDataPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
  
    let dataFormatada = null;
    if (value.length > 10) { //se for timestamp
      dataFormatada = moment(value).format("DD/MM/YYYY HH:mm")
    } else {
      dataFormatada = moment(value).format("DD/MM/YYYY")
    }
  
    if (dataFormatada === 'Invalid date' || !dataFormatada) {
      return false
    }
    return dataFormatada;
  }

}
