import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.less']
})
export class GroupComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;
  @Input() isLabelOptional: boolean = false;
  @Output() callback = new EventEmitter();
  
  attributes: any;

  constructor() { }

  ngOnInit(): void {
    this.attributes = _.get(this.attribute, 'formItem.pars.attributes');
  }

}
