import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { FormLayoutControlService } from '../../form-layout-control.service';
import { ApiService } from '../../../../shared/services/api.service';
import { DEFAULT_DESCRIPTION_PREFIX } from '../../../../shared/utils/const';

@Component({
  selector: 'app-select-double',
  templateUrl: './select-double.component.html',
  styleUrls: ['./select-double.component.less']
})
export class SelectDoubleComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;
  @Output() callback = new EventEmitter();

  /* lodash */
  _ = _;

  items: FormArray;
  formArray: FormArray;
  arrayName: string;
  attributes: any;

  attributeOne: any;
  attributeTwo: any;
  isLoading: boolean = false;
  selects: Array<{ one: { options: any[]; value: string }; two: { options: any[]; disabled: boolean } }> = [];
  optionsOne = [];
  optionsOneNo = [];
  optionsTwo = [];

  modeOne = 'default';
  modeTwo = 'default';
  
  keyValueOne: string;
  descriptionValueOne: string;

  keyValueTwo: string;
  descriptionValueTwo: string;

  constructor(
    private api: ApiService,
    private formLayoutControlService: FormLayoutControlService
  ) { }

  ngOnInit(): void {
    this.arrayName = this.attribute.key;
    this.formArray = this.form.get(this.arrayName) as FormArray;
    this.attributes = _.get(this.attribute, 'formItem.pars.attributes');
    this.attributeOne = _.find(this.attributes, att => _.result(att, 'formItem.pars.field') == 'one');
    this.attributeTwo = _.find(this.attributes, att => _.result(att, 'formItem.pars.field') == 'two');
    
    /* One */
    if(_.result(this.attributeOne, 'formItem.key') == 'select-multiple-search') this.modeOne = 'multiple';
    else this.modeOne = 'default';

    if(_.isEmpty(this.attributeOne.formItem.pars.attributeKey))
      this.keyValueOne = this.attributeOne.key;
    else
      this.keyValueOne = this.attributeOne.formItem.pars.attributeKey;

    if(_.isEmpty(this.attributeOne.formItem.pars.attributeValue))
      this.descriptionValueOne = DEFAULT_DESCRIPTION_PREFIX + _.snakeCase(this.attributeOne.key);
    else
      this.descriptionValueOne = this.attributeOne.formItem.pars.attributeValue;
        
    /* Two */
    if(_.result(this.attributeTwo, 'formItem.key') == 'select-multiple-search') this.modeTwo = 'multiple';
    else this.modeTwo = 'default';

    if(_.isEmpty(this.attributeTwo.formItem.pars.attributeKey))
      this.keyValueTwo = this.attributeTwo.key;
    else
      this.keyValueTwo = this.attributeTwo.formItem.pars.attributeKey;

    if(_.isEmpty(this.attributeTwo.formItem.pars.attributeValue))
      this.descriptionValueTwo = DEFAULT_DESCRIPTION_PREFIX + _.snakeCase(this.attributeTwo.key);
    else
      this.descriptionValueTwo = this.attributeTwo.formItem.pars.attributeValue;

    this.addItem();
  }

  createItem(): FormGroup {
    return this.formLayoutControlService.toFormGroup(this.attributes);
  }

  addItem(): void {
    this.items = this.form.get(this.arrayName) as FormArray;
    this.items.push(this.createItem());
    this.selects.push({
      one: { options: [], value: null },
      two: { options: [], disabled: true }
    });
  }
  
  removeItem(index: number): void {
    const selectsRemove = this.selects[index];
    this.items = this.form.get(this.arrayName) as FormArray;
    this.items.removeAt(index);
    this.optionsOneNo = this.optionsOneNo.filter(o => o != selectsRemove.one.value);
    this.selects = this.selects.filter(s => s != selectsRemove);
  }

  onFetch(field: string, index: number){
    this.isLoading = true;

    if(field == 'one'){
      this.api.get<any[]>(this.attributeOne.model).subscribe(resp => {
        this.optionsOne = resp;
        this.selects[index].one.options = this.optionsOne;
        this.isLoading = false;
      });
    }

    if(field == 'two'){
      this.api.get<any[]>(this.attributeTwo.model).subscribe(resp => {
        this.optionsTwo = resp;
        this.selects[index].two.options = this.optionsTwo.filter(
          item => item[this.keyValueOne] == this.selects[index].one.value
        );
        this.isLoading = false;
      });
    }
  }
  
  openChange(isOpen: boolean, field: string, index: number): void{
    if(isOpen){
      if(field == 'one'){
        if(this.optionsOne.length == 0)
          this.onFetch(field, index);
        else {
          if(this.optionsOneNo.length > 0){
            this.selects[index].one.options = this.optionsOne.filter(
              item => this.optionsOneNo.indexOf(item[this.keyValueOne]) < 0 || item[this.keyValueOne] == this.selects[index].one.value
            );
          } else {
            this.selects[index].one.options = this.optionsOne;
          }
        }
      } 

      if(field == 'two') {
        if(this.optionsTwo.length == 0)
          this.onFetch(field, index);
        else {
          this.selects[index].two.options = this.optionsTwo.filter(
            item => item[this.keyValueOne] == this.selects[index].one.value
          );
        }
      }
    }    
  }

  modelChange(value: any, field: string, index: number, formGroup?: FormGroup){
    const optionActive = [];
    let item = null;

    if(field == 'one'){
      if(value){
        if(this.selects[index].one.value && this.selects[index].one.value != value)
          this.optionsOneNo = this.optionsOneNo.filter(o => o != this.selects[index].one.value);
        
        this.optionsOneNo.push(value);
        this.selects[index].one.value = value;
        this.selects[index].two.disabled = false;
      } else {
        this.optionsOneNo = this.optionsOneNo.filter(o => o != this.selects[index].one.value);
        this.selects[index].one.value = null;
        this.selects[index].two.disabled = true;

        if(formGroup)
          formGroup.get(this.attributeTwo.key).reset();
      }

      if( _.result(this.attribute, 'formItem.key') != 'select-single-static-values'){
        if(_.isArray(value)){
          _.map(value, v => {
            item = _.find(this.optionsOne, option => option[this.keyValueOne] == v);
            if(item) optionActive.push({ value: item[this.keyValueOne], option: item[this.descriptionValueOne]});
          });
        } else {
          item = _.find(this.optionsOne, option => option[this.keyValueOne] == value);
          if(item) optionActive.push({ value: item[this.keyValueOne], option: item[this.descriptionValueOne]});
        }
        
        this.callback.emit({ field: `${this.attributeOne.key}_${this.formLayoutControlService.getIndexFormGroup(formGroup)}`, options: optionActive});
      }
    }
    
    if(field == 'two'){
      if( _.result(this.attribute, 'formItem.key') != 'select-single-static-values'){
        if(_.isArray(value)){
          _.map(value, v => {
            item = _.find(this.optionsTwo, option => option[this.keyValueTwo] == v);
            if(item) optionActive.push({ value: item[this.keyValueOne], option: item[this.descriptionValueTwo]});
          });
        } else {
          item = _.find(this.optionsTwo, option => option[this.keyValueTwo] == value);
          if(item) optionActive.push({ value: item[this.keyValueOne], option: item[this.descriptionValueTwo]});
        }
        
        this.callback.emit({ field: `${this.attributeTwo.key}_${this.formLayoutControlService.getIndexFormGroup(formGroup)}`, options: optionActive});
      }
    }
  }
}
