<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control [nzErrorTip]="errorTpl">
      <div class="summernote-rich-text-area" [ngClass]="{'focus': isFucos && !loadingUpload}" [ngClass]="{'no-note-statusbar': attribute?.formItem?.pars?.isNoSize}">
        <div
          [formControlName]="attribute?.key"
          [ngxSummernote]="config"
          [ngxSummernoteDisabled]="editorDisabled"
          (blur)="onBlur()"
          (focus)="onFocus()"
          (imageUpload)="isUpload($event)">
        </div>
        <div class="loading-upload" [ngClass]="{'error': isSizeUpload || isTypeUpload}" *ngIf="loadingUpload">
          <h2>
            <i nz-icon [nzType]="isSizeUpload || isTypeUpload ? 'close-circle' : 'loading'" nzTheme="outline"></i>

            <ng-container *ngIf="isTypeUpload">
              {{ formExplain?.typeUpload }}
            </ng-container>

            <ng-container *ngIf="isSizeUpload && !isTypeUpload">
              {{ formExplain?.sizeUpload(10) }}
            </ng-container>

            <div class="actions">
              <button *ngIf="isSizeUpload || isTypeUpload" nz-button (click)="action()">Ok</button>
            </div>
          </h2>
        </div>
      </div>

      <ng-template #errorTpl let-control>
        <app-form-explain [control]="control" [attribute]="attribute"></app-form-explain>
      </ng-template>

    </nz-form-control>
  </nz-form-item>
</ng-container>
