import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.less']
})
export class RadioComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;

  constructor() { }

  ngOnInit(): void {
    if (this.form.controls[this.attribute.key]) {
      const { options, defaultValue } = this.attribute.formItem.pars;
      const index = this.form.controls[this.attribute.key].value ? options.findIndex(el => this.form.controls[this.attribute.key].value === el.value) : -1;
      this.form.controls[this.attribute.key].setValue((index > -1) ? options[index].value : defaultValue);
    }
  }

}
