<nz-form-item [formGroup]="form">
  <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
  <nz-form-control  [nzErrorTip]="errorTpl" [nzExtra]="attribute?.tooltip">
    <nz-select
      [id]="attribute?.key"
      [formControlName]="attribute?.key"
      nzAllowClear
      [nzSuffixIcon]="isLoading ? 'loading' : null"
      [nzServerSearch]="isLoading"
      [nzNotFoundContent]="'Nenhum registro encontrado.'"
      [nzPlaceHolder]="attribute?.formItem?.placeholder"
      (nzOpenChange)="onOpenChange($event)"
    >
      <ng-container *ngFor="let item of options">
        <nz-option
          *ngIf="!isLoading"
          [nzValue]="item[keyValue]"
          [nzLabel]="item[descriptionValue]"
        ></nz-option>
      </ng-container>

      <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
        <i nz-icon nzType="loading" class="loading-icon"></i> Carregando opções...
      </nz-option>
    </nz-select>

    <ng-template #errorTpl let-control>
      <app-form-explain [control]="control" [attribute]="attribute"></app-form-explain>
    </ng-template>
  </nz-form-control>
</nz-form-item>
