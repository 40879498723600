import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-clear-button',
  templateUrl: './clear-button.component.html',
  styleUrls: ['./clear-button.component.less']
})
export class ClearButtonComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() attribute: any;

  button: {
    title: string;
    type?: 'primary' | 'default' | 'dashed' | 'danger' | 'link' | 'text' | null;
    shape?: 'circle' | 'round' | null;
    align?: 'center' | 'left' | 'right';
  }

  clearAttributes: string[];

  ngOnInit(): void {
    this.button = _.result(this.attribute, 'formItem.pars.button');
    this.clearAttributes = _.result(this.attribute, 'formItem.pars.attributes');
  }

  onClear(): void {
    const clear = this.clearAttributes.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: null
      }),
      {}
    );

    this.form.patchValue(clear);
  }
}
