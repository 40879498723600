import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

import { formExplain, validalitorValue, URL_API_SEARCH } from '../../../utils/const';
import { debounceTime, distinctUntilChanged, switchMap, catchError, delay } from 'rxjs/operators';
import { from } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.less']
})
export class InputMaskComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;

  /* Lodash */
  _ = _;

  control: FormControl;
  settingMask: any;
  mask = '';
  minLengthMask: string;
  searchCpf: any;
  loadingSearch = false;
  placeholder = '';
  uniqueConfig: any;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.control = this.form.get(this.attribute.key);
    this.settingMask = _.get(this.attribute, 'formItem.pars.settingMask');
    this.searchCpf = _.get(this.attribute, 'formItem.pars.searchCpf');
    this.placeholder = _.get(this.attribute, 'formItem.placeholder');

    if(_.result(this.attribute, 'formItem.pars.validate')){
      this.uniqueConfig = {
        endpoint: `${_.result(this.attribute, 'formItem.pars.validate.endpoint')}${URL_API_SEARCH}`,
        key: _.result(this.attribute, 'formItem.pars.validate.body.keyAttribute'),
        operador: _.result(this.attribute, 'formItem.pars.validate.body.operador'),
      }
    }

    if (this.uniqueConfig || this.settingMask.valid) this.control.setValidators([this.valid]);

    if(_.isObject(this.settingMask.mask) && !_.isArray(this.settingMask.mask)){
      let one = this.settingMask.mask.one.replace(/\D/g, '');
      let two = this.settingMask.mask.two.replace(/\D/g, '');
      this.minLengthMask = `${one.length - 1} ou ${two.length}`;

      if(!_.isEmpty(this.initial) && this.initial.value != null){
        let mask = this.settingMask.mask.one.replace(/\D/g, '');
        let value = this.initial.value.replace(/\D/g, '');

        if(value.length < mask.length)
          this.mask = this.settingMask.mask.one;
        else
          this.mask = this.settingMask.mask.two;
      } else {
        this.mask = this.settingMask.mask.one;
      }
    } else if(_.isArray(this.settingMask.mask)){
      this.mask = this.settingMask.mask[0].typeMask;
      this.minLengthMask = `${this.mask.replace(/[^a-zA-Z0-9]/g, '').replace(/9/g, '').length}`;
    } else {
      this.mask = this.settingMask.mask;
      this.minLengthMask = `${this.mask.replace(/[^a-zA-Z0-9]/g, '').replace(/9/g, '').length}`;
    }

    if(_.isArray(this.settingMask.mask)){
      this.form.get(this.settingMask.attributeKey).valueChanges.subscribe(value => {
        if(value){
          this.mask = _.find(this.settingMask.mask, i => i.typePhone == value).typeMask;
          this.placeholder = this.mask;
          this.minLengthMask = `${this.mask.replace(/[^a-zA-Z0-9]/g, '').replace(/9/g, '').length}`;
          this.control.markAsDirty();
          this.control.updateValueAndValidity();
        }
      });
    }

    if(this.searchCpf){
      this.control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(value => {
          if(value && validalitorValue.cpf(value.replace(/\D/g, ""))){
            this.loadingSearch = true;

            return this.api.post(this.searchCpf.endpoint, { [this.attribute.key]: value.replace(/\D/g, "") })
              .pipe(catchError(error => {
                  this.loadingSearch = false;
                  this.control.setErrors({ invalidCPF: true });
                return from([]);
              }))
          } else {
            if(value && value.replace(/\D/g, "").length == 11)
              this.control.setErrors({ invalidCPF: true });
            return from([])
          }
        })
      ).subscribe(
        resp => {
          if(this.searchCpf.mapResponseToSetup != null){
            if(resp){
              let value = null;

              _.map(this.searchCpf.mapResponseToSetup, (item) => {
                if(!item.value){
                  value = _.result(resp, item.fromResponse);
                  if(item.formtDate){
                    this.form.get(item.toSetupAttribute).setValue(moment(value, item.formtDate).isValid() ? moment(value).format(item.formtDate) : null);
                  } else {
                    this.form.get(item.toSetupAttribute).setValue(value);
                  }
                } else {
                  this.form.get(item.toSetupAttribute).setValue(item.value);
                }

                if(item.disableSetupAttribute || _.result(resp, _.split(item.fromResponse, '.', 1)[0]))
                  this.form.get(item.toSetupAttribute).disable();
                else
                  this.form.get(item.toSetupAttribute).enable();
              });
            }
          }

          this.loadingSearch = false;
        }
      );
    }
  }

  valid = (control: FormControl): { [ s: string ]: boolean } => {
    if (!control.value) {
      if(this.attribute.isRequired) return { required: true };
      else return;
    } else {
      if(this.settingMask.valid == "date" && !moment(control.value, "DD/MM/YYYY").isValid()) {
        return { invalidDate: true };
      }

      if(this.settingMask.valid == "cnpj" && !validalitorValue.cnpj(control.value)) {
        return { invalidCNPJ: true };
      }

      if(this.settingMask.valid == "cpf" && !validalitorValue.cpf(control.value)) {
        return { invalidCPF: true };
      }

      if(_.result(control.errors, 'invalidUnique')){
        return { invalidUnique: true };
      }

      if(_.result(control.errors, 'validating')){
        return { validating: true };
      }

      if(_.result(control.errors, 'errorServe')){
        return { errorServe: true };
      }
    }

    return {};
  }

  validUnique($event: any) {
    if($event.target.value && !this.control.errors){
      const value = $event.target.value.replace(/\D/g, "");

      this.control.disable();
      this.control.markAsPending();

      this.api.post(this.uniqueConfig.endpoint, {
        filters: [{
          model: "",
          filter: [this.uniqueConfig.key, this.uniqueConfig.operador, value ]
        }]
      }).subscribe(
        resp => {
          const data = _.result(resp, 'data');
          this.control.enable();

          if(data !== null && data.length > 0) {
            this.control.setErrors({ invalidUnique: true });
          } else {
            this.control.setErrors(null);
          }

          this.control.markAsDirty();
          this.control.updateValueAndValidity();
        }, err => {
          this.control.enable();
          this.control.setErrors({ errorServe: true });
          this.control.markAsDirty();
          this.control.updateValueAndValidity();
        }
      );
    }
  }

  onKey(event: any): void {
    if(this.uniqueConfig && _.result(this.control.errors, 'invalidUnique')){
      this.control.setErrors(null);
      this.control.markAsDirty();
      this.control.updateValueAndValidity();
    }

    if(_.isObject(this.settingMask.mask) && !_.isArray(this.settingMask.mask)){
      let mask = this.settingMask.mask.one.replace(/\D/g, '');
      let value = event.target.value.replace(/\D/g, '');

      if(value.length < mask.length)
        this.mask = this.settingMask.mask.one;
      else
        this.mask = this.settingMask.mask.two;
    }
  }

  /*validate = (control: FormControl): { [ s: string ]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if(control.value.length == _.get(this.attribute, 'formItem.mask').length) {

      const normalizedValue = control.value.replace(/\D/g, "");

      if (normalizedValue === this.initial.value) {
        return {};
      }

      //this.setState({ validate: 'validating' });

      this.apiService.post(this.attribute.formItem.pars.validate.url, {
        filters: [[
          this.attribute.formItem.pars.validate.body.keyAttribute,
          this.attribute.formItem.pars.validate.body.operador,
          normalizedValue
        ]]
      }).subscribe(resp => {
        if (_.result(resp, 'status') == 'OK') {
          if (_.get(resp, 'data.size') < 1) {
            //this.setState({ validate: 'success' });
            return {};
          } else if (
            this.initial
            && this.initial.value
            && (resp['data']['data'][0][this.attribute.key] === this.initial.value.replace(/\D/g, ""))
          ) {
            //this.setState({ validate: 'success' });
            return {};
          } else {
            //this.setState({ validate: 'error' });
            //callback(helpStatusUser(attribute.formItem.pars.validate.help));
            return { invalid: true };
          }
        } else {
          //this.setState({ validate: 'error' })
          //callback('Erro ao se comunicar com o servidor')
          return { invalid: true };
        }
      }, error => {
        //this.setState({ validate: 'error' })
        console.log('axios error: ', error);
        //callback('Erro ao se comunicar com o servidor')
        return { invalid: true };
      });
    }
  }*/
}
