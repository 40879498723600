<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <div class="help-text" *ngIf="attribute?.helpText">{{ attribute?.helpText }}</div>
    <nz-form-control [nzErrorTip]="errorTpl"  [nzExtra]="attribute?.tooltip">
      <nz-radio-group *ngIf="form.controls[this.attribute.key]" [formControlName]="attribute?.key" nzButtonStyle="solid">
        <span [ngSwitch]="attribute?.formItem?.key">

          <ng-container *ngSwitchCase="'radio'">
            <label
              nz-radio
              [ngClass]="attribute?.formItem?.pars?.direction ? [attribute?.formItem?.pars?.direction] : []"
              [nzValue]="item?.value"
              [nzDisabled]="item?.disabled || false"
              *ngFor="let item of attribute?.formItem?.pars?.options"
            >
            {{ item?.description }}
            </label>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <label
              nz-radio-button
              [class]="'ant-radio-button-' + item?.type"
              [nzValue]="item?.value"
              [nzDisabled]="item?.disabled || false"
              *ngFor="let item of attribute?.formItem?.pars?.options"
            >
              <i *ngIf="item?.icon" nz-icon [nzType]="item?.icon" nzTheme="outline"></i>{{ item?.description }}
            </label>
          </ng-container>
        </span>
      </nz-radio-group>

      <ng-template #errorTpl let-control>
        <app-form-explain [control]="control" [attribute]="attribute"></app-form-explain>
      </ng-template>

    </nz-form-control>
  </nz-form-item>
</ng-container>
