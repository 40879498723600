<ng-container *ngIf="control?.hasError('required')">
  {{ HELPER_MESSAGE.MSG004(attribute?.description) }}
</ng-container>

<ng-container *ngIf="control?.hasError('validating')">
  {{ formExplain?.validatingValue() }}
</ng-container>

<ng-container *ngIf="control?.hasError('Mask error')">
  {{ helperMessage.MSG107(minLength) }}
</ng-container>

<ng-container *ngIf="!control?.hasError('Mask error') && control?.hasError('invalidDate')">
  {{ helperMessage.MSG004(attribute?.description) }}
</ng-container>

<ng-container *ngIf="!control?.hasError('Mask error') && control?.hasError('invalidCPF')">
  {{ helperMessage.MSG004(attribute?.description) }}
</ng-container>

<ng-container *ngIf="!control?.hasError('Mask error') && control?.hasError('invalidCNPJ')">
  {{ helperMessage.MSG004(attribute?.description) }}
</ng-container>

<ng-container *ngIf="!control?.hasError('Mask error') && control?.hasError('errorServe')">
  {{ formExplain?.errorServe }}
</ng-container>

<ng-container *ngIf="!control?.hasError('Mask error') && control?.hasError('invalidUnique')">
  {{ formExplain?.uniqueValue() }}
</ng-container>

<ng-container *ngIf="control?.hasError('invalidCEP')">
  {{ helperMessage.MSG004(attribute?.description) }}
</ng-container>

<ng-container *ngIf="control?.hasError('minlength')">
  {{ helperMessage.MSG107(minLength) }}
</ng-container>

<ng-container *ngIf="control?.hasError('maxlength')">
  {{ helperMessage.MSG108(maxLength) }}
</ng-container>

<ng-container *ngIf="control?.hasError('confirm')">
  {{ helperMessage.MSG114
</ng-container>

<ng-container *ngIf="control?.hasError('email')">
  {{ helperMessage.MSG004(attribute?.description) }}
</ng-container>

<ng-container *ngIf="control?.hasError('nosize')">
  {{ formExplain?.sizeUpload(sizeUpload) }}
</ng-container>

<ng-container *ngIf="control?.hasError('errorserve')">
  {{ formExplain?.errorUpload }}
</ng-container>

<ng-container *ngIf="control?.hasError('categoriaRequired')">
  {{ HELPER_MESSAGE.MSG073 }}
</ng-container>