import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-input-text-area',
  templateUrl: './input-text-area.component.html',
  styleUrls: ['./input-text-area.component.less']
})
export class InputTextAreaComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;
  
  constructor() { }

  ngOnInit() {
  }

}
