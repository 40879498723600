import * as _ from "lodash";
import * as moment from 'moment';
import { environment } from "../../../environments/environment";
import { PnldFiltroBase } from "../models/pnled-filtros-base.model";

/**
 * Referente às conexões
 */
export const URL_API = environment.api;
export const URL_FILE = environment.urlPDFAmazon;
export const JASPER_PATH = environment.jasperPath;
export const URL_API_SEARCH = "/advanced-search";

/**
 * Referente aos modulos
 */
export const DEFAULT_MODULE = "avaliacao";
export const DEFAULT_PRIMARY_KEY_PREFIX = "co_";
export const DEFAULT_DESCRIPTION_PREFIX = "ds_";

export const DS_CHAVE_PROFESSOR = "professor";

export const DISABLE_REVIEWER = true;

/**
 * @description Converts a cammelCase, PascalCase and Hifen-Case to an underscore_string.
 * @param {String} cammelCaseString
 * @returns {String} underscore_string
 */
export const convertCammelCaseToUnderscoreCase = cammelCaseString =>
  cammelCaseString
    .replace(/\.?([A-Z.-]+)/g, function(x, y) {
      return y === "-" ? "_" : "_" + y.toLowerCase();
    })
    .replace(/^_/, "");

export const treatObjectValue = (value, attribute) => {
  const attributeKey = attribute.key;
  const attributeId =
    attribute.formItem.pars.attributeKey || `co_${attributeKey}`;
  const attributeValue = attribute.formItem.pars.attributeValue || attributeId;

  let options = [];
  let values = [];

  if (_.isArray(value)) {
    //Se for array, será recebido o valor de attribute.key com um S no final,
    //indicando que é um relacionamento M:N. É o padrão do lumen.
    //Aqui estou retirando o S para poder pegar o valor da chave primária e da descrição.

    value.map(v => {
      options.push({
        [attributeId]: _.result(v, attributeId),
        [attributeValue]: _.result(v, attributeValue)
      });
      values.push(_.result(v, attributeId));
    });
  } else {
    options = [
      {
        [attributeId]: _.result(value, attributeId),
        [attributeValue]: _.result(value, attributeValue) //TODO considerar attributeValue com subníveis
      }
    ];
    values = _.result(value, attributeId);
  }

  return {
    [attributeKey]: {
      value: values,
      options: options
    }
  };
};

/* Animation */
export class animation {
  static slideUp (element, duration = 500) {
    element.style.height = element.offsetHeight + 'px'
    element.style.transitionProperty = 'height'
    element.style.transitionDuration = duration + 'ms'
    element.offsetHeight
    element.style.overflow = 'hidden'
    element.style.height = 0
    window.setTimeout(function(){
      element.style.display = 'none'
      element.style.removeProperty('height')
      element.style.removeProperty('overflow')
      element.style.removeProperty('transition-property')
      element.style.removeProperty('transition-duration')
    }, duration)
  }
  static slideDown (element, duration = 500) {
    element.style.removeProperty('display')
    let display = window.getComputedStyle(element).display
    if (display === 'none') display = 'block'
    element.style.display = display
    let height = element.offsetHeight
    element.style.overflow = 'hidden'
    element.style.height = 0
    element.offsetHeight
    element.style.transitionProperty = 'height'
    element.style.transitionDuration = duration + 'ms'
    element.style.height = height + 'px'
    window.setTimeout(function(){
      element.style.removeProperty('height')
      element.style.removeProperty('overflow')
      element.style.removeProperty('transition-property')
      element.style.removeProperty('transition-duration')
    }, duration)
  }
  static slideToggle (element, duration = 500) {
    let display = window.getComputedStyle(element).display
    if(display === 'none') {
      this.slideDown(element, duration)
    } else {
      this.slideUp(element, duration)
    }
  }
}

export const formatarData = (dataStr) => {

  if (!dataStr) {
    return dataStr;
  }

  let dataFormatada = null;
  if (dataStr.length > 10) { //se for timestamp
    dataFormatada = moment(dataStr).format("DD/MM/YYYY HH:mm")
  } else {
    dataFormatada = moment(dataStr).format("DD/MM/YYYY")
  }

  if (dataFormatada === 'Invalid date' || !dataFormatada) {
    return false
  }
  return dataFormatada;
}

export const adaptDataEdit = (attributes, datas) => {
  let data = {};
  let value = null
  let model = null

  attributes.forEach(attribute => {
    const atts = _.result(attribute, 'formItem.pars.attributes');

    switch (attribute.formItem.key) {
      case "select-single-search":
        model = _.result(attribute, 'formItem.pars.responseAttributeKey') || _.snakeCase(attribute.model);
        value = _.result(datas, model);

        if (!_.isEmpty(value)) {
          Object.assign(data, treatObjectValue(value, attribute));
        }
        break;
      case "select-multiple-search":
        const modelKey = `${attribute.key.replace('-', '_')}`;
        model = _.result(attribute, 'formItem.pars.responseAttributeKey') || `${_.snakeCase(attribute.model)}s`;
        value = _.result(datas, model) || _.result(datas, modelKey);
        if (!_.isEmpty(value)) {
          Object.assign(data, treatObjectValue(value, attribute))
        }
        break;
      case "checkbox":
        value = _.result(datas, attribute.key);
        Object.assign(data, { [attribute.key]: { value: value === 'S' ? true : false } })
        break;
      case "radio-button":
        value = _.result(datas, attribute.key);
        Object.assign(data, { [attribute.key]: { value: `${value}` } });
        break;
      case "tree-select-single":
        model = _.snakeCase(attribute.model)
        value = _.result(datas, model)
        const initial = treatObjectValue(value, attribute);

        initial['fator']['value'] = `${initial['fator']['value']}`;

        if (!_.isEmpty(value)) {
          Object.assign(data, initial);
        }
        break;

      case "classificacao-pedagogica-edital":
        const classificacoes = _.result(datas, attribute.key) || [];
        const fases = _.result(datas, 'fases') || [];

        Object.assign(data, { [attribute.key]: {
          value: classificacoes.map(c => c.co_classif_pedag),
          fases
        }});
        break;

      case "classificacao-pedagogica-especialista":
        const rlClassificacoesPedagogicas = _.result(datas, attribute.key) || [];
        const classificacoesPedagogicaEspecialista = _.map(rlClassificacoesPedagogicas, 'classificacao_pedagogica');

        Object.assign(data, { [attribute.key]: {
          value: classificacoesPedagogicaEspecialista.map(c => c.co_classif_pedag),
        }});
        break;

      case "classificacao-pedagogica":
        const classificacoesPedagogica = _.result(datas, attribute.key) || [];

        Object.assign(data, { [attribute.key]: {
          value: classificacoesPedagogica.map(c => c.co_classif_pedag),
        }});
        break;

      case "membro-especialista":
      case "membro-comissao":
        const professor = _.result(datas, 'professor') || {};
        const portaria = _.result(datas, 'portaria') || {};
        const st_especialista = _.result(datas, 'st_especialista');
        const st_membro_comissao = _.result(datas, 'st_membro_comissao');
        const no_entidade_indicante = _.result(datas, 'no_entidade_indicante');
        const st_atribuicao = _.result(datas, 'st_atribuicao');

        Object.assign(data, { [attribute.key]: {
          value: _.result(datas, attribute.key),
          data: {
            professor,
            portaria,
            no_entidade_indicante,
            st_atribuicao,
            st_especialista,
            st_membro_comissao
          },
        }});
        break;

      case 'dynamic':
        value = _.result(datas, attribute.key) || [];
        let values = [];

        _.forEach(value, v => values.push(adaptDataEdit(atts, v)));

        Object.assign(data, { [attribute.key]: { value: values } });

        break;

      case "container-form":
        Object.assign(data, adaptDataEdit(atts, datas));

        break;

      default:
        const keyValue = _.result(attribute, 'formItem.pars.responseAttributeKey') || attribute.key;
        value = _.result(datas, keyValue);
        Object.assign(data, { [attribute.key]: { value: value } });
        break;
    }
  });

  return data;
};


export const handleDataDisplaySteps = (values, attributes, optionsSelects, index = null) => {
  let confirm = [];

  const atts = _.filter(attributes, i => i.formItem.pars && !i.formItem.pars.isNoConfirm);

  atts.forEach(attribute => {

    switch (attribute.formItem.key) {

      case 'select-single-search':
        const select = optionsSelects[index != null ? `${attribute.key}_${index}` : attribute.key];
        confirm.push({
          title: _.result(attribute, 'description'),
          attribute: attribute,
          description: _.result(select, '0.option')
        });
        break;

      case 'select-multiple-search':
        const description = [];
        _.map(optionsSelects[index != null ? `${attribute.key}_${index}` : attribute.key], select => {
          description.push(select.option);
        });
        confirm.push({
          title: _.result(attribute, 'description'),
          attribute: attribute,
          description: description
        })
        break;

      case 'select-double':
        const attributes = _.get(attribute, 'formItem.pars.attributes');
        const attributeOne = _.find(attributes, att => _.result(att, 'formItem.pars.field') == 'one');
        const attributeTwo = _.find(attributes, att => _.result(att, 'formItem.pars.field') == 'two');
        let items =[];
        let optionOne = [];
        let optionTwo = [];

        _.map(_.result(values, attribute.key), (v, i) => {
          optionOne = _.result(optionsSelects, `${attributeOne.key}_${i}`);
          optionTwo = [];

          _.map(_.result(optionsSelects, `${attributeTwo.key}_${i}`), select => {
            optionTwo.push(select.option);
          });

          items.push({
            one: _.result(optionOne, '0.option'),
            two: optionTwo,
          })
        });

        confirm.push({
          title: `${_.result(attributeTwo, 'description')} (${_.result(attributeOne, 'description')})`,
          attribute: attribute,
          attributeOne: _.result(attributeOne, 'description'),
          attributeTwo: _.result(attributeTwo, 'description'),
          description: items
        })
        break;

      case 'select-single-static-values':
      case 'radio-button':
      case 'radio':
        const objSelecionado = _.find(attribute.formItem.pars.options, { 'value': _.get(values, attribute.key) });
        if (!objSelecionado) {
          confirm.push({
            title: _.result(attribute, 'description'),
            attribute: attribute,
            description: _.get(values, attribute.key)
          })
        } else {
          confirm.push({
            title: _.result(attribute, 'description'),
            attribute: attribute,
            description: objSelecionado.description
          })
        }
        break;

      case 'upload-file':
        const textDescription = _.get(values, attribute.key);
        confirm.push({
          title: _.result(attribute, 'description'),
          attribute: attribute,
          description: {
            description: textDescription,
            link: `${URL_FILE}${textDescription}`
          }
        })
        break;

      case 'datePicker':
        const dataFormatada = formatarData(_.get(values, attribute.key));
        if (dataFormatada) {
          confirm.push({
            title: _.result(attribute, 'description'),
            attribute: attribute,
            description: dataFormatada
          })
        } else {
          confirm.push({
            title: _.result(attribute, 'description'),
            attribute: attribute,
            description: _.get(values, attribute.key)
          })
        }
        break;

      case 'input-cep':
      case 'input-mask':
        const settingMask =  _.result(attribute, 'formItem.pars.settingMask');
        const valueDependent = _.get(values, _.result(settingMask, 'attributeKey'));
        let maskAtt = '';

        if(_.isArray(settingMask.mask)){
          const maskActive =  _.find(settingMask.mask, i => i.typePhone == valueDependent);
          maskAtt = maskActive ? maskActive.typeMask : '';
        } else {
          maskAtt = settingMask.mask;
        }
        confirm.push({
          title: _.result(attribute, 'description'),
          attribute: attribute,
          description: {
            description: _.get(values, attribute.key),
            mask: maskAtt
          }
        })
        break;

      case 'number':
        const before = _.result(attribute, 'formItem.pars.before');
        const after = _.result(attribute, 'formItem.pars.after');
        let v =  _.get(values, attribute.key);

        if(before) v = `${before} ${v}`;
        if(after) v = `${v} ${after}`;

        confirm.push({
          title: _.result(attribute, 'description'),
          attribute: attribute,
          description: v
        });
        break;

      case 'input-hidden':
        break;

      case 'dynamic':
        let dynamicValues = [];
        let dynamicAttributes = [];

        dynamicAttributes = _.result(attribute, 'formItem.pars.attributes');

        _.map(_.result(values, attribute.key), (value, index) => {
          dynamicValues.push(handleDataDisplaySteps(value, dynamicAttributes, optionsSelects, index));
        });

        confirm.push({
          title: _.result(attribute, 'description'),
          attribute: attribute,
          description: dynamicValues
        })
        break;

      case 'group':
        let groupValues = {};
        let groupAttributes = [];

        groupAttributes = _.result(attribute, 'formItem.pars.attributes');
        groupValues = handleDataDisplaySteps(
          _.result(values, attribute.key),
          groupAttributes,
          optionsSelects,
          attribute.key
        );

        confirm.push({
          title: _.result(attribute, 'description'),
          attribute: attribute,
          description: groupValues
        })
        break;

      default:
        confirm.push({
          title: _.result(attribute, 'description'),
          attribute: attribute,
          description: _.get(values, attribute.key)
        })
        break;
    }
  });

  return confirm;
}

export const handleDataDisplay = (values, attributes) => {
  let valuesTratado = [];
  let title = undefined;
  let description = undefined;
  let id = undefined;
  let dynamicValues = [];

  const atts = _.filter(attributes, i => i.formItem.pars && !i.formItem.pars.isNoConfirm);

  atts.forEach(attribute => {
    title = attribute.description;
    dynamicValues = [];

    switch (attribute.formItem.key) {

      case "classificacao-pedagogica-selects":
      case 'select-single-search':
        id = _.get(values, attribute.formItem.pars.attributeKey) || "";
        const atributoValueConcatenado = _.snakeCase(attribute.model) + "." + attribute.formItem.pars.attributeValue;
        if(_.get(values, attribute.key)){
          description = {
            id: id,
            model: attribute.model,
            description: _.get(values, atributoValueConcatenado)
          }
        } else description = ""
        break;

      case 'select-multiple-search':
        description = []
        _.get(values, attribute.key) ? _.get(values, attribute.key).forEach(moduleViewEach => {
          id = _.get(moduleViewEach, attribute.formItem.pars.attributeKey) || "";
          description.push({
            id: id,
            model: attribute.model,
            description: _.get(moduleViewEach, attribute.formItem.pars.attributeValue) || ""
          })
        }) : '';
        break;

      case 'select-single-static-values':
      case 'radio-button':
        const objSelecionado = _.find(attribute.formItem.pars.options, { 'value': _.get(values, attribute.key) });
        if (!objSelecionado) {
          description = _.get(values, attribute.key);
        } else {
          description = objSelecionado.description
        }
        break;

      case 'upload-file':
        const textDescription = _.get(values, attribute.key);
        description = {
          description: textDescription,
          link: `${URL_FILE}${textDescription}`,
          isIcon: _.result(attribute, 'formItem.pars.isViewIcon')
        }
        break;

      case 'datePicker':
        const dataFormatada = formatarData(_.get(values, attribute.key));
        if (dataFormatada) {
          description = dataFormatada
        } else {
          description = _.get(values, attribute.key);
        }
        break;

      case 'input-hidden':
        break;

      case 'dynamic':
        _.map(_.result(values, attribute.key), value => {
          dynamicValues.push(handleDataDisplay(
            value,
            _.result(attribute, 'formItem.pars.attributes')
          ));
        })

        description = dynamicValues;
        break;

      case 'label':
        title = attribute?.formItem?.pars?.title;
        description =  attribute?.formItem?.pars?.description;
        break;

      case "dynamic-table":
        description = {
          attribute,
          value: {
            value: _.result(values, attribute.key)
          }
        };
        break;

      case "classificacao-pedagogica-edital":
        const classificacoes = _.result(values, attribute.key) || [];
        const fases = _.result(values, 'fases') || [];

        description = {
          attribute,
          value: classificacoes.map(c => c.co_classif_pedag),
          fases,
        }
        break;

      case "classificacao-pedagogica-especialista":
        const classificacoesPedagogicaEspecialista = _.result(values, attribute.key) || [];

        description = {
          attribute,
          value: classificacoesPedagogicaEspecialista.map(c => c.classificacao_pedagogica.co_classif_pedag),
        }
        break;

      case "classificacao-pedagogica":
        const classificacoesPedagogica = _.result(values, attribute.key) || [];

        description = {
          attribute,
          value: classificacoesPedagogica.map(c => c.co_classif_pedag),
        }
        break;

      case "container-form":
        description = {
          value: handleDataDisplay(
            values,
            _.result(attribute, 'formItem.pars.attributes'),
          ),
        }
        break;

      case 'label-local':
        const local = _.result(attribute, 'formItem.pars.local');
        const pnldFiltroBase: PnldFiltroBase = JSON.parse(localStorage.getItem('@pnld_filtro_base'));

        if (pnldFiltroBase) {
          description = pnldFiltroBase[local.model][local.descriptionKey];
        } else {
          description = '';
        }
        break;

      case "membro-especialista":
      case "membro-comissao":
        const professor = _.result(values, 'professor') || {};
        const portaria = _.result(values, 'portaria') || {};
        const st_especialista = _.result(values, 'st_especialista');
        const st_membro_comissao = _.result(values, 'st_membro_comissao');
        const no_entidade_indicante = _.result(values, 'no_entidade_indicante');
        const st_atribuicao = _.result(values, 'st_atribuicao');

        description = {
          attribute,
          value: _.result(values, attribute.key),
          data: {
            professor,
            portaria,
            no_entidade_indicante,
            st_atribuicao,
            st_especialista,
            st_membro_comissao
          },
        }
        break;

      default:
        description = _.get(values, attribute.key);
        break;
    }

    if(attribute.formItem.key != 'password'){
      valuesTratado.push({
        icon: attribute.formItem.pars.icon,
        type: attribute.formItem.key,
        title,
        description,
        gridClass: attribute?.formItem?.pars?.gridClass,
        gridWidth: attribute?.formItem?.pars?.gridWidth,
        status: false,
      });
    }
  })

  return valuesTratado;
}

export const htmlToText = html => {
  const elem = document.createElement('textarea');
  elem.innerHTML = html;
  return elem.value.replace(/<[^>]+>/g, '');
}

/**
 * Método criado para tratar a exibição dos dados recebidos do backend
 * na lista do DataGrid.
 *
 * Por exemplo, quando recebe um objeto, converter para o texto com o valor (nome do edital)
 * ou quando recebe uma flag, exibir o texto e não a chave.
 */
export const treatDataset = (dataSource, attributes) => {

  if (_.isEmpty(dataSource)) {
    return null;
  }

  // const dataSourceTratado = [...dataSource];
  const dataSourceTratado = JSON.parse(JSON.stringify(dataSource));

  _.forEach(dataSourceTratado, registro => {
    Object.keys(registro).forEach(registroKey => {
      const atributo = _.find(attributes, { 'key': registroKey });
      let objParse = null;

      if (!_.isEmpty(atributo)) {
        //Considerando que é um atributo que deve ser visível no dataGrid
        switch (atributo.formItem.key) {
          case 'select-single-search':
            if (!_.isEmpty(registro[_.snakeCase(atributo.model)])) {
              const codigo = registro[_.snakeCase(atributo.model)][atributo.formItem.pars.attributeKey];
              const descricao = registro[_.snakeCase(atributo.model)][atributo.formItem.pars.attributeValue];

              if (codigo && descricao) {
                registro[registroKey] = `${descricao}`;
              } else {
                registro[registroKey] = null;
              }
            } else {
              registro[registroKey] = null;
            }
            break;
          case 'radio-button':
            objParse = _.find(atributo.formItem.pars.options, { 'value': registro[registroKey] });
            registro[registroKey] = objParse && objParse.description ? objParse.description : "";
            break;
          case 'select-single-static-values':
            objParse = _.find(atributo.formItem.pars.options, { 'value': registro[registroKey] })
            registro[registroKey] = objParse && objParse.description ? objParse.description : "";
            break;
          case 'input-rich-text-area':
          case 'draft-input-rich-text-area':
          case 'summernote-input-rich-text-area':
            registro[registroKey] = htmlToText(registro[registroKey]);
            break;
          case 'datePicker':
            //se enviar para formatarData vazio, retornará falso
            const dataFormatada = formatarData(registro[registroKey]);
            if (dataFormatada) {
              registro[registroKey] = dataFormatada
            }
            break;
          default:
            break;
        }
      }
    });
  });
  return dataSourceTratado;
}

export const validalitorValue = {
  cpf: value => {
    if( !value || value.length != 11
      || value == "00000000000"
      || value == "11111111111"
      || value == "22222222222"
      || value == "33333333333"
      || value == "44444444444"
      || value == "55555555555"
      || value == "66666666666"
      || value == "77777777777"
      || value == "88888888888"
      || value == "99999999999" )
    return false;

    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(value.substring(i-1, i)) * (11 - i);

    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(value.substring(9, 10)) ) return false;
    soma = 0;

    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(value.substring(i-1, i)) * (12 - i);

    resto = (soma * 10) % 11;
    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(value.substring(10, 11) ) ) return false;

    return true;
  },
  cnpj: value => {
    if ( !value || value.length != 14
      || value == "00000000000000"
      || value == "11111111111111"
      || value == "22222222222222"
      || value == "33333333333333"
      || value == "44444444444444"
      || value == "55555555555555"
      || value == "66666666666666"
      || value == "77777777777777"
      || value == "88888888888888"
      || value == "99999999999999")
    return false;

    var tamanho = value.length - 2;
    var numeros = value.substring(0,tamanho);
    var digitos = value.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = value.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado != digitos.charAt(1)) return false;

    return true;
  }
}

/**
 * Referente ao template
 */
/* FormItems */
export const formExplain = {
  required: 'Campo obrigatório!',
  confirm: 'As senhas são diferentes!',
  invalidDate: 'Data inválida!',
  invalidValue: (field = null) => field ? `O ${field} informado não é válido` : 'Informado inválida',
  validatingValue: (field = null) => field ? `Verificando se ${field} já foi utilizado...` : 'Validando...',
  uniqueValue: (field = null) => field ? `Este ${field} já foi utilizado!` : 'Já foi utilizado!',
  errorServe: 'Erro ao se comunicar com o servidor',
  requiredUpload: 'É necessário anexar um arquivo!',
  errorUpload: 'Infelizmente não foi possível realizar o upload. Essa ocorrência foi registrada e será analisada. Tente novamente em instantes!',
  errorTypesUpload: fileTypes => `Somente os tipos a seguir são permitidos: ${fileTypes}`,
  sizeUpload: (size) => `Os arquivos devem ter no máximo ${size}MB!`,
  typeUpload: 'Somente arquivos JPG / PNG podem ser carregados!',
  minlength: (min) => `No mínimo ${min} caracteres.`,
  maxlength: (max) => `No máximo ${max} caracteres.`,
  reCaptchaRequired: 'Marque o checkbox acima para confirmar que você não é um robô!'
}
/* Loader - Mensagens */
export const LOADING = {"status": "loading", "type": "", "title": "Carregando...", "description": "Por favor aguarde."}
export const LOADING_SAVE = {"status": "loading", "type": "", "title": "Salvando...", "description": "Por favor aguarde."}
export const LOADING_SEND = {"status": "loading", "type": "", "title": "Enviando...", "description": "Por favor aguarde."}
export const LOADING_SUBMIT = {"status": "loading", "type": "", "title": "Submetendo...", "description": "Por favor aguarde."}
export const LOADING_REMOVE = {"status": "loading", "title": "Removendo...", "description": "Por favor aguarde."}
export const LOADING_SEARCH = {"status": "loading", "title": "Buscando...", "description": "Por favor aguarde."}
/* SUCCESS */
export const SUCCESS = {"status": "success", "type": "info", "title": "Sucesso!", "description": "Realizado com sucesso."}
/* ERROR */
export const ERROR = {"status": "error", "type": "info", "title": "Erro ao carregar!", "description": "Contate o administrador ou tente novamente mais tarde."}
export const ERROR_TRIAGEM_PROPOSTA = {"status": "error", "type": "info", "title": "Erro ao carregar triagem!", "description": "Não foi possível consultar a triagem dessa proposta."}
/* EMPTY */
export const EMPTY = {"status": "empty", "type": "info", "title": "Registro não encontrado", "description": "Tente novamente mais tarde."}
export const EMPTY_ATUALIZAR_GUIA = {"status": "empty", "type": "info", "title": "Relatório não encontrado", "description": "Tente novamente mais tarde."}
/* View */
export const ERROR_VIEW = {"status": "error", "type": "info", "title": "Erro ao visualizar os dados!", "description": "Contate o administrador ou tente novamente mais tarde."}
/* Add */
export const SUCCESS_ADD = {"status": "success", "type": "info", "title": "Cadastrado com sucesso!", "description": "Seu cadastro ocorreu com sucesso."}
export const ERROR_ADD = {"status": "error", "type": "info", "title": "Erro ao cadastrar!", "description": "Contate o administrador ou tente novamente mais tarde."}
/* Edit */
export const SUCCESS_EDIT = {"status": "success", "type": "info", "title": "Editado com sucesso!", "description": "Sua edição ocorreu com sucesso."}
export const ERROR_EDIT = {"status": "error", "type": "info", "title": "Erro ao editar!", "description": "Contate o administrador ou tente novamente mais tarde."}
/* Remove */
export const SUCCESS_REMOVE = {"status": "success", "type": "info", "title": "Removido com sucesso!", "description": "Sua remoção ocorreu com sucesso."}
export const ERROR_REMOVE = {"status": "error", "type": "info", "title": "Erro ao remover!", "description": "Contate o administrador ou tente novamente mais tarde."}

export const ERROR_SAVE = {"status": "error", "type": "info", "title": "Erro ao salvar!", "description": "Contate o administrador ou tente novamente mais tarde."}

export const SUBMIT_SUCCESS = {
  "status": "submit",
  "type": "info",
  "title": "Proposta submetida!",
  "description": "Sua proposta foi submetida com sucesso. Para submeter uma nova proposta, clicar no botão abaixo.",
  actions: [{
    key: 'add-proposta',
    name: 'Nova Proposta'
  }]
}

export const SUBMIT = {
  "status": "submit",
  "type": "info",
  "title": "Proposta já submetida!",
  "description": "Uma vez submetida, você não poderá mais alterar sua avaliação. Para submeter uma nova proposta, clicar no botão abaixo.",
  actions: [{
    key: 'add-proposta',
    name: 'Nova Proposta'
  }]
}

export const SUBMIT_ERROR = {
  "status": "error",
  "type": "info",
  "title": "Não foi possível submeter a proposta!",
  "description": "Contate o administrador ou tente novamente mais tarde.",
  actions: [{
    key: 'error_submit',
    name: 'Ok'
  }]
}

export const EMPTY_PROPOSTA = {
  "status": "empty",
  "type": "info",
  "title": "Oops!",
  "description": "Proposta não encontrada."
}

export const ERROR_PROPOSTA = {
  "status": "error",
  "type": "info",
  "title": "Erro ao carregar proposta!",
  "description": "Para atualizar, clique no botão a baixo. Contate o administrador ou tente novamente mais tarde.",
  actions: [{
    key: 'error_proposta',
    name: 'Atualizar'
  }]
}

export const PERMISSION_PROPOSTA = {
  "status": "permission",
  "type": "info",
  "title": "Não tem permissão para acessar a proposta!",
  "description": "Contate o administrador ou tente novamente mais tarde.",
}

export const  QUESTION_DELETE_PROPOSTA = {
  status: 'question',
  type: '',
  title: 'Deseja realmente descartar esta proposta?',
  description: 'Todas as suas alterações serão perdidas.',
  actions: [
    {
      key: 'close_modal',
      type: 'danger',
      name: 'Não'
    },
    {
      key: 'remove-proposta',
      type: 'primary',
      name: 'Sim'
    }
  ]
};

export const  SUCCESS_TRANSMITIDA = {
  status: 'success',
  type: '',
  title: 'Proposta transmitida!',
  description: 'Sua proposta foi transmitida e você pode acompanhá-la na aba "Transmitidas".',
  actions: [{
    key: 'close_modal',
    type: 'primary',
    name: 'Ok'
  }]
};

export const QUESTION_AVALIAR_PROPOSTA = {"status": "question", "type": "confirm", "title": "Tem certeza que irá submeter a avaliação?", "description": "Uma vez submetida, você não poderá mais alterar sua avaliação."}
export const LOADING_AVALIAR_PROPOSTA = {"status": "loading", "type": "", "title": "Submetendo avaliação...", "description": "Por favor aguarde."}

export const HOW_IT_WORKS = [
  {
    title: 'Instituições se cadastram e submetem suas tecnologias educacionais',
    image: '/assets/img/passo1.png',
  },
  {
    title: 'Avaliadores de diversas competências se cadastram na plataforma',
    image: '/assets/img/passo2.png',
  },
  {
    title: 'As submissões são automaticamente distribuídas aos avaliadores mais adequados a cada tecnologia',
    image: '/assets/img/passo3.png',
  },
  {
    title: 'Gestores e proponentes acompanham o processo de avaliação e os resultados em tempo real',
    image: '/assets/img/passo4.png',
  },
  {
    title: 'O catálogo de tecnologias selecionadas é publicado',
    image: '/assets/img/passo5.png',
  }
];

export const MIMETYPES = [
  { type: 'pdf', expanded: 'application/pdf' },
  { type: 'png', expanded: 'image/png' },
  { type: 'jpg', expanded: 'image/jpg' },
  { type: 'jpeg', expanded: 'image/jpeg' },
  { type: 'gif', expanded: 'image/gif' },
  { type: 'bmp', expanded: 'image/bmp' },
  { type: 'rtf', expanded: 'text/rtf' },
  { type: 'xls', expanded: 'application/excel' },
  { type: 'xlsx', expanded: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { type: 'doc', expanded: ['application/msword', 'application/octet-stream'] },
  { type: 'docx', expanded: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { type: 'odt', expanded: 'application/vnd.oasis.opendocument.text' },
  { type: 'html', expanded: 'text/html' },
  { type: 'xhtml', expanded: 'application/xhtml+xml' },
  { type: 'csv', expanded: 'text/plain' },
];

export const VISITA_PRESENCIAL_MESSAGE = "Os avaliadores irão analisar a real necessidade de uma visita in loco, esta opção é apenas um indicativo e não garante a visita por parte dos avaliadores, caso a visita seja considerada necessária o sistema irá notificar o proponente.";

export const INSTRUMENT_STATUS = {
  N: 'Em construção',
  F: 'Finalizado',
  A: 'Alterado',
};

export const BLOCK_STATUS = {
  CON: 'Em construção',
  RAS: 'Rascunho',
  VDC: 'Em validação',
  SUG: 'Sugerido pela Comissão Técnica',
  VAC: 'Validado pela Comissão Técnica',
  VAE: 'Validação pelo Especialista',
  FIN: 'Finalizado',
  ALE: 'Alterado pelo especialista',
};

export const BLOCO_AVALIACAO_STATUS = {
  NIN: 'Não Iniciada',
  EAV: 'Em Avaliação',
  ENT: 'Entregue',
  ERV: 'Em Revisão',
  REV: 'Revisado',
  RVA: 'Revisão Validada',
  EAJ: 'Em ajuste'
};


export const NATUREZA = [
  { natureza_bloco: 'FP', ds_natureza_bloco: 'Falhas pontuais' },
  { natureza_bloco: 'RE', ds_natureza_bloco: 'Resenha' },
  { natureza_bloco: 'PR', ds_natureza_bloco: 'Parecer' },
  { natureza_bloco: 'PI', ds_natureza_bloco: 'Panorama inicial' },
  { natureza_bloco: 'QS', ds_natureza_bloco: 'Questões' },
];

export const NATUREZA_BLOCO_PARECER = "PR";

export const EXEMPLAR = [
  { tp_exemplar: 'LE', ds_tp_exemplar: 'Livro do estudante' },
  { tp_exemplar: 'MP', ds_tp_exemplar: 'Manual do professor' },
  { tp_exemplar: 'LA', ds_tp_exemplar: 'Livros de Práticas e Acompanhamento da Aprendizagem' },
  { tp_exemplar: 'LG', ds_tp_exemplar: 'Livro do Gestor' },
  { tp_exemplar: 'LP', ds_tp_exemplar: 'Livro do Professor' },
  { tp_exemplar: 'LF', ds_tp_exemplar: 'Livro de Formação (Continuada)' },
  { tp_exemplar: 'MA', ds_tp_exemplar: 'Manual de Práticas e Acompanhamento da Aprendizagem' },
];

export const FORMATO = [
  { tp_formato: 'IM', ds_tp_formato: 'Impresso' },
  { tp_formato: 'PD', ds_tp_formato: 'PDF' },
  { tp_formato: 'VA', ds_tp_formato: 'Vídeoaula' },
  { tp_formato: 'BR', ds_tp_formato: 'Brailler' },
  { tp_formato: 'VT', ds_tp_formato: 'Videotutorial' },
  { tp_formato: 'AV', ds_tp_formato: 'Audiovisual' },
  { tp_formato: 'ME', ds_tp_formato: 'Mecdaisy' },
];

export const TIPO_RESPOSTA = [
  { tp_resposta: 'P', ds_tp_resposta: 'Pontual' },
  { tp_resposta: 'D', ds_tp_resposta: 'Descritiva' },
];

export const LIMITE_CARACTERES = [
  { limite_caracter: '500',  ds_limite: '500 caracteres' },
  { limite_caracter: '2000',  ds_limite: '2.000 caracteres' },
  { limite_caracter: '4000',  ds_limite: '4.000 caracteres' },
  { limite_caracter: '10000',  ds_limite: '10.000 caracteres' },
  { limite_caracter: '15000',  ds_limite: '15.000 caracteres'},
  { limite_caracter: '20000',  ds_limite: '20.000 caracteres' },
  { limite_caracter: '40000',  ds_limite: '40.000 caracteres' },
];

export const SITUACAO_OBRA = [
    {codigo: "VAL", descricao: "Validada e Cadastrada" },
    {codigo: "PRE", descricao: "Pré-analise" },
    {codigo: "PRR", descricao: "Pré-análise reprovada" },
    {codigo: "PRA", descricao: "Pré-análise Aprovada" },
    {codigo: "EAP", descricao: "Em avaliação pedagógica" },
    {codigo: "AVA", descricao: "Avaliada" },
    {codigo: "RPD", descricao: "Resultado prévio disponibilizado" },
    {codigo: "RPD", descricao: "Resultado prévio disponibilizado" },
    {codigo: "RCA", descricao: "Recurso apresentado" },
    {codigo: "RRD", descricao: "Resultado de recurso disponibilizado" },
    {codigo: "FAC", descricao: "Falhas corrigidas" },
    {codigo: "ADR", descricao: "Aguardando Disponibilização do Resultado Final" },
    {codigo: "RFD", descricao: "Resultado Final Disponibilizado" },
]

export const SITUACAO_ALTERACAO_OBRA = [
  { codigo: "AAN", descricao: 'Aguardando Análise' },
  { codigo: "AAC", descricao: 'Alteração Aceita' },
  { codigo: "ALR", descricao: 'Alteração Recusada' }
];

//Situação da Ficha Modelo :
/**"EAV" - Em Avaliação,
 * "ENT"- Entregue,
 * "ASS"- Assinada, "PUB" - Publicada,
 * "NIN" - Não Iniciada
 **/

 export const SITUACAO_FORMULARIO_AVALIACAO = [
  { codigo: "EAV", descricao: 'Em Avaliação' },
  { codigo: "ENT", descricao: 'Entregue' },
  { codigo: "ASS", descricao: 'Assinada, "PUB" - Publicada' },
  { codigo: "NIN", descricao: 'Não Iniciada' },
  { codigo: "PUB", descricao: 'Públicada' },
 ];

 export const LABEL_PERFIS = {
  gestor_mec: 'Gestor MEC',
  avaliador: 'Avaliador',
  coordenador_pedagogico: 'Coordenador Pedagógico',
  coordenador_adjunto: 'Coordenador Adjunto',
  leitor_tecnico: 'Leitor Técnico',
  assessor_pedagogico: 'Acessor Pedagógico',
  membro_comissao: 'Membro da Comissão Técnica',
  especialista: 'Especialista',
  recursista: 'Recursista'
 }

 /**
  * Constante com o ID definido no banco de dados
  * para a instituição de ensino "outra".
  */
 export const COD_IES_OUTRA = 9999;

 interface SituacaVerificacaoObra {
   codigo: string,
   descricao: string
 }

 export const SITUACOES_VERIFICACAO_OBRA: SituacaVerificacaoObra[] = [
  {
    codigo: "AGI",
    descricao: "Aguardando verificação"
  },
  {
    codigo: "VRF",
    descricao: "Em verificação"
  },
  {
    codigo: "APV",
    descricao: "Aprovado"
  },
  {
    codigo: "RPV",
    descricao: "Reprovado"
  }
 ];

 export const DEBOUNCE_TIME_DELAY = 1000;

 export const HTTP_STATUS_CODE = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  EARLY_HINTS: 103,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_EARLY: 425,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  BANDWIDTH_LIMIT_EXCEEDED: 509,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511
}
