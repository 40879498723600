<nz-form-item>
  <nz-form-label *ngIf="attribute?.description">{{ attribute?.description }}</nz-form-label>
  <nz-form-control>
    <ng-container [ngSwitch]="attribute?.formItem?.pars?.type">

      <!-- Alert -->
      <nz-alert *ngSwitchCase="'alert'" [nzType]="attribute?.formItem?.pars?.alert?.type"
        [nzMessage]="attribute?.formItem?.pars?.alert?.message"
        [nzShowIcon]="attribute?.formItem?.pars?.alert?.showIcon"
        [nzDescription]="attribute?.formItem?.pars?.alert?.description"></nz-alert>

      <!-- List meta -->
      <div *ngSwitchCase="'list-meta'" class="list-meta">
        <div class="meta" *ngFor="let item of attribute?.formItem?.pars?.listMeta">
          <div class="title">{{ item?.title }}</div>
          <div *ngIf="!item?.link" class="description">{{ item?.description }}</div>
          <a *ngIf="item?.link" [href]="item?.description" target="_blank">{{ item?.description }}</a>
        </div>
      </div>

      <div *ngSwitchCase="'header'" class="header">
        <strong>{{ attribute?.formItem?.pars?.title }}</strong>

        <p *ngIf="attribute?.formItem?.pars?.description">
          {{ attribute?.formItem?.pars?.description }}
        </p>
      </div>

      <div *ngSwitchCase="'banner'" class="header">
        <banner-curso-pnld-step></banner-curso-pnld-step>
      </div>

    </ng-container>
  </nz-form-control>
</nz-form-item>