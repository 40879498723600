<strong>{{ attribute?.formItem?.pars?.header?.title }}</strong>

<div class="guia-row" *ngIf="attributes">
  <div
    *ngFor="let att of attributes"
    [ngClass]="!att?.formItem?.pars?.gridClass ? 'col-12' : att?.formItem?.pars?.gridClass"
    [ngStyle]="att?.formItem?.pars?.gridWidth ? {'max-width': att?.formItem?.pars?.gridWidth} : {}"
  >
    <div
      [ngStyle]="att?.formItem?.pars?.controlWidth ? {'max-width': att?.formItem?.pars?.controlWidth} : {}"
    >
      <app-form-items
        [attribute]="att"
        [initial]="initial"
        [form]="form.get(attribute.key)"
        [isLabelOptional]="isLabelOptional"
        (callback)="callback.emit($event)"
      ></app-form-items>
    </div>
  </div>
</div>
