import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { ApiService } from '../../../../shared/services/api.service';
import { Professor, ProfessorData } from '../../../../shared/models/professor.model';
import { URL_API_SEARCH } from '../../../../shared/utils/const';
import { ATTRIBUTE_ATRIBUICAO, ATTRIBUTE_ENTIDADE_INDICANTE, ATTRIBUTE_PORTARIA, ATTRIBUTE_SITUACAO } from './setup';
import { PnldFiltroBase } from '../../../../shared/models/pnled-filtros-base.model';
import { Portaria } from '../../../../shared/models/portaria.model';

@Component({
  selector: 'app-membro-comissao',
  templateUrl: './membro-comissao.component.html',
  styleUrls: ['./membro-comissao.component.less']
})
export class MembroComissaoComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() attribute: any;
  @Input() initial: any;
  @Input() isView = false;

  searchChange$ = new BehaviorSubject('');
  professores: Professor[] = [];
  professor: Professor;
  portaria: Portaria;
  noEntidadeIndicante: string;
  stAtribuicao: 'TIT' | 'SUP';
  isLoading = false;
  st_membro_comissao: 'ATI' | 'INA';

  attributePortaria = ATTRIBUTE_PORTARIA;
  attributeAtribuicao = ATTRIBUTE_ATRIBUICAO;
  attributeEntidadeIndicante = ATTRIBUTE_ENTIDADE_INDICANTE;
  attributeSituacao = ATTRIBUTE_SITUACAO;

  pnldFiltroBase: PnldFiltroBase;

  constructor(
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.pnldFiltroBase = JSON.parse(
      localStorage.getItem('@pnld_filtro_base') || '{}'
    );
    
    if (this.initial?.data) {
      this.professor = this.initial.data?.professor;
      this.professores = [this.initial.data?.professor];
      this.portaria = this.initial.data?.portaria;
      this.noEntidadeIndicante = this.initial.data?.no_entidade_indicante;
      this.stAtribuicao = this.initial.data?.st_atribuicao;
      this.st_membro_comissao = this.initial.data?.st_membro_comissao;
    }

    if (!this.isView) {
      this.searchChange$.asObservable().pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(value => value?.length >= 3),
        switchMap(value => {
          if (value) {
            this.isLoading = true;
  
            const regex = /[0-9]/;
            let filters: Array<{ model: string; filter: string[] }> = [];
  
            if (regex.test(value)) {
              filters.push({
                model: '',
                filter: ['co_cpf', 'like', `%${value}%`]
              });
            } else {
              filters.push({
                model: '',
                filter: ['no_professor', 'like', `%${value}%`]
              });
            }
      
            return this.api.post<ProfessorData>(`professor${URL_API_SEARCH}`, { 
              filters 
            });
          } else {
            return from([])
          }
        })
      ).subscribe(
        ({ data }) => {
          this.professores = data;
          this.isLoading = false;
        }
      );
  
      this.form.get(this.attribute.key).valueChanges.subscribe((co_professor: number) => {
        if (co_professor) {
          this.professor = this.professores.find(
            professor => professor.co_professor == co_professor
          );
        } else {
          this.professor = undefined;
          this.form.get(this.attribute.key).setValue(undefined);
        }
      });
    }
  }

  onSearch(value: string): void {
    this.searchChange$.next(value);
  }
}
