import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/pages/user/shared/services/auth.service';

import { User } from '../models/user.model';
import { PermissionsService } from '../services/permissions.service';
import { DS_CHAVE_PROFESSOR } from '../utils/const';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private ps: PermissionsService,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = JSON.parse(localStorage.getItem('user')) as User;

    if (user) {
      return true;
    }

    let url: string = state.url;
    this.auth.urlRedirecionamento = url;
    
    this.ps.handleLogin();
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // Se tiver o parâmetro (moduleyKey), verifica se possue permissão.
    const moduleKey = next.params.moduleKey || next.data.moduleKey;

    if (moduleKey){
      // Carregando as permissões, caso não exista, e verificar se possue permissão para a rota.
      const isPermission = this.ps.isPermissions(moduleKey);

      // Não tem permissão, então redirecione para a página inicial.
      isPermission.then(values => {
        if(!values){
          this.router.navigate(['/admin']);
        }
      });

      // Retorna uma promise, true se possuir permissão e false não possue.
      return isPermission;
    } else {
      // Carregando as permissões caso não exista
      this.ps.isPermissions();
      // Retorna true, caso não tenha o parâmetro (moduleKey).
      return true;
    }
  }
}
