<ng-container *ngIf="form != null" [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control>

      <div class="items">
        <ng-container *ngFor="let item of form.get(arrayName).controls; let i = index;">
          <div *ngIf="attribute?.formItem?.pars?.header" class="header">
            <strong>{{ attribute?.formItem?.pars?.header?.title }} {{i === 0 ? '' : i + 1}}</strong>

            <button
              *ngIf="i > 0"
              nz-button
              nzType="danger-basic"
              nzShape="round"
              [ngStyle]="{'margin-left': '15px'}"
              (click)="removeItem(i)"
            >
              Remover
            </button>
          </div>

          <div class="item">
            <div class="fields">
              <div
                *ngFor="let attribute of attributes"
                [ngClass]="!attribute?.formItem?.pars?.gridClass ? 'col-12' : attribute?.formItem?.pars?.gridClass"
                [ngStyle]="attribute?.formItem?.pars?.gridWidth ? {'max-width': attribute?.formItem?.pars?.gridWidth} : {}"
              >
                <div
                  [ngStyle]="attribute?.formItem?.pars?.controlWidth ? {'max-width': attribute?.formItem?.pars?.controlWidth} : {}"
                >
                  <app-form-items
                    [attribute]="attribute"
                    [initial]="initial?.value[i]"
                    [form]="item"
                    [isLabelOptional]="isLabelOptional"
                    (callback)="callback.emit($event)"
                  ></app-form-items>
                </div>
              </div>
            </div>

            <button
              *ngIf="!attribute?.formItem?.pars?.header && form.get(arrayName).controls?.length > 1"
              nz-button
              nzShape="round"
              nzType="danger-basic"
              (click)="removeItem(i)"
            >
              Remover
            </button>
          </div>
        </ng-container>
      </div>
      <br />
      <button 
        *ngIf="!attribute?.formItem?.pars?.isNoButtonAdd" 
        nz-button 
        nzShape="round"
        (click)="addItem()"
      >
        {{attribute?.formItem?.pars?.labelButtonAdd || 'Adicionar'}} 
      </button>
    </nz-form-control>
  </nz-form-item>
</ng-container>
