import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NgxMaskModule } from 'ngx-mask';

import { NgZorroAntdModule } from '../ng-zorro-antd.module';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { FormLayoutModule } from './form/form-layout.module';

import { BaseComponent } from './components/base/base.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { HeaderActionComponent } from './components/header-action/header-action.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { SortDataSourceByComponent } from './components/sort-datasource-by/sort-datasource-by.component';
import { StepsComponent } from './components/steps/steps.component';

import { StepViewComponent } from './components/steps/step-view/step-view.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { BaseContentComponent } from './components/base-content/base-content.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ModalMessageComponent } from './components/modal-message/modal-message.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LayoutProponenteComponent } from './components/layout-proponente/layout-proponente.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AlertComponent } from './components/alert/alert.component';
import { HeaderBreadcrumbComponent } from './components/header-breadcrumb/header-breadcrumb.component';
import { LayoutHeaderComponent } from './components/layout-header/layout-header.component';
import { LayoutFooterComponent } from './components/layout-footer/layout-footer.component';
import { ModalActiveInactiveComponent } from './components/modal-active-inactive/modal-active-inactive.component';
import { SelectSimpleComponent } from './components/select-simple/select-simple.component';
import { PainelAcoesComponent } from './components/painel-acoes/painel-acoes.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { CardComponent } from './components/card/card.component';
import { MovingCardComponent } from './components/moving-card/moving-card.component';
import { AddItemInstrumentEvaluationComponent } from './components/add-item-instrument-evaluation/add-item-instrument-evaluation.component';
import { BlockTreeComponent } from './components/block-tree/block-tree.component';
import { BlockRegisterComponent } from '../pages/admin/pages/evaluation-instrument/items-evaluation/block-register/block-register.component';
import { SubblockRegisterComponent } from '../pages/admin/pages/evaluation-instrument/items-evaluation/subblock-register/subblock-register.component';
import { GroupRegisterComponent } from '../pages/admin/pages/evaluation-instrument/items-evaluation/group-register/group-register.component';
import { QuestionRegisterComponent } from '../pages/admin/pages/evaluation-instrument/items-evaluation/question-register/question-register.component';
import { DescricaoSituacaoObraPipe } from './pipes/descricao-situacao-obra.pipe';
import { ChatConversationsComponent } from './components/chat-conversations/chat-conversations.component';
import { OrderByPipe } from './pipes/orderby.pipe';
import { ChatMessagesComponent } from './components/chat-conversations/chat-messages/chat-messages.component';
import { SearchConversationsPipePipe } from './pipes/search-conversations-pipe.pipe';
import { FormatDataPipe } from './pipes/format-data.pipe';
import { AccordionModule } from './components/accordion/accordion.module';
import { AddBlockComponent } from '../pages/admin/pages/evaluation-instrument/items-evaluation/add-block/add-block.component';
import { AddSubblockComponent } from '../pages/admin/pages/evaluation-instrument/items-evaluation/add-subblock/add-subblock.component';
import { AddGroupComponent } from '../pages/admin/pages/evaluation-instrument/items-evaluation/add-group/add-group.component';
import { AddQuestionComponent } from '../pages/admin/pages/evaluation-instrument/items-evaluation/add-question/add-question.component';
import { InfoAlertCreatedUpdatedComponent } from './components/info-alert-created-updated/info-alert-created-updated.component';
import { LandingHomeComponent } from './components/landing-home/landing-home.component';
import { LoginGovbrComponent } from './components/login-govbr/login-govbr.component';
import { DescricaoStatusObraInscritaPipe } from './pipes/descricao-status-obra-inscrita.pipe';
import { BannerCursoPnldHomeComponent } from './components/banner-curso-pnld-home/banner-curso-pnld-home.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

@NgModule({
  declarations: [
    BaseComponent,
    DataGridComponent,
    HeaderActionComponent,
    LoaderComponent,
    PdfViewerComponent,
    SortDataSourceByComponent,
    StepsComponent,
    StepViewComponent,
    CustomIconComponent,
    BaseContentComponent,
    AvatarComponent,
    SafeHtmlPipe,
    DescricaoSituacaoObraPipe,
    ModalMessageComponent,
    LayoutComponent,
    LayoutProponenteComponent,
    BreadcrumbComponent,
    AlertComponent,
    HeaderBreadcrumbComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    ModalActiveInactiveComponent,
    CardComponent,
    MovingCardComponent,
    AddItemInstrumentEvaluationComponent,
    BlockTreeComponent,
    SelectSimpleComponent,
    PainelAcoesComponent,
    PaginationComponent,
    CustomModalComponent,
    BlockRegisterComponent,
    SubblockRegisterComponent,
    GroupRegisterComponent,
    QuestionRegisterComponent,
    ChatConversationsComponent,
    OrderByPipe,
    ChatMessagesComponent,
    SearchConversationsPipePipe,
    FormatDataPipe,
    AddBlockComponent,
    AddSubblockComponent,
    AddGroupComponent,
    AddQuestionComponent,
    InfoAlertCreatedUpdatedComponent,
    LandingHomeComponent,
    LoginGovbrComponent,
    DescricaoStatusObraInscritaPipe,
    BannerCursoPnldHomeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    FormLayoutModule,
    PdfJsViewerModule,
    RouterModule,
    PerfectScrollbarModule,
    AccordionModule,
    NgxMaskModule.forChild()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    FormLayoutModule,
    SafeHtmlPipe,
    DescricaoSituacaoObraPipe,
    BaseComponent,
    DataGridComponent,
    HeaderActionComponent,
    LoaderComponent,
    PdfViewerComponent,
    SortDataSourceByComponent,
    StepsComponent,
    BaseContentComponent,
    AvatarComponent,
    ModalMessageComponent,
    LayoutComponent,
    LayoutProponenteComponent,
    BreadcrumbComponent,
    AlertComponent,
    HeaderBreadcrumbComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    NgxMaskModule,
    ModalActiveInactiveComponent,
    CardComponent,
    MovingCardComponent,
    AddItemInstrumentEvaluationComponent,
    BlockTreeComponent,
    SelectSimpleComponent,
    PainelAcoesComponent,
    PaginationComponent,
    CustomModalComponent,
    BlockRegisterComponent,
    SubblockRegisterComponent,
    GroupRegisterComponent,
    QuestionRegisterComponent,
    ChatConversationsComponent,
    OrderByPipe,
    ChatMessagesComponent,
    SearchConversationsPipePipe,
    FormatDataPipe,
    AccordionModule,
    AddBlockComponent,
    InfoAlertCreatedUpdatedComponent,
    LoginGovbrComponent,
    DescricaoStatusObraInscritaPipe,
    BannerCursoPnldHomeComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
})
export class SharedModule { }
