<ng-container [formGroup]="form">
  <nz-form-item *ngIf="template">
    <nz-form-label>{{template.label}}</nz-form-label>

    <nz-form-control *ngIf="template?.endpoint; else linkTpt">
      <button nz-button nzType="link" (click)="callback.emit({ endpoint: template.endpoint })">
        <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
        {{template.title}}
      </button>
    </nz-form-control>

    <ng-template #linkTpt>
      <nz-form-control>
        <a [href]="template.link" target="_blank" rel="noopener noreferrer">
          <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
          {{template.title}}
        </a>
      </nz-form-control>
    </ng-template>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label
      *ngIf="attribute?.description"
      [nzRequired]="attribute?.isRequired"
      [nzFor]="attribute?.key"
    >
      {{ attribute?.description }}
    </nz-form-label>

    <nz-form-control [nzErrorTip]="errorTpl" [nzExtra]="attribute?.formItem?.pars?.extra">
      <input type="hidden" [formControlName]="attribute?.key">

      <!-- <div class="upload">

        <ng-container *ngIf="fileList?.length > 0">
          <div
            class="file"
            [ngClass]="[
              item?.status, limit == 1 ? 'default' : 'multiple',
              fileList?.length > 1 && last ? 'last' : '',
              uploadControl?.disabled ? 'disabled' : ''
            ]"
            *ngFor="let item of fileList; let last = last"
          >
            <div class="file-info">
              <div class="file-name" *ngIf="item?.status != 'done'">
                <span>
                  <i nz-icon [nzType]="item?.status == 'uploading' ? 'loading' : 'paper-clip'" nzTheme="outline"></i>
                  {{ item?.name }}
                </span>
              </div>
              <a *ngIf="item?.status == 'done'" class="file-name" target="_blank" [href]="item?.url" [title]="item?.name">
                <span>
                  <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
                  {{ item?.name }}
                </span>
              </a>
            </div>
            <i *ngIf="!uploadControl?.disabled" class="remove" nz-icon nzType="close" nzTheme="outline" title="Remover" (click)="handleRemove(item)"></i>
            <div
              *ngIf="item?.status == 'uploading'"
              class="progress"
              [ngClass]="{'complete': item?.percent > 90}"
              [ngStyle]="{'width': item?.percent + '%'}"
            ></div>
          </div>
        </ng-container>

        <nz-upload
          [hidden]="!(fileList?.length < limit)"
          nzType="drag"
          [nzAction]="action"
          [nzHeaders]="header"
          [nzFileList]="fileList"
          [nzShowUploadList]="false"
          [nzDisabled]="uploadControl.disabled"
          [nzFilter]="filters"
          [nzLimit]="limit"
          (nzChange)="handleChange($event)"
        >
          <div class="text-upload" [ngClass]="{'disabled': uploadControl.disabled}">
            <span>
              <i nz-icon nzType="upload" nzTheme="outline"></i>
              {{ attribute?.formItem?.description || 'Clique ou arraste o arquivo para esta área para anexar' }}
            </span>
          </div>
        </nz-upload>
      </div> -->

      <nz-upload
        [nzAction]="action"
        [nzHeaders]="header"
        [nzFileList]="fileList"
        [nzDisabled]="uploadControl.disabled"
        [nzFilter]="filters"
        [nzLimit]="limit"
        [nzRemove]="handleRemove"
        (nzChange)="handleChange($event)"
      >
        <div class="upload-btn">
          <button nz-button nzType="primary" nzShape="round" [disabled]="!(fileList?.length < limit)">
            <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
            Anexar documento
          </button>

          <span>Tamanho máximo do total dos arquivos: 10MB</span>
        </div>
      </nz-upload>

      <ng-template #errorTpl let-control>
        <app-form-explain [control]="control" [attribute]="attribute" [sizeUpload]="10"></app-form-explain>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
</ng-container>
