import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/guards/auth.guard';
import { LandingHomeComponent } from './shared/components/landing-home/landing-home.component';
import { LoginGovbrComponent } from './shared/components/login-govbr/login-govbr.component';

const appRouters: Routes = [
  // {
  //   path: 'professor',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./pages/professor/professor.module').then(m => m.ProfessorModule),
  // },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'govbr',
    component: LoginGovbrComponent,
  },
  {
    path: 'landing',
    component: LandingHomeComponent,
  },
  {
    path: '',
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)
  },


  // otherwise redirect to home
  //{ path: '**', redirectTo: '' }
]

@NgModule({
  imports: [
    RouterModule.forRoot(appRouters, { relativeLinkResolution: 'legacy' })
  ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {}
