import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthGovbrService, URLGovRequest } from 'src/app/pages/user/shared/services/auth-govbr.service';
import { AuthService } from 'src/app/pages/user/shared/services/auth.service';

@Component({
  selector: 'app-login-govbr',
  templateUrl: './login-govbr.component.html',
  styleUrls: ['./login-govbr.component.less']
})
export class LoginGovbrComponent implements OnInit {

  code: string
  state: string

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private govAuthService: AuthGovbrService,
    private ns: NzNotificationService
  ) { }

  ngOnInit() {
    this._verifyLoginGovBR();
  }

  private _verifyLoginGovBR(): void {
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      this.state = params['state'];

      if (this.code && this.state) {
        let requestLoginGovData = new URLGovRequest(this.code, this.state);
        this.govAuthService.login(requestLoginGovData).subscribe(
          response => {
            this.auth.realizeLogin(response.data);
          },
          error => {
            this.ns.error("Atenção", error.message);
            this.router.navigate(['/login']);
          }
        )
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

}
