import { NgModule }      from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from '../../ng-zorro-antd.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import { FormItemsComponent } from './form-items/form-items.component';
import { InputTextComponent } from './form-items/input-text/input-text.component';
import { InputUrlComponent } from './form-items/input-url/input-url.component';
import { InputTextAreaComponent } from './form-items/input-text-area/input-text-area.component';
import { SelectSearchComponent } from './form-items/select-search/select-search.component';
import { DataPickerComponent } from './form-items/data-picker/data-picker.component';
import { UploadFileComponent } from './form-items/upload-file/upload-file.component';
import { CheckboxComponent } from './form-items/checkbox/checkbox.component';
import { InputMaskComponent } from './form-items/input-mask/input-mask.component';
import { InputNumberComponent } from './form-items/input-number/input-number.component';
import { InputPasswordComponent } from './form-items/input-password/input-password.component';
import { RadioComponent } from './form-items/radio/radio.component';
import { FormLayoutComponent } from './form-layout.component';
import { FormLayoutControlService } from './form-layout-control.service';
import { SummernoteRichTextAreaComponent } from './form-items/summernote-rich-text-area/summernote-rich-text-area.component';
import { NgxSummernoteModule } from './form-items/summernote-rich-text-area/summernote/ngx-summernote.module';
import { DynamicFormControlComponent } from './form-items/dynamic-form-control/dynamic-form-control.component';
import { InputCepComponent } from './form-items/input-cep/input-cep.component';
import { ReCaptchaControlComponent } from './form-items/re-captcha-control/re-captcha-control.component';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { RecaptchaModule } from 'ng-recaptcha';
import { InputEmailComponent } from './form-items/input-email/input-email.component';
import { FormExplainComponent } from './components/form-explain/form-explain.component';
import { SelectDoubleComponent } from './form-items/select-double/select-double.component';
import { SelectCardComponent } from './form-items/select-card/select-card.component';
import { FormItemCustomComponent } from './form-items/form-item-custom/form-item-custom.component';
import { TreeSelectSingleComponent } from './form-items/tree-select-single/tree-select-single.component';
import { UploadFile2Component } from './form-items/upload-file-2/upload-file-2.component';
import { DynamicFormTableComponent } from './form-items/dynamic-form-table/dynamic-form-table.component';
import { LabelComponent } from './form-items/label/label.component';
import { FasesComponent } from './form-items/classificacao-pedagogica-edital/fases/fases.component';
import { ClassificacaoPedagogicaEditalComponent } from './form-items/classificacao-pedagogica-edital/classificacao-pedagogica-edital.component';
import { ClassificacaoPedagogicaEspecialistaComponent } from './form-items/classificacao-pedagogica-especialista/classificacao-pedagogica-especialista.component';
import { LabelLocalComponent } from './form-items/label-local/label-local.component';
import { ModalCreateFormComponent } from './form-items/select-search/modal-create-form/modal-create-form.component';
import { GroupComponent } from './form-items/group/group.component';
import { ClassificacaoPedagogicaComponent } from './form-items/classificacao-pedagogica/classificacao-pedagogica.component';
import { MembroEspecialistaComponent } from './form-items/membro-especialista/membro-especialista.component';
import { ContainerFormComponent } from './form-items/container-form/container-form.component';
import { FilterModelComponent } from './form-items/filter-model/filter-model.component';
import { ClearButtonComponent } from './form-items/clear-button/clear-button.component';
import { MembroComissaoComponent } from './form-items/membro-comissao/membro-comissao.component';
import { ClassificacaoPedagogicaSelectsComponent } from './form-items/classificacao-pedagogica-selects/classificacao-pedagogica-selects.component';
import { BannerCursoPnldStepComponent } from './form-items/banner-curso-pnld-step/banner-curso-pnld-step.component';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSummernoteModule,
    NgxMaskModule.forChild(),
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [ FormLayoutControlService ],
  declarations: [
    FormLayoutComponent,
    FormItemsComponent,
    InputTextComponent,
    InputUrlComponent,
    InputTextAreaComponent,
    SelectSearchComponent,
    DataPickerComponent,
    UploadFileComponent,
    CheckboxComponent,
    InputMaskComponent,
    InputNumberComponent,
    InputPasswordComponent,
    RadioComponent,
    SummernoteRichTextAreaComponent,
    DynamicFormControlComponent,
    InputCepComponent,
    ReCaptchaControlComponent,
    InputEmailComponent,
    FormExplainComponent,
    SelectDoubleComponent,
    SelectCardComponent,
    FormItemCustomComponent,
    TreeSelectSingleComponent,
    UploadFile2Component,
    DynamicFormTableComponent,
    LabelComponent,
    FasesComponent,
    ClassificacaoPedagogicaEditalComponent,
    ClassificacaoPedagogicaEspecialistaComponent,
    LabelLocalComponent,
    ModalCreateFormComponent,
    GroupComponent,
    ClassificacaoPedagogicaComponent,
    MembroEspecialistaComponent,
    MembroComissaoComponent,
    ContainerFormComponent,
    FilterModelComponent,
    ClearButtonComponent,
    ClassificacaoPedagogicaSelectsComponent,
    BannerCursoPnldStepComponent
  ],
  exports: [
    FormLayoutComponent,
    FormItemsComponent,
    FormExplainComponent ,
    ClassificacaoPedagogicaEditalComponent,
    ClassificacaoPedagogicaEspecialistaComponent,
    ClassificacaoPedagogicaComponent,
    DynamicFormTableComponent,
    MembroEspecialistaComponent,
    MembroComissaoComponent,
    BannerCursoPnldStepComponent
  ]
})
export class FormLayoutModule {  }
