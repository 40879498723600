import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as _ from 'lodash';

import { ApiService } from '../../../services/api.service';

import { PnldFiltroBase } from '../../../models/pnled-filtros-base.model';
import { ClassificacaoPedagogica } from '../../../models/classificacao-pedagogica';
import { Categoria } from '../../../models/categoria.model';
import { AreaConhecimento } from '../../../models/area-conhecimento';
import { Componente } from '../../../models/componente.model';
import { Edital } from '../../../models/edital.model';

@Component({
  selector: 'app-classificacao-pedagogica-especialista',
  templateUrl: './classificacao-pedagogica-especialista.component.html',
  styleUrls: ['./classificacao-pedagogica-especialista.component.less']
})
export class ClassificacaoPedagogicaEspecialistaComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() attribute: any;
  @Input() initial: any;
  @Input() isView = false;

  validateForm: FormGroup;
  control: AbstractControl;

  controlCategoria: FormControl;
  controlAreaConhecimento: FormControl;
  controlComponente: FormControl;

  classificacaoPedagogicaEspecialistas: ClassificacaoPedagogica[];
  categorias: Categoria[];
  area_conhecimentos: AreaConhecimento[];
  componentes: Componente[];

  classificacoesSelecionadas: ClassificacaoPedagogica[] = [];
  
  pnldFiltroBase: PnldFiltroBase = JSON.parse(localStorage.getItem('@pnld_filtro_base') || '{}');
  lblClassificacaoPedagogicaEspecialista: any;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private modal: NzModalService
  ) { }

  ngOnInit(): void {
    if (!this.isView) {
      this.control = this.form.get(this.attribute.key);
      this.controlCategoria = new FormControl({ value: null });
      this.controlAreaConhecimento = new FormControl({ value: null, disabled: true });
      this.controlComponente = new FormControl({ value: null, disabled: true });

      this.validateForm = this.fb.group({
        co_categoria: this.controlCategoria,
        co_area_conhecimento: this.controlAreaConhecimento,
        co_componente: this.controlComponente,
      });
      
      this.controlCategoria.valueChanges.subscribe((co_categoria: number) => {
        if (co_categoria) {
          this.controlAreaConhecimento.enable();
        } else {
          this.validateForm.patchValue({
            co_area_conhecimento: null,
            co_componente: null,
          });
  
          this.controlAreaConhecimento.disable();
          this.controlComponente.disable();
        }
  
        this.area_conhecimentos = this.filter('area_conhecimento', {
          co_categoria,
        });
      });
  
      this.controlAreaConhecimento.valueChanges.subscribe((co_area_conhecimento: number) => {
        if (co_area_conhecimento) {
          this.controlComponente.enable();
        } else {
          this.validateForm.patchValue({
            co_componente: null,
          });
  
          this.controlComponente.disable();
        }
  
        this.componentes = this.filter('componente', {
          co_categoria: this.controlCategoria.value,
          co_area_conhecimento,
        });
      });
    }
    
    this.api.get<Edital>(`edital/${this.pnldFiltroBase.edital.value}`).subscribe(
      edital => {
        this.classificacaoPedagogicaEspecialistas = edital.classificacao_pedagogicas.filter(
          ({ co_objeto, co_etapa_ensino }) => 
            co_objeto === this.pnldFiltroBase.objeto.value 
            && co_etapa_ensino === this.pnldFiltroBase.etapaEnsino.value
        );
          
        this.categorias = this.classificacaoPedagogicaEspecialistas.map(classificacao => ({
          ...classificacao.categoria,
        }));
        this.categorias = _.uniqBy(this.categorias, 'co_categoria');

        const initial = this.initial?.value as number[];


        if (initial?.length > 0) {
          this.classificacoesSelecionadas = this.classificacaoPedagogicaEspecialistas.filter(classificacao => {
            return initial.indexOf(classificacao.co_classif_pedag) > -1;
          });
        }
      }, err => {
        console.log('Error: ', err);
      }
    );

    this.lblClassificacaoPedagogicaEspecialista = {
      "model": "",
      "key": "",
      "isEditable": true,
      "isHiddenOnEdit": false,
      "order": 1,
      "formItem": {
        "key": "label",
        "pars": {
          "title": "Classificação Pedagógica",
          "description": ""
        },
        "filters": []
      }
    }
  }

  filter(key: string, filter: any) {
    const classificacoes = _.filter(this.classificacaoPedagogicaEspecialistas, filter);
    const items = [];
    
    _.forEach(classificacoes, classificacao => {
      if (!classificacao[key]) {
        return;
      }

      items.push(classificacao[key]);
    });

    return _.uniqWith(items, _.isEqual);
  }
  
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      const { 
        co_categoria, 
        co_area_conhecimento, 
        co_componente,
      } = this.validateForm.getRawValue();

      const classificacaoPedagogicaEspecialista = _.find(this.classificacaoPedagogicaEspecialistas, { 
        co_categoria, 
        co_area_conhecimento, 
        co_componente
      });

      if (classificacaoPedagogicaEspecialista) {
        const value = this.control.value || [];

        if (value.indexOf(classificacaoPedagogicaEspecialista.co_classif_pedag) === -1) {
          value.push(classificacaoPedagogicaEspecialista.co_classif_pedag);
          this.control.setValue(value);
          
          this.classificacoesSelecionadas = [
            ...this.classificacoesSelecionadas, 
            classificacaoPedagogicaEspecialista
          ];
        }

        this.validateForm.reset();

        this.controlAreaConhecimento.disable();
        this.controlComponente.disable();
        
        this.area_conhecimentos = [];
        this.componentes = [];
      } else {
        this.modal.info({
          nzTitle: 'Classificação pedagógica não encontrada',
          nzContent: 'A combinação dos campos selecionados não correspondem a uma classificação pedagógica.',
        });
      }
    }
  }

  removeItem(id: number): void {
    const value = this.control.value.filter((v: number) => v !== id);
    this.control.setValue(value);

    this.classificacoesSelecionadas = this.classificacoesSelecionadas.filter(
      c => c.co_classif_pedag !== id
    );
  }
}
