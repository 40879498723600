<nz-modal
  [(nzVisible)]="visible"
  [nzMaskClosable]="false"
  [nzClosable]="false"
  [nzWidth]="400"
  [nzFooter]="null"
  (nzOnCancel)="onClose.emit()"
>
  <strong>{{title}}</strong>

  <main>

    <nz-alert style="margin-bottom: 10px;" *ngIf="error" nzType="error" [nzMessage]="error" nzShowIcon></nz-alert>
    <app-form-layout
      *ngIf="!loading; else templateLoading"
      [form]="form"
      [attributes]="attributes"
    ></app-form-layout>

    <ng-template #templateLoading>
      <div class="loading">
        <i nz-icon nzType="loading" nzTheme="outline"></i>
      </div>
    </ng-template>
  </main>

  <footer>
    <button nz-button [disabled]="loadingSave || loading" (click)="close()">Cancelar</button>
    <button nz-button nzType="primary" [disabled]="loading" [nzLoading]="loadingSave" (click)="onSubmitForm()">Inserir</button>
  </footer>
</nz-modal>
