<nz-form-item>
  <nz-form-label></nz-form-label>

  <nz-form-control>
    <div [style.text-align]="button.align || 'left'">
      <button 
        nz-button 
        [nzType]="button.type" 
        [nzShape]="button.shape"
        (click)="onClear()"
      >
        {{ button.title }}
      </button>
    </div>
  </nz-form-control>
</nz-form-item>