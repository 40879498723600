import { Component, OnInit, Input, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import * as _ from 'lodash';
import { URL_API, formExplain } from '../../../../shared/utils/const';

declare var $;

let params: Array<{
  key: string;
  label: string;
}> = [];

@Component({
  selector: 'app-summernote-rich-text-area',
  templateUrl: './summernote-rich-text-area.component.html',
  styleUrls: ['./summernote-rich-text-area.component.less']
})
export class SummernoteRichTextAreaComponent implements OnInit {
  
  @Input() form;
  @Input() attribute;
  @Input() initial;

  formExplain = formExplain;

  config =  {
    lang: 'pt-BR',
    placeholder: 'Digite aqui...',
    height: '300px',
    dialogsInBody: true,
    disableDragAndDrop: true,
    uploadImagePath: `${URL_API}/storage/put`,
    toolbar: [
      ['ev', ['style']],
      ['font', ['bold', 'italic', 'underline', 'clear']],
      ['fontname', ['fontname']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture']],
      ['view', ['fullscreen', 'codeview']],      
    ],
  }

  editorDisabled = false;
  isFucos = false;
  loadingUpload = false;
  isTypeUpload = false;
  isSizeUpload = false;

  get sanitizedHtml() {
    return this.sanitizer.bypassSecurityTrustHtml(this.form.get(this.attribute.key).value);
  }

  constructor(private sanitizer: DomSanitizer, private zone: NgZone){}

  ngOnInit(): void{
    params = _.result(this.attribute, 'formItem.pars.params');

    if (params) {
      this.config.toolbar.push(['eventDropdown', ['event']]);
      this.config['buttons'] = {
        event: this.adicionarParametro
      };
    }

    if(!_.isEmpty(this.attribute.formItem.pars.height)){
      this.config.height = _.result(this.attribute, 'formItem.pars.height');
    }
  }

  enableEditor(): void{
    this.editorDisabled = false;
  }

  disableEditor(): void{
    this.editorDisabled = true;
  }

  onBlur(): void{
    this.zone.run(() => { this.isFucos = false; });
  }

  onFocus(): void{
    this.zone.run(() => { this.isFucos = true; });
  }

  isUpload(event): void{
    this.loadingUpload = _.get(event, 'uploading');
    this.isTypeUpload = _.get(event, 'type');
    this.isSizeUpload = _.get(event, 'size');
  }

  action(): void{
    this.loadingUpload = false;
    this.isTypeUpload = false;
    this.isSizeUpload = false;
  }
  
  summernoteModelChange(event) {
    //console.log('***** ', event);
  }

  adicionarParametro(context) {
    const items = params.map(param => param.label);

    const ui = $.summernote.ui;
    
    const event = ui.buttonGroup([
      ui.button({
        contents: 'Adicionar parâmetro <i class="note-icon-caret"></i>',
        data: {
          toggle: 'dropdown'
        }
      }),
      ui.dropdown({
        items,
        callback: function (items) {
          $(items).find('.note-dropdown-item').on('click', function(e){
            const param = params.find(param => param.label === $(this).text());

            context.invoke("editor.insertText", param.key);
            e.preventDefault();
          })
        }
      })
    ]);
  
    return event.render();
  }
}
