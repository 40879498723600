<div class="container-landing">
    <app-layout-header [isButtonVisible]="false"></app-layout-header>
    <div class="container">
        <div class="first-line" nz-row>
            <div nz-col nzSpan="24">
                <h3>
                    <strong>Bem-vindo(a) à Plataforma de Avaliação dos Materiais Didáticos do Programa Nacional do Livro
                        e do Material Didático.
                    </strong>
                </h3>
            </div>
        </div>

        <div class="middle-line" nz-row>
            <div nz-col nzSpan="12">
                <img src="assets/img/ilust.svg" alt="">

            </div>
            <div nz-col nzSpan="12">
                <p>
                    O Ministério da Educação executa, em parceria com o Fundo Nacional de Desenvolvimento da Educação
                    (FNDE), o Programa Nacional do Livro e
                    do Material Didático (PNLD), destinado a disponibilizar obras didáticas, pedagógicas e literárias,
                    entre outros materiais de apoio à prática
                    educativa, de forma sistemática, regular e gratuita, a todas as escolas públicas de Educação Básica
                    do Brasil.
                </p>
                <p>
                    A avaliação pedagógica dos materiais didáticos no âmbito do PNLD é coordenada pela Secretaria de
                    Educação Básica (SEB) do Ministério da
                    Educação, por meio desta plataforma, que possui duas importantes funções: manter um banco de
                    avaliadores e executar a avaliação pedagógica dos
                    materiais.
                </p>

                <a nz-button nzType="primary" nzShape="round" [routerLink]="['/criar']">
                    <strong>
                        Cadastro no Banco de Professores
                    </strong>
                </a>

                <a nz-button nzShape="round" [routerLink]="['/login']">
                    <strong>
                        Acesso à Plataforma
                    </strong>
                </a>

            </div>
        </div>

        <banner-curso-pnld-home></banner-curso-pnld-home>

        <div class="help" nz-row>
            <span>Caso tenha dificuldades com o cadastro, <a href="assets/pdf/tutorial_cadastro_pnld.pdf"
                    target="_blank">consulte o tutorial</a>. Para relatar problemas, <a
                    href="mailto:atendimento+pnldavaliacao@nees.ufal.br?subject=Contato">entre em contato.</a></span>
        </div>


    </div>
    <app-layout-footer></app-layout-footer>

</div>