import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { formExplain } from '../../../utils/const';
import { HELPER_MESSAGE } from "../../../utils/helperMessage";

@Component({
  selector: 'app-form-explain',
  templateUrl: './form-explain.component.html',
  styleUrls: ['./form-explain.component.less']
})
export class FormExplainComponent {

  @Input() control: FormControl;
  @Input() sizeUpload: number;
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() attribute: any;

  formExplain = formExplain;
  HELPER_MESSAGE = HELPER_MESSAGE;
}
