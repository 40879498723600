import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

import { ApiService } from '../../../../services/api.service';
import { Fase, FaseDataSet, Objeto, ValueFase } from './fases.model';

@Component({
  selector: 'app-fases',
  templateUrl: './fases.component.html',
  styleUrls: ['./fases.component.less']
})
export class FasesComponent implements OnInit, OnChanges {

  @Input() form: FormGroup;
  @Input() initial: ValueFase[];
  @Input() classificacoes: any;
  @Input() isView = false;

  controlObjeto: FormControl;
  controlFormFase: FormControl;

  objetos: Objeto[];
  dataSet: FaseDataSet[] = [];
  values: ValueFase[] = [];

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.controlFormFase = new FormControl(null);
    this.controlObjeto = new FormControl(null);

    if (!this.isView) {
      this.form.setControl('fases', this.controlFormFase);
    }
    
    this.api.get<Fase[]>('fase').subscribe(
      fases => {
        const filhos = fases.filter(fase => fase.co_fase_sup);
        
        this.dataSet = fases.filter(f => !f.co_fase_sup).map(fase => ({
          id: fase.co_fase,
          title: fase.ds_fase,
          dt_inicio: undefined,
          dt_final: undefined,
          active: true,
          items: filhos.filter(
            fFilha => fFilha.co_fase_sup === fase.co_fase
          ).map(faseFilho => ({
            id: faseFilho.co_fase,
            title: faseFilho.ds_fase,
            dt_inicio: undefined,
            dt_final: undefined,
          }))
        }));
        
        if (this.initial && this.initial.length > 0) {
          this.initial.forEach(({ co_fase, co_objeto, dt_inicio, dt_final }) => {
            this.values.push({ 
              co_fase, 
              co_objeto, 
              dt_inicio, 
              dt_final
            })
          });

          this.controlObjeto.setValue(this.objetos[0].co_objeto);

          this.controlFormFase.setValue(this.values);
        }
      }
    );

    this.controlObjeto.valueChanges.subscribe(value => {
      this.initFaseDataSet();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const items = _.map(this.classificacoes, classificacao => classificacao.objeto);
    this.objetos = _.uniqWith(items, _.isEqual);

    const objetosId = this.objetos.map(o => o.co_objeto);    
    this.values = this.values.filter(value => objetosId.indexOf(value.co_objeto) > -1);
    this.controlFormFase?.setValue(this.values);

    this.controlObjeto?.setValue(null);
  }

  onOpenCalendarChange(isOpen: boolean, { id: co_fase, dt_inicio, dt_final }: FaseDataSet): void {
    if (!isOpen) {
      const co_objeto = this.controlObjeto.value;
      const existFaseIndex = this.values.findIndex(
        value => value.co_fase === co_fase && value.co_objeto === co_objeto
      );

      if (existFaseIndex < 0) {
        this.values.push({
          co_objeto,
          co_fase,
          dt_inicio,
          dt_final
        });
      } else {
        this.values[existFaseIndex] = {
          co_objeto,
          co_fase,
          dt_inicio,
          dt_final
        };
      }

      this.controlFormFase.setValue(this.values);
    }
  }

  onActiveCollapse(fase: FaseDataSet): void {
    fase.active = !fase.active;
  }

  initFaseDataSet(): void {
    if (this.dataSet.length > 0) {
      const co_objeto = this.controlObjeto.value;
      const values = this.values.filter(value => value.co_objeto === co_objeto);
  
      this.dataSet.forEach(fase => {
        const faseValue = values.find(value => value.co_fase === fase.id);
  
        fase.dt_inicio = faseValue?.dt_inicio;
        fase.dt_final = faseValue?.dt_final;
        fase.active = true;
  
        if (fase.items?.length > 0) {
          fase.items.forEach(faseFilha => {
            const faseFilhaValue = values.find(value => value.co_fase === faseFilha.id);
  
            faseFilha.dt_inicio = faseFilhaValue?.dt_inicio;
            faseFilha.dt_final = faseFilhaValue?.dt_final;
          });
        }
      });
    }
  }
}
