<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control [nzErrorTip]="errorTpl">

      <nz-input-group [nzSuffix]="suffixTemplate">
        <input
          [id]="attribute?.key"
          nz-input
          type="text"
          [mask]="settingMask?.mask"
          [dropSpecialCharacters]="settingMask?.dropSpecialCharacters"
          [formControlName]="attribute?.key"
          [placeholder]="attribute?.formItem?.placeholder ? attribute?.formItem?.placeholder : ''"
        >
      </nz-input-group>

      <ng-template #suffixTemplate>
        <i *ngIf="!loading" nz-icon nzType="search" nzTheme="outline"></i>
        <i *ngIf="loading" nz-icon nzType="loading" nzTheme="outline"></i>
      </ng-template>

      <ng-template #errorTpl let-control>
        <app-form-explain 
          [control]="control" 
          [minLength]="minLengthMask || attribute?.formItem?.maxLength"
          [maxLength]="attribute?.formItem?.maxLength"
          [attribute]="attribute"
        ></app-form-explain>
      </ng-template>

    </nz-form-control>
  </nz-form-item>
</ng-container>
