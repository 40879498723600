<div class="info guia-row">
  <div
    *ngFor="let attribute of attributes"
    [ngClass]="!attribute?.formItem?.pars?.gridClass ? 'col-12' : attribute?.formItem?.pars?.gridClass"
    [ngStyle]="attribute?.formItem?.pars?.gridWidth ? {'max-width': attribute?.formItem?.pars?.gridWidth} : {}"
  >
    <div
      [ngStyle]="attribute?.formItem?.pars?.controlWidth ? {'max-width': attribute?.formItem?.pars?.controlWidth} : {}"
    >
      <app-form-items
        [attribute]="attribute"
        [initial]="initial"
        [form]="form"
        [isLabelOptional]="isLabelOptional"
        (callback)="callback.emit($event)"
      ></app-form-items>
    </div>
  </div>
</div>