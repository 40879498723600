
export const environment = {
  production: false,
  name: '',  
  debug: true,
  api: '',
  urlPDFAmazon: '',
  jasperPath: '',
  pusher: {
    key: '',
    cluster: '',
  },
  sentry: {
    DSN: '',
    release: 'undefined',
    envName: '',
    tracesSampleRate: 0.0
  }
};
