import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class SessionExpirationService {

  private $isResetTime = new BehaviorSubject(false);
  isResetTime = this.$isResetTime.asObservable();

  constructor() { }

  startSession(isReset = true): void {
    this.$isResetTime.next(isReset);
  }  
}