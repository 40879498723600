<header>
  <strong>Fases</strong>
</header>

<div class="container-fases">
  <form nz-form nzLayout="vertical" class="guia-row">
    <nz-form-item class="col-4">
      <nz-form-label nzFor="objeto">Objeto</nz-form-label>
      <nz-form-control>
        <nz-select 
          nzShowSearch 
          nzAllowClear 
          nzPlaceHolder="Objeto"
          [formControl]="controlObjeto"
        >
          <nz-option *ngFor="let objeto of objetos"
            [nzLabel]="objeto.ds_objeto" 
            [nzValue]="objeto.co_objeto"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>

  <table>
    <thead>
      <tr>
        <th class="item">#</th>
        <th>Fase</th>
        <th>Início</th>
        <th>Final</th>
        <th class="item"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="controlObjeto.value; else templateItem">      
        <ng-container *ngFor="let fase of dataSet; let index = index">
          <tr *ngIf="fase.items.length === 0; else templateCollapse">

            <td class="item">{{index + 1}}</td>

            <td>{{fase.title}}</td>

            <td class="date">
              <nz-date-picker 
                *ngIf="!isView"
                nzPlaceHolder="dd/mm/aaaa"
                [nzFormat]="'dd/MM/yyyy'"
                [nzSuffixIcon]="iconCalenda"
                [(ngModel)]="fase.dt_inicio"
                (nzOnOpenChange)="onOpenCalendarChange($event, fase)"
              ></nz-date-picker>

              <ng-container *ngIf="isView">
                {{fase.dt_inicio | date: 'dd/MM/YYYY' }}
              </ng-container>
            </td>

            <td class="date">
              <nz-date-picker 
                *ngIf="!isView"
                nzPlaceHolder="dd/mm/aaaa"
                [nzFormat]="'dd/MM/yyyy'"
                [nzSuffixIcon]="iconCalenda"
                [(ngModel)]="fase.dt_final"
                (nzOnOpenChange)="onOpenCalendarChange($event, fase)"
              ></nz-date-picker>

              <ng-container *ngIf="isView">
                {{fase.dt_final | date: 'dd/MM/YYYY' }}
              </ng-container>
            </td>

            <td class="item"></td>
          </tr>

          <ng-template #templateCollapse>
            <tr class="collapse" [ngClass]="{'active': fase.active}" (click)="onActiveCollapse(fase)">
              <td class="item">{{index + 1}}</td>
              <td colspan="3">{{fase.title}}</td>
              <td class="item">
                <i nz-icon nzType="down" nzTheme="outline"></i>
              </td>
            </tr>

            <ng-container *ngIf="fase.active">
              <tr 
                *ngFor="let faseFilha of fase.items; let i = index; let first = first; let last = last"
                [ngClass]="{'first': first, 'last': last}"
              >
                <td class="item">{{index + 1}}.{{i + 1}}</td>

                <td>{{faseFilha.title}}</td>
                
                <td class="date">
                  <nz-date-picker 
                    *ngIf="!isView"
                    nzPlaceHolder="dd/mm/aaaa"
                    [nzFormat]="'dd/MM/yyyy'"
                    [nzSuffixIcon]="iconCalenda"
                    [(ngModel)]="faseFilha.dt_inicio"
                    (nzOnOpenChange)="onOpenCalendarChange($event, faseFilha)"
                  ></nz-date-picker>

                  <ng-container *ngIf="isView">
                    {{faseFilha.dt_inicio | date: 'dd/MM/YYYY' }}
                  </ng-container>
                </td>
                
                <td class="date">
                  <nz-date-picker 
                    *ngIf="!isView"
                    nzPlaceHolder="dd/mm/aaaa"
                    [nzFormat]="'dd/MM/yyyy'"
                    [nzSuffixIcon]="iconCalenda"
                    [(ngModel)]="faseFilha.dt_final"
                    (nzOnOpenChange)="onOpenCalendarChange($event, faseFilha)"
                  ></nz-date-picker>

                  <ng-container *ngIf="isView">
                    {{faseFilha.dt_final | date: 'dd/MM/YYYY' }}
                  </ng-container>
                </td>
                
                <td class="item"></td>
              </tr>
            </ng-container>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-template #templateItem>
        <tr>
          <td class="item">1</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td class="item"></td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>

<ng-template #iconCalenda>
  <i nz-icon nzType="calendar" nzTheme="fill"></i>
</ng-template>