import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { ApiService } from './../../../services/api.service';
import { FormLayoutControlService } from './../../form-layout-control.service';

@Component({
  selector: 'app-select-card',
  templateUrl: './select-card.component.html',
  styleUrls: ['./select-card.component.less']
})
export class SelectCardComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;
  @Output() action = new EventEmitter;
  @Output() callback = new EventEmitter;

  // Lodash
  _ = _;

  filters = [
    ["st_edital", "=", "P"]
  ];

  control: FormControl;
  loading: boolean = false;
  cardsDataSet: any[] = [];
  actions: any[] = [];
  icon: string;
  keyValue: string;
  keyTitle: string;
  keyDescription: string;

  constructor(
    private api: ApiService,
    private formLayoutControlService: FormLayoutControlService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.control = this.form.get(this.attribute.key);
    this.icon = _.result(this.attribute, 'formItem.pars.icon');
    this.keyTitle = _.result(this.attribute, 'formItem.pars.attributeTitle');
    this.keyValue = _.result(this.attribute, 'formItem.pars.attributeKey');
    this.keyDescription = _.result(this.attribute, 'formItem.pars.attributeDescription');
    this.actions = _.result(this.attribute, 'formItem.pars.actions');

    this.api.get<any[]>(_.result(this.attribute, 'formItem.pars.endpoint'))
      .subscribe(
        resp => {
          this.cardsDataSet = resp;
          this.loading = false;
        },
        err => {

        }
      );
  }

  valueChanges(item): void{
    this.control.setValue(_.result(item, this.keyValue));
    this.callback.emit({
      field: this.formLayoutControlService.getIndexFormGroup(this.form) != null ? `${this.attribute.key}_${this.formLayoutControlService.getIndexFormGroup(this.form)}` : this.attribute.key,
      options: item
    });
  }
}
