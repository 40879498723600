<div class="menu">
  <ng-container *ngIf="!loading; else templateLoading">
    <div class="item" *ngFor="let item of cardsDataSet">
      <h2 class="guia-header top"
        [ngClass]="{'active': control?.value === _.result(item, keyValue), 'error': control?.dirty && control?.errors}"
        (click)="valueChanges(item)">
        <div class="body">
          <i *ngIf="icon" class="icon-header" nz-icon [nzType]="icon" nzTheme="outline"></i>
          <span>{{ _.result(item, keyTitle) }}</span>
          <div class="sub-header">
            {{ _.result(item, keyDescription) }}
          </div>
        </div>

        <!-- Action -->
        <div *ngIf="actions?.length > 0" class="actions" [ngClass]="{
          'one': actions?.length === 1,
          'two': actions?.length === 2,
          'three': actions?.length === 3
        }">
          <ng-container *ngFor="let action of actions; let iAction=index">
            <div class="item button">
              <div *ngIf="action?.key != 'link'" class="action" (click)="action.emit({'action': action})">
                <i nz-icon [nzType]="action?.icon" nzTheme="outline"></i>
                <span *ngIf="action.title">{{ action.title }}</span>
              </div>
              <a *ngIf="action?.key == 'link'" class="action" [href]="_.result(item, action?.link)" target='_blank'>
                <i nz-icon [nzType]="action?.icon" nzTheme="outline"></i>
                <span *ngIf="action.title">{{ action.title }}</span>
              </a>
            </div>
          </ng-container>
        </div>
      </h2>
    </div>
  </ng-container>
</div>

<!-- loading -->
<ng-template #templateLoading>
  <div class="guia-loading skeleton item" *ngFor="let i of [0,1,2]">
    <h2 class="guia-header top">
      <div class="body">
        <i *ngIf="icon" class="icon-header item-skeleton"></i>
        <span class="item-skeleton"></span>
        <div class="sub-header item-skeleton"></div>
      </div>

      <!-- Action -->
      <div *ngIf="actions?.length > 0" class="actions" [ngClass]="{
        'one': actions?.length === 1,
        'two': actions?.length === 2,
        'three': actions?.length === 3
      }">
        <ng-container *ngFor="let action of actions; let iAction=index">
          <div class="item button">
            <div class="item-skeleton">
              <i></i>
              <span *ngIf="action.title"></span>
            </div>
          </div>
        </ng-container>
      </div>
    </h2>
  </div>
</ng-template>
