import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { FormLayoutControlService } from '../form-layout-control.service';

@Component({
  selector: 'app-form-items',
  templateUrl: './form-items.component.html',
  styleUrls: ['./form-items.component.less']
})
export class FormItemsComponent implements OnInit {

  @Input() attribute;
  @Input() initial;
  @Input() form: FormGroup;
  @Input() isLabelOptional: boolean = false;
  @Output() callback = new EventEmitter();

  /* Lodash */
  _ = _

  formItemKey: string;
  visible: boolean = true;
  valueAtual: string;
  filterAtual: string

  constructor(
    private formLayoutControlService: FormLayoutControlService
  ) { }

  ngOnInit(): void {
    this.formItemKey = _.result(this.attribute, 'formItem.key');

    //Davi Zanotto
    //Verificar se o atributo define que o formitem
    //tem dependência com outro formitem para ser exibido
    //passando um valor fixo para ser comparado.
    //
    //No caso do select-single-search, não é recebido o filterFromAttribute.value
    //E por isso não entrará nesse if, deixando a regra de negócio toda dentro
    //do código dele próprio. Porém, se for passado filterFromAttribute.value pelo setup
    //aí sim será considerado aqui.
    const filterFromAttribute = _.get(this.attribute, 'formItem.pars.filterFromAttribute');

    if(!_.isEmpty(filterFromAttribute)){
      if(_.isArray(filterFromAttribute)){
        this.form.valueChanges.subscribe(values => {
          let filter = '';

          _.map(filterFromAttribute, (item, i) => {
            if(i > 0)
              filter = filter + ' && ';

            if(_.isArray(item.value))
              filter = filter + `(${this.tratarCondition(values[item.attributeKey] || '', item.operator, item.value)})`;
            else
              filter = filter + `'${values[item.attributeKey] || ''}' ${item.operator} '${item.value}'`
          });

          if(this.filterAtual != filter){
            this.filterAtual = filter;
            this.onVisible(eval(filter));
          }
        })
      } else {

        //Usando o conceito de destructuring (ES6/ES7) para renomear o atributo value para fixedValue
        const { attributeKey, operator, value: fixedValue } = filterFromAttribute;
        try {
          if (this.form.get(attributeKey) && fixedValue) {
            this.onVisible(eval(this.tratarCondition(this.form.get(attributeKey).value, operator, fixedValue)));
          } else {
            this.onVisible(true);
          }
        } catch (error) {
          console.error('Erro ao avaliar a condição:', error, attributeKey, operator, fixedValue);
          this.onVisible(true); // Defina um valor padrão em caso de erro
        }
        this.form.valueChanges.subscribe(values => {
          const value = _.result(values, attributeKey) || '';

          if(value != this.valueAtual){
            this.valueAtual = value;
            try {
              if (value && fixedValue) {
                this.onVisible(eval(this.tratarCondition(value, operator, fixedValue)));
              }
              else {
                this.onVisible(true);
              }
            } catch (error) {
              console.error('Erro ao avaliar a condição:', error, attributeKey, operator, fixedValue);
              this.onVisible(true); // Defina um valor padrão em caso de erro
            }
          }
        });
      }
    }
  }

  tratarInCondition(value: any, operator: string, fixedValue: any): string {
    // Garantir que fixedValue seja um array ou objeto
    let validValues: Set<any> = new Set();

    if (typeof fixedValue === 'string') {
      try {
        validValues = new Set(Object.keys(JSON.parse(fixedValue)));
      } catch (e) {
        console.error('Erro ao analisar fixedValue:', e);
        return '';
      }
    } else if (Array.isArray(fixedValue)) {
      validValues = new Set(fixedValue);
    } else {
      console.error('fixedValue deve ser uma string JSON ou array');
      return '';
    }

    // Verificar o operador e retornar o resultado apropriado
    if (operator === 'in') {
      if (Array.isArray(value)) {
        // Verifica se todos os valores estão no conjunto de valores válidos
        return value.every(v => validValues.has(v)) ? 'true' : 'false';
      } else {
        // Verifica se o valor está no conjunto de valores válidos
        return validValues.has(value) ? 'true' : 'false';
      }
    } else if (operator === 'not in') {
      if (Array.isArray(value)) {
        // Verifica se nenhum dos valores está no conjunto de valores válidos
        return value.every(v => !validValues.has(v)) ? 'true' : 'false';
      } else {
        // Verifica se o valor não está no conjunto de valores válidos
        return !validValues.has(value) ? 'true' : 'false';
      }
    } else {
      console.error('Operador não suportado');
      return '';
    }
  }

  tratarCondition(value, operator, fixedValue){
    if (operator == 'in' || operator == 'not in') {
      return this.tratarInCondition(value, operator, fixedValue);
    }
    let conditions = '';

    if(_.isArray(fixedValue)){
      if(_.isArray(value) && value.length > 0){

        _.map(value, (v, i) => {
          if(i > 0)
            conditions = conditions + ' && ';

          _.map(fixedValue, (vf, i) => {
            if(i > 0)
              conditions = conditions + ' && ';
            conditions = conditions + `'${v}' ${operator} '${vf}'`
          });
        });
      } else {
        _.map(fixedValue, (vf, i) => {
          if(i > 0)
            conditions = conditions + ' && ';
          conditions = conditions + `'${value}' ${operator} '${vf}'`
        });
      }

    } else {
      if(_.isArray(value) && value.length > 0){
        _.map(value, (v, i) => {
          if(i > 0)
            conditions = conditions + ' && ';
          conditions = conditions + `'${v}' ${operator} '${fixedValue}'`
        });
      } else {
        conditions = `'${value}' ${operator} '${fixedValue}'`;
      }
    }

    return conditions;
  }

  onVisible(conditions): void{
    //Obs: está testando sempre convertendo para String.
    if (conditions) {
      if (this.attribute.key) {
        if(!_.get(this.attribute, 'formItem.pars.isDuplicated')) {
          this.form.removeControl(this.attribute.key);
        }
      }

      this.visible = false;
    } else {
      if (this.attribute.key) {
        this.form.addControl(this.attribute.key, this.formLayoutControlService.toFormField(this.attribute));
      }

      this.visible = true;
    }
  }
}
