import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'banner-curso-pnld-home',
  templateUrl: './banner-curso-pnld-home.component.html',
  styleUrls: ['./banner-curso-pnld-home.component.less']
})
export class BannerCursoPnldHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
