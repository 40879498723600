import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { treatDataset } from 'src/app/shared/utils/const';

import { FormLayoutControlService } from '../../form-layout-control.service';

interface SelectData {
  field: string;
  options: Array<{
    value: string;
    option: string;
  }>
}

@Component({
  selector: 'app-dynamic-form-table',
  templateUrl: './dynamic-form-table.component.html',
  styleUrls: ['./dynamic-form-table.component.less']
})
export class DynamicFormTableComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;
  @Input() isView = false;

  validateForm: FormGroup;
  control: FormControl;
  attributes: any;

  valuesTable: Array<{[key: string]: any;}> = [];

  valuesSelect: {
    [key: string]: string;
  };

  constructor(
    private formLayoutControlService: FormLayoutControlService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.attributes = _.result(this.attribute, 'formItem.pars.attributes');

    if (!this.isView) {
      this.validateForm = this.formLayoutControlService.toFormGroup(this.attributes);
      this.control = this.form.get(this.attribute.key);
    }

    if (this.initial?.value) {
      this.valuesTable =  treatDataset(this.initial.value, this.attributes);
    }
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if(this.validateForm.valid){
      const valueControl = this.control.value || [];
      
      this.control.setValue([
        ...valueControl,
        this.validateForm.value
      ]);

      const values = this.validateForm.value;
      const formattedValues = {};
            
      _.forEach(this.attributes, att => {
        switch (att.formItem.key) {
          case 'select-single-static-values':
            const option = _.find(att.formItem.pars.options, { 'value': values[att.key] });
            Object.assign(formattedValues, { [att.key]: option?.description });
            break;

          default:
            Object.assign(formattedValues, { [att.key]: values[att.key] });
            break;
        }
      });

      this.valuesTable.push({
        ...formattedValues,
        ...this.valuesSelect
      });

      this.validateForm.reset();
      this.valuesSelect = {};
    }
  }

  removeItem(index: number): void {
    const values = [...this.control.value];
    values.splice(index, 1);

    this.control.setValue(values);
    this.valuesTable.splice(index, 1);
  }

  callback(event: SelectData): void {
    let options = '';

    event.options.forEach(({ value, option }, index)=> {
      if (index > 0) {
        options += `, [${value}]${option}`;
      } else {
        options += `[${value}] ${option}`;
      }
    });

    this.valuesSelect = {
      ...this.valuesSelect,
      [event.field]: options 
    };
  }
}