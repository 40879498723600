<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">
      {{ attribute?.description }}</nz-form-label>
    <nz-form-control [nzErrorTip]="errorTpl">
      <label nz-checkbox [formControlName]="attribute?.key">
        <div [innerHtml]="safeHtml(attribute?.formItem?.description)"></div>
      </label>

      <ng-template #errorTpl let-control>
        <app-form-explain [control]="control" [attribute]="attribute"></app-form-explain>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
</ng-container>

<!-- <nz-form-item>
  <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">
    {{ attribute?.description }}</nz-form-label>
  <nz-form-control [nzValidateStatus]="control?.dirty && control?.errors ? 'error' : ''">
    <label nz-checkbox [(ngModel)]="checked"
      (ngModelChange)="modelChange($event)">{{ attribute?.formItem?.description }}</label>
    <nz-form-explain *ngIf="control?.dirty && control?.errors">
      <app-form-explain [form]="form" [attribute]="attribute"></app-form-explain>
    </nz-form-explain>
  </nz-form-control>
</nz-form-item> -->
