import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from "@angular/core";
import { FormControl } from "@angular/forms";

import { BehaviorSubject, from, Subject } from "rxjs";
import {
  switchMap,
  tap,
  debounceTime,
  distinctUntilChanged,
  catchError,
  take,
  takeUntil,
  startWith
} from "rxjs/operators";
import * as _ from "lodash";

import { ApiService } from "../../../services/api.service";
import { FormLayoutControlService } from "../../form-layout-control.service";
import { ModuleService } from "../../../services/module.service";
import {
  DEFAULT_DESCRIPTION_PREFIX,
  URL_API_SEARCH
} from "../../../utils/const";
import { PnldFiltroBase } from "src/app/shared/models/pnled-filtros-base.model";

@Component({
  selector: "app-select-search",
  templateUrl: "./select-search.component.html",
  styleUrls: ["./select-search.component.less"]
})
export class SelectSearchComponent implements OnInit, OnDestroy {
  @Input() form;
  @Input() attribute;
  @Input() initial;
  @Input() mode: string = "default";
  @Input() static: boolean = false;
  @Output() callback = new EventEmitter();

  control: FormControl;
  defaultLimit: number = 1000;
  options: any;
  isOpen: boolean = false;
  keyValue: string;
  descriptionValue: string;
  isLoading: boolean = false;
  defaultValuesSelected: boolean = false;
  filter: any[];
  disabled: boolean = false;
  previousValueDependency: string = null;

  dependentFormItem: any;
  performedSearch: boolean = false;
  isSearch: boolean = false;

  searchSub = new BehaviorSubject(null);
  search = this.searchSub.asObservable();
  unsub$ = new Subject();

  hierarchy: string = null;

  /* Lodash */
  _ = _;

  visibleModalCreateItem = false;

  other: {
    label: string;
    value: any;
  };
  pnldFiltroBase: PnldFiltroBase = JSON.parse(
    localStorage.getItem("@pnld_filtro_base") || "{}"
  );

  constructor(
    private api: ApiService,
    private formLayoutControlService: FormLayoutControlService,
    private moduleService: ModuleService
  ) {}

  ngOnInit(): void {
    this.control = this.form.get(this.attribute.key);
    this.isSearch = true; //_.result(this.attribute, 'formItem.pars.isSearch');
    this.dependentFormItem = _.result(
      this.attribute,
      "formItem.pars.dependentFormItem"
    );
    this.other = _.result(this.attribute, "formItem.pars.other");
    this.hierarchy = _.result(this.attribute, "formItem.pars.hierarchy");

    if (!_.isEmpty(this.initial)) {
      this.options = this.initial.options;
      if (!_.isEmpty(this.attribute.formItem.pars.filterFromAttribute)) {
        this.previousValueDependency = this.form.get(
          this.attribute.formItem.pars.filterFromAttribute.attributeKey
        ).value;
      }
    } else if (
      _.get(this.attribute, "formItem.pars.defaultValuesSelected") == true
    ) {
      if (_.isEmpty(this.attribute.formItem.pars.filterFromAttribute)) {
        this.onFetch();
      }
    }

    if (!this.static) {
      if (_.isEmpty(this.attribute.formItem.pars.attributeKey))
        this.keyValue = this.attribute.key;
      else this.keyValue = this.attribute.formItem.pars.attributeKey;

      if (_.isEmpty(this.attribute.formItem.pars.attributeValue))
        this.descriptionValue =
          DEFAULT_DESCRIPTION_PREFIX + _.snakeCase(this.attribute.key);
      else this.descriptionValue = this.attribute.formItem.pars.attributeValue;
    } else {
      this.options = this.attribute.formItem.pars.options;
    }

    if (!_.isEmpty(this.attribute.formItem.pars.filterFromAttribute)) {
      const filterAttribute = _.get(
        this.attribute,
        "formItem.pars.filterFromAttribute"
      );

      if (filterAttribute.value == null) {
        if (this.form.get(filterAttribute.attributeKey)?.value == null)
          this.control.disable();

        this.form
          .get(filterAttribute.attributeKey)
          .valueChanges.subscribe(values => {
            if (values != this.previousValueDependency) {
              this.previousValueDependency = values;
              this.control.reset();

              if (values) {
                if (!_.get(this.attribute, "isDisabled")) this.control.enable();
                if (
                  _.get(
                    this.attribute,
                    "formItem.pars.defaultValuesSelected"
                  ) == true
                ) {
                  this.onFetch();
                }
              } else this.control.disable();
            }
          });
      }
    }

    if (!_.isEmpty(this.attribute.formItem.pars.disabledFromAttribute)) {
      const disabledFromAttribute = _.get(
        this.attribute,
        "formItem.pars.disabledFromAttribute"
      );

      if (
        eval(
          `'${this.form.get(disabledFromAttribute.attributeKey).value}' ${
            disabledFromAttribute.operator
          } '${disabledFromAttribute.value}'`
        )
      ) {
        this.control.disable();
      }

      this.form
        .get(disabledFromAttribute.attributeKey)
        .valueChanges.subscribe(values => {
          if (
            eval(
              `'${values}' ${disabledFromAttribute.operator} '${disabledFromAttribute.value}'`
            )
          ) {
            this.control.enable();
          } else {
            this.control.disable();
          }
        });
    }

    if (this.isSearch) {
      this.search
        .pipe(
          debounceTime(500),
          tap(() => (this.isLoading = false)),
          distinctUntilChanged(),
          switchMap(value =>
            value != null 
              ? this.api.post(
                  `${this.attribute.model}${URL_API_SEARCH}`,
                  this.onFilter({
                    model: "",
                    filter: [
                      _.get(this.attribute, "formItem.pars.attributeValue"),
                      "like",
                      `%${value}%`
                    ],
                    path: _.get(this.attribute, "path")
                  })
                )
                .pipe(catchError(error => from([])))
              : from([])
          ),
          takeUntil(this.unsub$)
        )
        .subscribe(resp => {
          this.options = _.result(resp, "data");
          this.isLoading = false;
        });
    }

    this.control.valueChanges.pipe(
      startWith(this.control.value)
    ).subscribe(values => {
      if (values) {
        const optionActive = [];
        let valuasDependent = null;
        let item = null;

        if (!(this.options?.length > 0)) {
          this.onFetch();
        }

        if (_.isArray(values)) {
          valuasDependent = [];

          _.map(values, v => {
            item = _.find(this.options, option => option[this.keyValue] == v);
            if (
              item &&
              _.result(this.attribute, "formItem.key") !=
                "select-single-static-values"
            ) {
              optionActive.push({
                value: item[this.keyValue],
                option: item[this.descriptionValue]
              });
            }

            if (this.dependentFormItem)
              valuasDependent.push(item[this.dependentFormItem.attributeKey]);
          });
        } else {
          item = _.find(
            this.options,
            option => option[this.keyValue] == values
          );
          if (
            item &&
            _.result(this.attribute, "formItem.key") !=
              "select-single-static-values"
          ) {
            optionActive.push({
              value: item[this.keyValue],
              option: item[this.descriptionValue]
            });
          }

          if (this.dependentFormItem)
            valuasDependent = item[this.dependentFormItem.attributeKey];
        }
        if (
          _.result(this.attribute, "formItem.key") !=
          "select-single-static-values"
        ) {
          this.callback.emit({
            field:
              this.formLayoutControlService.getIndexFormGroup(this.form) != null
                ? `${
                    this.attribute.key
                  }_${this.formLayoutControlService.getIndexFormGroup(
                    this.form
                  )}`
                : this.attribute.key,
            options: optionActive,
            keys: [this.keyValue, this.descriptionValue]
          });
        }

        if (this.dependentFormItem)
          this.form
            .get(this.dependentFormItem.dependentKey)
            .setValue(valuasDependent);
      }
    });
  }

  filtersAttribute(): void {
    const filterAttribute = _.get(
      this.attribute,
      "formItem.pars.filterFromAttribute"
    );
    const filter = [...this.attribute.formItem.filters];
    const value = this.form.get(filterAttribute.attributeKey).value;

    if (value != null) {
      let attributeKey = filterAttribute.attributeKey;
      if (!_.isEmpty(filterAttribute.filterAttributeKey)) {
        // Ao utilizar mais de um select-search baseado em um mesmo model, como ao pesquisar por município de nascimento e município de residência a partir dos
        // valores de UF de nascimento e UF de residência, é preciso diferenciar os attributeKey ao apontar para cada co_uf. Entretanto, o mesmo valor de
        // attributeKey também é usado no array de filtros do advanced-search. A fim de evitar erros de filtros inexistentes, adicionei o campo filterAttributeKey
        // para ser usado no lugar de attributeKey na definição dos filtros
        attributeKey = filterAttribute.filterAttributeKey;
      }
      filter.push({
        model: "",
        filter: [attributeKey, filterAttribute.operator, value],
        path: _.get(this.attribute, "path")
      });
    }

    if (
      value != this.previousValueDependency &&
      this.previousValueDependency != null
    ) {
      this.options = [];
      this.control.reset();
    }

    this.filter = filter;
    this.previousValueDependency = value;
  }

  onFilter(filterSearch?: any): any {
    if (!this.isOpen) {
      return;
    }
    this.isLoading = true;
    this.options = [];

    if (
      !_.isEmpty(this.attribute.formItem.pars.filterFromAttribute) &&
      // Checar se this.attribute.formItem.pars.filterFromAttribute.value é vazio.
      // Se for vazio, significa que é para filtrar de acordo com o campo selecionado do filterFromAttribute.attributeKey
      // Porém, se existir value, significa que é para fazer um filtro estático, que não vai para o BD.
      _.isEmpty(this.attribute.formItem.pars.filterFromAttribute.value)
    ) {
      //Enviar requisição para o backend com o filtro
      this.filtersAttribute();
    }

    let filtersFinal = [];

    if (!_.isEmpty(this.filter)) {
      filtersFinal = [...this.filter];
    } else {
      /**
       * Vamos verificar se foi passado filtro que
       * deverá puxar da localstorage. Através do pipe no value |
       */
      const filters = _.result(this.attribute.formItem, "filters") as any[];

      if (filters) {
        filters.map((f: any) => {
          const [key, operador, value] = f.filter;
          const [model, attKey] = value.split("|");
  
          if (attKey) {
            filtersFinal.push({
              ...f,
              filter: [key, operador, this.pnldFiltroBase[model].value]
            });
          } else {
            filtersFinal = [...this.attribute.formItem.filters];
          }
        });
      }
    }

    if (_.result(this.attribute, "formItem.pars.filtersDependency")) {
      _.map(
        _.result(this.attribute, "formItem.pars.filtersDependency"),
        item => {
          const control = this.form.get(_.result(item, "attributeKey"));
          const value = control
            ? control.value
            : this.moduleService.values[_.result(item, "attributeKey")];

          filtersFinal.push([
            _.result(item, "key"),
            _.result(item, "operator"),
            value
          ]);
        }
      );
    }

    if (filterSearch) {
      filtersFinal.push(filterSearch);
    }

    return _.isEmpty(filtersFinal)
      ? {
        orderBy: {
          attribute: this.descriptionValue,
          direction: 'asc'
        },
      }
      : {
          filters: filtersFinal,
          limit: this.attribute.formItem.pars.limit || this.defaultLimit,
          offset: 0,          
          orderBy: {
            attribute: this.descriptionValue,
            direction: 'asc'
          },
        };
  }

  onFetch(): void {
    const filters = this.onFilter();
    const defaultValuesSelected = _.get(
      this.attribute,
      "formItem.pars.defaultValuesSelected"
    );
    this.isLoading = true;
    let req = null;

    if (
      _.isEmpty(filters) &&
      _.result(this.attribute, "formItem.key") != "select-single-search"
    ) {
      req = {
        method: "GET",
        endpoint: this.attribute.model,
        filters: null
      };
    } else {
      req = {
        method: "POST",
        endpoint: `${this.attribute.model}${URL_API_SEARCH}`,
        filters: filters
      };
    }

    this.api
      .request(req.method, req.endpoint, req.filters)
      .pipe(take(1))
      .subscribe(resp => {
        if (
          _.isEmpty(filters) &&
          _.result(this.attribute, "formItem.key") != "select-single-search"
        )
          this.options = resp;
        else {
          this.options = _.result(resp, "data");
        }

        if (defaultValuesSelected == true && this.control.value == null) {
          if (this.mode === "default")
            this.control.setValue(_.result(this.options[0], this.keyValue));
          else {
            let values = [];
            this.options.map(item => {
              values.push(_.result(item, this.keyValue));
            });
            this.control.setValue(values);
          }
        }

        this.isLoading = false;
      });
  }

  onSearch(value: string): void {
    if (this.isSearch) {
      if (value.length > 2) {
        this.isLoading = true;
        this.performedSearch = true;
        this.searchSub.next(value);
      } else if (this.performedSearch) {
        this.performedSearch = false;
        this.searchSub.next("");
      }
    }
  }

  openChange(isOpen: boolean): void {
    this.isOpen = isOpen;
    if (isOpen && this.mode != "tags") {
      this.onFetch();
    } 
  }

  onPlaceholder(): string {
    const placeholder = _.result(this.attribute, "formItem.placeholder");
    const tooltip = _.result(this.attribute.tooltip);

    if (placeholder || tooltip || this.static) {
      if (tooltip) return tooltip;
      if (placeholder) return placeholder;

      return "Selecione...";
    } else {
      return this.isSearch
        ? "Digite 3 caracteres para pesquisar"
        : "Selecione...";
    }
  }

  openModalCreateItem(): void {
    this.visibleModalCreateItem = true;
  }

  closeModalCreateItem(): void {
    this.visibleModalCreateItem = false;
  }

  ngOnDestroy(): void {
    this.unsub$.next();
    this.unsub$.complete();
  }
  
  selectOther() {
    this.control.setValue(this.other.value);
    this.isOpen = false;
  }
}
