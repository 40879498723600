import { Component, OnInit, Input } from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.less']
})
export class InputNumberComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;
  
  imin: number = 0;
  before: string;
  after: string;

  constructor() { }

  ngOnInit(): void {
    this.before = _.result(this.attribute, 'formItem.pars.before');
    this.after = _.result(this.attribute, 'formItem.pars.after');
    this.imin = _.result(this.attribute, 'formItem.pars.min') ? _.result(this.attribute, 'formItem.pars.min') : 0;

    if(this.form.controls[this.attribute.key].value == null){
      this.form.controls[this.attribute.key].setValue(this.imin);
    }
  }  

  formatter = value => {
    let v = value;

    if(this.before) v = `${this.before} ${v}`;
    if(this.after) v = `${v} ${this.after}`;
    
    return v;
  }
    
  parser = value => value.replace(/\D/g, '');

}
