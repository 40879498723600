<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label
      *ngIf="attribute?.description"
      [nzRequired]="attribute?.isRequired"
      [nzFor]="attribute?.key"
    >
      {{ attribute?.description }}
    </nz-form-label>

    <nz-form-control
      [nzValidateStatus]="uploadControl.errors ? 'error' : ''"
      [nzErrorTip]="errorTpl"
      [nzExtra]="attribute?.formItem?.pars?.extra"
    >
      <div class="upload">

        <!-- File -->
        <ng-container *ngIf="fileList?.length > 0">
          <div
            class="file"
            [ngClass]="[
              item?.status, limit == 1 && !isUnlimited ? 'default' : 'multiple',
              fileList?.length > 1 && last ? 'last' : '',
              uploadControl?.disabled ? 'disabled' : ''
            ]"
            *ngFor="let item of fileList; let last = last"
          >
            <div class="file-info">
              <div class="file-name" *ngIf="item?.status != 'done'">
                <span>
                  <i nz-icon [nzType]="item?.status == 'uploading' ? 'loading' : 'paper-clip'" nzTheme="outline"></i>
                  {{ item?.name }}
                </span>
              </div>
              <a *ngIf="item?.status == 'done'" class="file-name" target="_blank" [href]="item?.url" [title]="item?.name">
                <span>
                  <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
                  {{ item?.name }}
                </span>
              </a>
            </div>
            <i *ngIf="!uploadControl?.disabled" class="remove" nz-icon nzType="close" nzTheme="outline" title="Remover" (click)="handleRemove(item)"></i>
            <div
              *ngIf="item?.status == 'uploading'"
              class="progress"
              [ngClass]="{'complete': item?.percent > 90}"
              [ngStyle]="{'width': item?.percent + '%'}"
            ></div>
          </div>
        </ng-container>

        <!-- Upload -->
        <nz-upload
          [hidden]="!isUnlimited ? !(fileList?.length < limit) : false"
          nzType="drag"
          [nzAction]="action"
          [nzHeaders]="header"
          [nzFileList]="fileList"
          [nzShowUploadList]="false"
          [nzDisabled]="uploadControl.disabled"
          [nzFilter]="filters"
          [nzLimit]="limit"
          (nzChange)="handleChange($event)"
        >
          <div class="text-upload" [ngClass]="{'disabled': uploadControl.disabled}">
            <span>
              <i nz-icon nzType="upload" nzTheme="outline"></i>
              {{ attribute?.formItem?.description || 'Clique ou arraste o arquivo para esta área para anexar' }}
            </span>
          </div>
        </nz-upload>
      </div>

      <ng-template #errorTpl let-control>
        <app-form-explain [control]="control" [attribute]="attribute" [sizeUpload]="10"></app-form-explain>
      </ng-template>

    </nz-form-control>
  </nz-form-item>
</ng-container>
