import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.less']
})
export class LayoutFooterComponent implements OnInit {

  version: string = '2.715.10'; 
  versionBackend: string = '??';

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.get('version').subscribe(result => {
      this.versionBackend = result["version"];
    }); 
  }

}
