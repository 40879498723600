<ng-container [formGroup]="form">
  <nz-form-item>
    <nz-form-label *ngIf="attribute?.description" [nzRequired]="attribute?.isRequired" [nzFor]="attribute?.key">{{ attribute?.description }}</nz-form-label>
    <nz-form-control [nzErrorTip]="errorTpl">
      <nz-date-picker 
        [formControlName]="attribute?.key" 
        [nzFormat]="'dd/MM/yyyy'"
        [nzSuffixIcon]="iconCalenda"
        [nzPlaceHolder]="attribute?.formItem?.placeholder ? attribute?.formItem?.placeholder : null"
        [ngStyle]="{'width': attribute?.formItem?.pars?.isBlock ? '100%' : 'auto' }"
      ></nz-date-picker>

      <ng-template #iconCalenda>
        <i nz-icon nzType="calendar" nzTheme="fill"></i>
      </ng-template>
      
      <ng-template #errorTpl let-control>
        <app-form-explain [control]="control" [attribute]="attribute"></app-form-explain>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
</ng-container>
