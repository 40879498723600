import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-layout',
  templateUrl: './form-layout.component.html',
  styleUrls: ['./form-layout.component.less']
})
export class FormLayoutComponent implements OnInit {

  @Input() attributes;
  @Input() initial;
  @Input() form: FormGroup;
  @Input() isLabelOptional: boolean = false;
  @Output() callback = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
